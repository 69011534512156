import { Logout } from '@mui/icons-material';
import { Box, Button, Divider, Stack, Typography } from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { SyntheticEvent, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Account } from './Account';
import { Profile } from './Profile';

import { theme } from '@app/theme';

export const TAB_NAME = {
  ACCOUNT: '/account',
  PROFILE: '/profile',
  PROFILE_EDIT: '/profile/edit',
} as const;

type TabType = (typeof TAB_NAME)[keyof typeof TAB_NAME];

const tabs = [
  {
    label: 'アカウント情報',
    path: TAB_NAME.ACCOUNT,
  },
  {
    label: '個人情報',
    path: TAB_NAME.PROFILE,
  },
];

export const Index = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [value, setValue] = useState(0);

  useMemo(() => {
    if (pathname === TAB_NAME.ACCOUNT) {
      setValue(0);
    } else if (
      pathname === TAB_NAME.PROFILE ||
      pathname === TAB_NAME.PROFILE_EDIT
    ) {
      setValue(1);
    }
  }, [pathname]);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleTabClick = (path: TabType) => {
    navigate(path);
  };

  const handleClickLogout = () => {
    alert('Logout');
  };

  const handleClickUpdate = () => {
    alert('Update profile');
  };
  const handleClickBack = () => {
    navigate(TAB_NAME.PROFILE);
  };

  return (
    <>
      <Stack>
        <Box
          sx={{
            display: 'flex',
            mt: 3,
            px: 3,
          }}
        >
          <Stack alignItems="center">
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={value}
              onChange={handleChange}
              aria-label="Vertical tabs example"
              sx={{
                '& .Mui-selected': {
                  backgroundColor: theme.palette.neutral.sky,
                },
                '& .MuiTab-root': {
                  color: theme.palette.grey[800],
                  display: 'inline-block',
                  minWidth: '310px',
                  px: 3,
                  textAlign: 'left',
                },
                '& .MuiTabs-indicator': {
                  display: 'none',
                },
                border: 1,
                borderColor: theme.palette.grey[400],
                borderRadius: 1,
                height: 'fit-content',
                py: 2,
              }}
            >
              {tabs.map((tab, index) => (
                <Tab
                  onClick={() => handleTabClick(tab.path)}
                  key={index}
                  label={tab.label}
                  aria-controls={`vertical-tabpanel-${index}`}
                  id={`vertical-tab-${index}`}
                />
              ))}
            </Tabs>
            <Button
              sx={{
                alignItems: 'center',
                display: 'inline-flex',
                justifyContent: 'center',
                mt: 3,
                width: 'fit-content',
              }}
              onClick={handleClickLogout}
            >
              <Logout fontSize="small" color="error" sx={{ mr: 1 }} />
              <Typography textAlign={'right'} variant="body2" color="error">
                ログアウト
              </Typography>
            </Button>
          </Stack>

          <Box
            flexGrow={1}
            role="tabpanel"
            hidden={value !== 0}
            id={`vertical-tabpanel-0`}
            aria-labelledby={`vertical-tab-0`}
          >
            {value === 0 && <Account />}
          </Box>
          <Box
            role="tabpanel"
            hidden={value !== 1}
            flexGrow={1}
            id={`vertical-tabpanel-1`}
            aria-labelledby={`vertical-tab-1`}
          >
            {value === 1 && <Profile />}
          </Box>
        </Box>
      </Stack>
      {pathname === TAB_NAME.PROFILE_EDIT && (
        <>
          <Divider sx={{ mt: 11 }} />
          <Stack direction="row" justifyContent="flex-end" px={2} my={3}>
            <Button
              variant="outlined"
              color="black"
              onClick={handleClickBack}
              sx={{ borderRadius: 1, minWidth: '120px', mr: 2 }}
            >
              キャンセル
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleClickUpdate}
              sx={{ borderRadius: 1, minWidth: '120px' }}
            >
              保存
            </Button>
          </Stack>
        </>
      )}
    </>
  );
};
