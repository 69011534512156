import { Container, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';

import { HomeCarousel } from '@app/components/Home/HomeCarousel';
import { ProductCardList } from '@app/components/Product/ProductCardList';
import { BottomMenu } from '@app/components/Shared/BottomMenu';
import { HeadBlock } from '@app/components/Shared/HeadBlock';
import { ScrollThreshold } from '@app/components/Shared/ScrollThreshold';
import { searchResultProductsState } from '@app/domain/catalog';
import { getProducts } from '@app/domain/network-actions';
import { useDeviceType } from '@app/hooks/useBrowserHooks';
import { useSetSnackbar } from '@app/hooks/useSetSnackbar';
import { BOTTOM_MENU_ITEMS } from '@app/static/constants';
import { theme } from '@app/theme';
import { LoadableState } from '@app/types/common';

export function Home(): ReactElement {
  const { isDesktop } = useDeviceType();

  const [resultProducts, setResultProducts] = useRecoilState(
    searchResultProductsState
  );
  const [loadable, setLoadable] = useState<LoadableState>(
    LoadableState.HAS_VALUE
  );
  const setSnackbar = useSetSnackbar();

  const fetchProducts = useCallback(
    async (nextLink?: string) => {
      if (loadable === LoadableState.LOADING) return;
      setLoadable(LoadableState.LOADING);
      try {
        const options = {
          nextLink,
        };

        const result = await getProducts(options);

        setResultProducts((prevState) => ({
          data: nextLink
            ? [...prevState.data, ...result.data.value]
            : result.data.value,
          nextLink: result.data['@nextLink'],
          total: result.data.total,
        }));
        setLoadable(LoadableState.HAS_VALUE);
      } catch (error) {
        setLoadable(LoadableState.HAS_ERROR);
        setSnackbar(true, '展示場の取得に失敗しました');
      }
    },
    [loadable, setSnackbar, setResultProducts]
  );

  useEffect(() => {
    setResultProducts({
      data: [],
      nextLink: '',
      scrollY: 0,
      total: 0,
    });
    void fetchProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleScrollThresholdReached = async () => {
    if (isEmpty(resultProducts.nextLink)) {
      return;
    }
    await fetchProducts(resultProducts.nextLink);
  };

  return (
    <>
      <HeadBlock />
      <HomeCarousel />
      <Container
        maxWidth="lg"
        disableGutters={!isDesktop}
        sx={{ pb: '16px!important' }}
      >
        <Typography
          sx={{
            color: theme.palette.neutral.blueLight,
            fontSize: '14px',
            pt: 6,
            textAlign: 'center',
          }}
        >
          おすすめ情報
        </Typography>
        <Typography
          sx={{
            color: 'black',
            fontSize: '1.25rem',
            fontWeight: 'bold',
            pt: 2,
            textAlign: 'center',
          }}
        >
          新着住宅展示場
        </Typography>
      </Container>
      <ProductCardList
        products={resultProducts.data}
        initialVisibleItems={6}
        isProperty={false}
      />
      <ScrollThreshold
        disabled={isEmpty(resultProducts.nextLink)}
        thresholdReached={handleScrollThresholdReached}
      />
      <BottomMenu menuItems={BOTTOM_MENU_ITEMS} shouldShowMenuItems={false} />
    </>
  );
}
