import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Divider,
} from '@mui/material';
import React from 'react';

import { theme } from '@app/theme';
import { Product } from '@app/types/catalog';

interface PropertyInfoProps {
  product: Product;
}

const TableCellWithStyle = ({ children }: { children: React.ReactNode }) => (
  <TableCell
    sx={{
      backgroundColor: theme.palette.neutral.sky,
      fontWeight: 'bold',
      width: '30%',
    }}
  >
    {children}
  </TableCell>
);

const TableCellWithFallback = ({
  value,
}: {
  value: string | number | undefined | null | string[];
}) => (
  <TableCell>
    {Array.isArray(value) ? value.join(', ') : value ? value : '-'}
  </TableCell>
);

export function PropertyInfo({ product }: PropertyInfoProps) {
  return (
    <Box sx={{ p: 2 }}>
      <Divider sx={{ my: 4 }} />
      <Typography variant="h6" fontWeight={600} sx={{ mb: 3 }}>
        物件情報
      </Typography>
      <TableContainer
        component={Paper}
        sx={{
          border: `1px solid ${theme.palette.neutral.silver}`,
          borderRadius: '8px',
          width: '100%',
        }}
      >
        <Table>
          <TableBody>
            <TableRow>
              <TableCellWithStyle>販売スケジュール</TableCellWithStyle>
              <TableCellWithFallback
                value={product.customFields.salesStartDate}
              />
            </TableRow>
            <TableRow>
              <TableCellWithStyle>イベント情報</TableCellWithStyle>
              <TableCellWithFallback
                value={product.customFields.eventContent}
              />
            </TableRow>
            <TableRow>
              <TableCellWithStyle>所在地</TableCellWithStyle>
              <TableCell>
                {product.addressLine1 ||
                product.addressLine2 ||
                product.addressLine3
                  ? `${product.addressLine1 ? product.addressLine1 : ''}${
                      product.addressLine2 ? ' ' + product.addressLine2 : ''
                    }${product.addressLine3 ? ' ' + product.addressLine3 : ''}`
                  : '-'}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCellWithStyle>交通</TableCellWithStyle>
              <TableCellWithFallback value={product.customFields.access} />
            </TableRow>
            <TableRow>
              <TableCellWithStyle>販売戸数</TableCellWithStyle>
              <TableCellWithFallback
                value={
                  product.customFields.salesHouseCount
                    ? `${product.customFields.salesHouseCount}戸`
                    : '-'
                }
              />
            </TableRow>
            <TableRow>
              <TableCellWithStyle>総戸数</TableCellWithStyle>
              <TableCellWithFallback
                value={
                  product.customFields.totalHouseCount
                    ? `${product.customFields.totalHouseCount}戸`
                    : '-'
                }
              />
            </TableRow>
            <TableRow>
              <TableCellWithStyle>価格</TableCellWithStyle>
              <TableCell>
                {product.customFields.minPrice || '-'}万円〜
                {product.customFields.maxPrice || '-'}万円
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCellWithStyle>最多価格帯</TableCellWithStyle>
              <TableCellWithFallback
                value={
                  product.customFields.mostPriceRanges &&
                  product.customFields.mostPriceRanges.length > 0
                    ? product.customFields.mostPriceRanges
                        .map((range) => `${range}万円`)
                        .join('、')
                    : '-'
                }
              />
            </TableRow>
            <TableRow>
              <TableCellWithStyle>私道負担・道路</TableCellWithStyle>
              <TableCell>
                {product.customFields.minRoadWidth || '-'}m〜
                {product.customFields.maxRoadWidth || '-'}m、
                {product.customFields.roadPainting || ''},
                {product.customFields.roadNote || ''}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCellWithStyle>諸費用</TableCellWithStyle>
              <TableCellWithFallback value={product.customFields.otherCost} />
            </TableRow>
            <TableRow>
              <TableCellWithStyle>間取り</TableCellWithStyle>
              <TableCellWithFallback value={undefined} />
            </TableRow>
            <TableRow>
              <TableCellWithStyle>建物面積</TableCellWithStyle>
              <TableCell>
                {product.customFields.minBuildingArea || '-'}〜
                {product.customFields.maxBuildingArea || '-'}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCellWithStyle>土地面積</TableCellWithStyle>
              <TableCell>
                {product.customFields.minLandArea || '-'}〜
                {product.customFields.maxLandArea || '-'}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCellWithStyle>建ペイ率・容積率</TableCellWithStyle>
              <TableCellWithFallback value={product.customFields.areaRatio} />
            </TableRow>
            <TableRow>
              <TableCellWithStyle>完成時期（築年月）</TableCellWithStyle>
              <TableCellWithFallback
                value={product.customFields.completionDate}
              />
            </TableRow>
            <TableRow>
              <TableCellWithStyle>引き渡し可能時期</TableCellWithStyle>
              <TableCellWithFallback
                value={product.customFields.deliveryDateType}
              />
            </TableRow>
            <TableRow>
              <TableCellWithStyle>土地の権利形態</TableCellWithStyle>
              <TableCellWithFallback
                value={product.customFields.landRightType}
              />
            </TableRow>
            <TableRow>
              <TableCellWithStyle>構造・工法</TableCellWithStyle>
              <TableCellWithFallback
                value={product.customFields.buildingSystem}
              />
            </TableRow>
            <TableRow>
              <TableCellWithStyle>施工</TableCellWithStyle>
              <TableCellWithFallback
                value={product.customFields.constructionCompany}
              />
            </TableRow>
            <TableRow>
              <TableCellWithStyle>リフォーム</TableCellWithStyle>
              <TableCellWithFallback
                value={product.customFields.reformExterior}
              />
            </TableRow>
            <TableRow>
              <TableCellWithStyle>用途地域</TableCellWithStyle>
              <TableCellWithFallback value={product.customFields.useDistrict} />
            </TableRow>
            <TableRow>
              <TableCellWithStyle>地目</TableCellWithStyle>
              <TableCellWithFallback
                value={product.customFields.landCategory}
              />
            </TableRow>
            <TableRow>
              <TableCellWithStyle>その他制限事項</TableCellWithStyle>
              <TableCellWithFallback
                value={product.customFields.legalOtherLimitations}
              />
            </TableRow>
            <TableRow>
              <TableCellWithStyle>その他概要・特記事項</TableCellWithStyle>
              <TableCellWithFallback
                value={product.customFields.legalOtherLimitationNote}
              />
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
