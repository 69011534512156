import { Visibility, VisibilityOff } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Container,
  FormControl,
  FormLabel,
  Link as MUILink,
  Stack,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { Box } from '@mui/system';
import React, { ReactElement, useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { useSetRecoilState, useRecoilState } from 'recoil';

import { getUser } from '@app/adapter/user-service';
import { Copyright } from '@app/components/Shared/Copyright';
import { HeadBlock } from '@app/components/Shared/HeadBlock';
import { Logo } from '@app/components/Shared/Logo';
import {
  afterLoginRouteSelector,
  loggedInUserState,
  userAuthInfoSelector,
} from '@app/domain/app';
import { handleLogin } from '@app/domain/network-actions';
import { useSetSnackbar } from '@app/hooks/useSetSnackbar';
import { imageAssets } from '@app/theme';
import { isError } from '@app/utils/error';
import { isUserInfoRegistered } from '@app/utils/user';

export function Login(): ReactElement {
  const navigate = useNavigate();
  const setSnackbar = useSetSnackbar();
  const setUserAuthInfoState = useSetRecoilState(userAuthInfoSelector);
  const setLoggedInUser = useSetRecoilState(loggedInUserState);
  const [afterLoginRoute, setAfterLoginRoute] = useRecoilState(
    afterLoginRouteSelector
  );
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const { control, handleSubmit, formState } = useForm({
    defaultValues: {
      email: '',
      fingerprint: 'fingerprint',
      password: '',
    },
    mode: 'all',
  });
  const [isLoading, setIsLoading] = useState(false);

  const handlePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const onSubmit = useCallback(
    async (data: { email: string; password: string }) => {
      try {
        setIsLoading(true);
        const loginData = await handleLogin(data.email, data.password);
        const user = await getUser(
          loginData.userId,
          loginData.accessToken,
          loginData.fingerprint
        );
        if (!user.data.emailVerified) {
          navigate('/register/complete');
          return;
        }
        setLoggedInUser(user.data);

        setUserAuthInfoState({
          accessToken: loginData.accessToken,
          fingerPrint: loginData.fingerprint,
          userId: loginData.userId,
        });

        if (!isUserInfoRegistered(user.data)) {
          navigate('/profile/edit');
        } else if (afterLoginRoute) {
          navigate(afterLoginRoute);
          setAfterLoginRoute(null);
        } else {
          navigate('/home');
        }
      } catch (error) {
        let message = 'ログインに失敗しました';
        if (isError(error)) {
          if (error.message === 'email or password is wrong') {
            message = 'メールアドレスまたはパスワードが間違っています';
          }
        }
        setSnackbar(true, message);
      } finally {
        setIsLoading(false);
      }
    },
    [
      afterLoginRoute,
      navigate,
      setAfterLoginRoute,
      setLoggedInUser,
      setSnackbar,
      setUserAuthInfoState,
    ]
  );

  return (
    <>
      <HeadBlock />
      <Container maxWidth={false} disableGutters>
        <Box display="flex" height="100vh" width="100%">
          <Box
            flex={1}
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            p={3}
            sx={{ p: 8 }}
          >
            <Box sx={{ px: 10 }}>
              <Logo />
              <Stack spacing={3} sx={{ mt: 6, py: 3 }}>
                <Typography
                  variant="h5"
                  fontWeight={600}
                  textAlign="left"
                  sx={{ py: 3 }}
                >
                  ログイン
                </Typography>
              </Stack>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={2}>
                  <FormControl variant="outlined" fullWidth>
                    <FormLabel>
                      <Typography variant="body2" fontWeight={500}>
                        メールアドレス
                      </Typography>
                    </FormLabel>
                    <Controller
                      name="email"
                      control={control}
                      rules={{ required: 'メールアドレスを入力してください' }}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          error={!!error}
                          helperText={error?.message}
                          margin="dense"
                          placeholder="メールアドレスを入力"
                        />
                      )}
                    />
                  </FormControl>
                  <FormControl variant="outlined" fullWidth>
                    <FormLabel>
                      <Typography variant="body2" fontWeight={500}>
                        パスワード
                      </Typography>
                    </FormLabel>
                    <Controller
                      name="password"
                      control={control}
                      rules={{ required: 'パスワードを入力してください' }}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          type={isPasswordVisible ? 'text' : 'password'}
                          error={!!error}
                          helperText={error?.message}
                          margin="dense"
                          placeholder="パスワードを入力"
                          autoComplete="new-password"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={handlePasswordVisibility}
                                  onMouseDown={(e) => e.preventDefault()}
                                >
                                  {isPasswordVisible ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    />
                  </FormControl>
                  <Stack
                    alignItems="center"
                    justifyContent="center"
                    sx={{ pt: 5 }}
                  >
                    <MUILink component={Link} to="/password/reset">
                      パスワードを忘れた方はこちら
                    </MUILink>
                  </Stack>
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    fullWidth
                    disabled={!formState.isValid}
                    loading={isLoading}
                  >
                    ログイン
                  </LoadingButton>
                </Stack>
              </form>
              <Box sx={{ my: 5 }}>
                <Typography component="div" align="center" variant="body2">
                  まだアカウントをお持ちではないですか？
                  <MUILink component={Link} to="/register" underline="none">
                    会員登録
                  </MUILink>
                </Typography>
              </Box>
            </Box>
            <Box sx={{ py: 2, textAlign: 'center' }}>
              <Copyright />
            </Box>
          </Box>
          <Box
            flex={1}
            sx={{
              backgroundImage: `url(${imageAssets.registerMainImage})`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              width: '100%',
            }}
          ></Box>
        </Box>
      </Container>
    </>
  );
}
