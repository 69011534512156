import {
  Box,
  Grid,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Divider,
} from '@mui/material';
import React from 'react';

import { imageAssets, theme } from '@app/theme';
import { ProductVariant, ProductVariantSku } from '@app/types/catalog';

interface PropertyVariantSkuDetailsProps {
  variants: ProductVariant[];
}

const renderVariantSection = (
  title: string,
  variants: ProductVariant[]
): React.ReactNode => {
  if (variants.length === 0) {
    return null;
  }

  return (
    <>
      <Divider sx={{ my: 4 }} />
      <Typography variant="h6" fontWeight={600} sx={{ my: 2 }}>
        {title}
      </Typography>
      <Grid container spacing={2} sx={{ mb: 7 }}>
        {variants.map((variant) => (
          <Grid item xs={6} key={variant.id}>
            <Card
              sx={{
                border: `1px solid ${theme.palette.neutral.silver}`,
                height: '100%',
              }}
            >
              <CardMedia
                component="img"
                sx={{
                  height: '200px',
                  mt: 3,
                  objectFit: 'contain',
                  width: '100%',
                }}
                image={variant.images?.[0]?.url || imageAssets.floorPlanDefault}
                alt={variant.title}
              />
              <CardContent>
                <Typography variant="subtitle1" fontWeight={600} sx={{ my: 2 }}>
                  {variant.title}
                </Typography>
                <Typography variant="body2" sx={{ my: 2 }}>
                  {variant.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export function PropertyVariantSkuDetails({
  variants,
}: PropertyVariantSkuDetailsProps) {
  const exteriorVariants = variants.filter(
    (variant) => variant.sku === ProductVariantSku.EXTERIOR
  );
  const aroundVariants = variants.filter(
    (variant) => variant.sku === ProductVariantSku.AROUND
  );
  const equipmentVariants = variants.filter(
    (variant) => variant.sku === ProductVariantSku.EQUIPMENT
  );
  const interiorVariants = variants.filter(
    (variant) => variant.sku === ProductVariantSku.INTERIOR
  );

  return (
    <Box sx={{ mt: 3 }}>
      {renderVariantSection('外観', exteriorVariants)}
      {renderVariantSection('内観', interiorVariants)}
      {renderVariantSection('周辺環境', aroundVariants)}
      {renderVariantSection('設備', equipmentVariants)}
    </Box>
  );
}
