import { AlertColor } from '@mui/material';
import { MutableRefObject } from 'react';
import { atom } from 'recoil';

export const navPortalContainerRef =
  atom<MutableRefObject<Element | null> | null>({
    dangerouslyAllowMutability: true,
    default: null,
    key: 'navPortalContainerRef',
  });

export const navPortalSpacerRef = atom<MutableRefObject<Element | null> | null>(
  {
    dangerouslyAllowMutability: true,
    default: null,
    key: 'navPortalSpacerRef',
  }
);

export const sidebarOpenState = atom<boolean>({
  default: false,
  key: 'sidebarOpenState',
});

export const snackbarOpenState = atom<boolean>({
  default: false,
  key: 'snackbarOpenState',
});
export const snackbarTextState = atom<string>({
  default: '',
  key: 'snackbarTextState',
});
export const snackbarSeverityState = atom<AlertColor>({
  default: 'error',
  key: 'snackbarSeverityState',
});

export const productFavoritesCountState = atom<number>({
  default: 0,
  key: 'productFavoritesState',
});

export const productMessageCountState = atom<number>({
  default: 0,
  key: 'productMessageState',
});
