import { atom } from 'recoil';

import {
  defaultInquiryStepOneFormData,
  defaultInquiryStepTwoFormData,
} from '@app/schemas/order';
import {
  InquiryStepOneFormData,
  InquiryStepTwoFormData,
} from '@app/types/order';

export const InquiryStepTwoState = atom<InquiryStepTwoFormData>({
  default: defaultInquiryStepTwoFormData,
  key: 'InquiryStepTwoState',
});

export const InquiryStepOneState = atom<InquiryStepOneFormData>({
  default: defaultInquiryStepOneFormData,
  key: 'InquiryStepOneState',
});
