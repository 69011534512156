import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { IconButton, Typography } from '@mui/material';
import { ReactElement } from 'react';

interface TopNavBackButtonProps {
  onGoBack: () => void;
}

export function TopNavBackButton({
  onGoBack,
}: TopNavBackButtonProps): ReactElement {
  return (
    <IconButton size="small" sx={{ color: 'inherit' }} onClick={onGoBack}>
      <ArrowBackIosIcon sx={{ width: '0.8em' }} />
      <Typography color="inherit" variant="body2">
        戻る
      </Typography>
    </IconButton>
  );
}
