import { Box, Button, Typography } from '@mui/material';
import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';

import { ProgressStepper } from '@app/components/User/ProgressStepper';

export function Completed(): ReactElement {
  const navigate = useNavigate();

  const handleGoToTopPage = () => {
    navigate('/');
  };

  return (
    <Box
      sx={{
        borderRadius: 1,
        mt: 10,
        padding: 3,
      }}
    >
      <ProgressStepper activeStep={2} />
      <Typography
        variant="h6"
        fontWeight={700}
        textAlign="center"
        sx={{ mb: 5 }}
      >
        会員登録が完了しました
      </Typography>

      <Box sx={{ textAlign: 'center' }}>
        <Button variant="contained" color="primary" onClick={handleGoToTopPage}>
          トップページへ
        </Button>
      </Box>
    </Box>
  );
}
