import {
  Grid,
  Typography,
  Link as MuiLink,
  Box,
  Stack,
  CardMedia,
} from '@mui/material';
import { ReactElement, useCallback, useMemo, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import { ProgressStepper } from '@app/components/Order/ProgressStepper';
import { Confirm } from '@app/components/Product/company/Inquiry/Confirm';
import { ExhibitionStep1 } from '@app/components/Product/company/Inquiry/ExhibitionStep1';
import { ExhibitionStep2 } from '@app/components/Product/company/Inquiry/ExhibitionStep2';
import { BottomMenu } from '@app/components/Shared/BottomMenu';
import { HeadBlock } from '@app/components/Shared/HeadBlock';
import { InquiryStepOneState, InquiryStepTwoState } from '@app/domain/order';
import companyLogo from '@app/mockData/company-logo.svg';
import { BOTTOM_MENU_ITEMS } from '@app/static/constants';
import { LoadableState } from '@app/types/common';
import {
  InquiryStepOneFormData,
  InquiryStepTwoFormData,
} from '@app/types/order';

export enum OrderStep {
  COMPLETE = 'complete',
  IN_REVIEW = 'inReview',
  STEP1 = 'step1',
  STEP2 = 'step2',
}

export function Register(): ReactElement {
  const navigate = useNavigate();
  const [loadable, setLoadable] = useState<LoadableState>(
    LoadableState.HAS_VALUE
  );
  const [step1Data, setSte1pData] = useRecoilState(InquiryStepOneState);
  const [step2Data, setStep2Data] = useRecoilState(InquiryStepTwoState);

  const [step, setStep] = useState<OrderStep>(OrderStep.STEP1);

  const isLoading = useMemo(() => {
    return loadable === LoadableState.LOADING;
  }, [loadable]);

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const handleSubmitStep1Form = useCallback(
    (data: InquiryStepOneFormData) => {
      setSte1pData((prevData) => ({
        ...prevData,
        ...data,
      }));
      setStep(OrderStep.STEP2);
      scrollToTop();
    },
    [setSte1pData]
  );

  const handleSubmitStep2Form = useCallback(
    (data: InquiryStepTwoFormData) => {
      setStep2Data((prevData) => ({
        ...prevData,
        ...data,
      }));
      setStep(OrderStep.IN_REVIEW);
      scrollToTop();
    },
    [setStep2Data]
  );

  const handleSubmitRegistrationForm = useCallback(
    async (formData: InquiryStepOneFormData & InquiryStepTwoFormData) => {
      setLoadable(LoadableState.LOADING);
      console.log('Form data submitted:', formData);
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setStep(OrderStep.COMPLETE);
      scrollToTop();
      navigate(`/companies/123/inquiry/create/complete`);
      setLoadable(LoadableState.HAS_VALUE);
    },
    [navigate]
  );

  const handleBackToStep1 = () => {
    setStep(OrderStep.STEP1);
  };

  const handleBackToStep2 = () => {
    setStep(OrderStep.STEP2);
  };

  return (
    <>
      <HeadBlock />
      <Grid
        container
        direction="column"
        alignItems="center"
        textAlign="center"
        sx={{ mt: 10 }}
      >
        <CardMedia
          component="img"
          image={companyLogo}
          alt="Company logo"
          sx={{
            borderRadius: 2,
            height: '36px',
            mb: '12px',
            objectFit: 'cover',
            width: '360px',
          }}
        />
        <Typography variant="h3" fontWeight="bold">
          資料請求
        </Typography>
        <Grid sx={{ my: 3 }}>
          <Typography color="textSecondary">
            対象ハウスメーカーのカタログをまとめて一括請求（無料）できます。
          </Typography>
          <Grid sx={{ mb: 3, mt: 6 }}>
            {/* TODO: /companies/{organizationId}/inquiry/create */}
            <MuiLink component={Link} to="#" variant="body1">
              見学予約はこちらから
            </MuiLink>
          </Grid>
        </Grid>
        <ProgressStepper
          activeStep={
            step === OrderStep.STEP1
              ? 0
              : step === OrderStep.STEP2
              ? 1
              : step === OrderStep.IN_REVIEW
              ? 2
              : step === OrderStep.COMPLETE
              ? 4
              : 0
          }
        />
      </Grid>
      <Stack
        sx={{
          justifyContent: 'center',
        }}
      >
        <Box sx={{ px: 9, py: 4, width: '100%' }}>
          <Box sx={{ p: '0 !important' }}>
            {step === OrderStep.STEP1 && (
              <ExhibitionStep1
                onSubmit={handleSubmitStep1Form}
                goToStep2={() => setStep(OrderStep.STEP2)}
                defaultValues={step1Data}
              />
            )}
            {step === OrderStep.STEP2 && (
              <ExhibitionStep2
                onSubmit={handleSubmitStep2Form}
                defaultValues={step2Data}
                onBack={handleBackToStep1}
              />
            )}
            {step === OrderStep.IN_REVIEW && (
              <Confirm
                onSubmit={handleSubmitRegistrationForm}
                onBackToStep1={handleBackToStep1}
                onBackToStep2={handleBackToStep2}
                isLoading={isLoading}
              />
            )}
          </Box>
        </Box>
      </Stack>
      <BottomMenu menuItems={BOTTOM_MENU_ITEMS} />
    </>
  );
}
