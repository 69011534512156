import { Stack, Tab, Tabs } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { AccountInfo } from '@app/components/Setting/AccountInfo';
import { NotificationEdit } from '@app/components/Setting/NotificationEdit';
import { HeadBlock } from '@app/components/Shared/HeadBlock';
import { loggedInUserState } from '@app/domain/app';
import { userUploadFilesState, userUpdateState } from '@app/domain/user';

export const tabs = [
  { label: 'アカウント設定', value: 'account' },
  { label: '通知設定', value: 'notification' },
] as const;

export function Setting(): ReactElement {
  const theme = useTheme();
  const loggedInUser = useRecoilValue(loggedInUserState);
  const setUpdateSharedState = useSetRecoilState(userUpdateState);
  const setUploadFilesState = useSetRecoilState(userUploadFilesState);
  const [tab, setTab] = useState<string>(tabs[0].value);

  useEffect(() => {
    // 編集データをリセット
    setUpdateSharedState(null);
    setUploadFilesState(null);
  }, [setUpdateSharedState, setUploadFilesState]);

  const handleChangeTab = useCallback(
    (event: React.SyntheticEvent, value: string) => {
      setTab(value);
    },
    [setTab]
  );

  return (
    <>
      <HeadBlock />
      <Stack
        spacing={2}
        sx={{
          backgroundColor: theme.palette.neutral.greyLight,
          height: '100vh',
        }}
      >
        <Tabs value={tab} onChange={handleChangeTab} sx={{ px: 2 }}>
          {tabs.map(({ label, value }, index) => (
            <Tab key={index} label={label} value={value} />
          ))}
        </Tabs>
        {tab === tabs[1].value ? (
          <NotificationEdit />
        ) : (
          <>{loggedInUser && <AccountInfo user={loggedInUser} />}</>
        )}
      </Stack>
    </>
  );
}
