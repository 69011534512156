import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export function Complete() {
  const navigate = useNavigate();

  return (
    <>
      <Box textAlign="center" mt={5} px={4}>
        <Typography fontSize={24} fontWeight={700}>
          パスワード再設定完了
        </Typography>
        <Typography color="text.gray" mt={3} mb={7}>
          パスワード再設定が完了しました。
        </Typography>
        <Button
          color="primary"
          variant="contained"
          onClick={() => navigate('/login')}
        >
          ログイン画面へ
        </Button>
      </Box>
    </>
  );
}
