import { Search } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { ReactElement } from 'react';

export function SearchNoResult(): ReactElement {
  return (
    <Box sx={{ color: 'secondary', px: 1, py: 4, textAlign: 'center' }}>
      <Search fontSize="large" color="secondary" />
      <Typography
        sx={{ opacity: 0.5 }}
        component="h4"
        data-e2e="searchResult-noItemMessage"
      >
        一致する情報はありませんでした
      </Typography>
    </Box>
  );
}
