import { LoadingButton } from '@mui/lab';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Stack,
  Typography,
} from '@mui/material';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useRecoilValue } from 'recoil';

import {
  deletePreference,
  getPreferences,
  postPreference,
} from '@app/adapter/user-service';
import { userAuthInfoSelector } from '@app/domain/app';
import { useSetSnackbar } from '@app/hooks/useSetSnackbar';
import { PreferenceNotificationForm } from '@app/schemas/user';
import { PreferenceKeys, PreferenceNotification } from '@app/types/user';
import { isError } from '@app/utils/error';

export function NotificationEdit(): ReactElement {
  const setSnackbar = useSetSnackbar();
  const userInfo = useRecoilValue(userAuthInfoSelector);
  const [isLoading, setIsLoading] = useState(false);

  const {
    control,
    formState: { isValid },
    handleSubmit,
    reset,
  } = useForm<PreferenceNotification>({
    defaultValues: PreferenceNotificationForm.defaultValues,
    mode: 'all',
    resolver: PreferenceNotificationForm.resolver,
  });

  const fetchPreference = useCallback(async () => {
    if (!userInfo) return;
    try {
      const result = await getPreferences(
        userInfo.userId,
        userInfo.accessToken,
        userInfo.fingerPrint,
        { key: PreferenceKeys.NOTIFICATION }
      );
      if (result.data.value[0]) {
        reset(JSON.parse(result.data.value[0].value.replace(/\\+/g, '')));
      }
    } catch (e) {
      if (isError(e)) {
        throw new Error(e.message);
      }
    }
  }, [reset, userInfo]);

  useEffect(() => {
    void fetchPreference();
    // eslint-disable-next-line
  }, []);

  const onSubmit = useCallback(
    async (data: PreferenceNotification) => {
      if (!userInfo) return;

      setIsLoading(true);
      try {
        const newPreference = await postPreference(
          userInfo.userId,
          userInfo.accessToken,
          userInfo.fingerPrint,
          {
            key: PreferenceKeys.NOTIFICATION,
            value: JSON.stringify(data),
          }
        );

        const result = await getPreferences(
          userInfo.userId,
          userInfo.accessToken,
          userInfo.fingerPrint,
          { key: PreferenceKeys.NOTIFICATION }
        );
        for (const preference of result.data.value) {
          if (preference.id === newPreference.data.id) continue;
          await deletePreference(
            userInfo.userId,
            userInfo.accessToken,
            userInfo.fingerPrint,
            preference.id
          );
        }

        setSnackbar(true, '登録しました', 'success');
      } catch (e) {
        if (isError(e)) {
          throw new Error(e.message);
        }
        setSnackbar(true, '登録に失敗しました');
      }
      setIsLoading(false);
    },
    [setSnackbar, userInfo]
  );

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2} sx={{ backgroundColor: 'white', p: 2 }}>
          <Stack>
            <Typography variant="body2">新着メッセージ</Typography>
            <Controller
              name="newMessageByEmail"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  {...field}
                  checked={field.value}
                  control={<Checkbox />}
                  label={
                    <Typography variant="body2">
                      新着メッセージをメールで通知する
                    </Typography>
                  }
                />
              )}
            />
          </Stack>
          <Stack>
            <Typography variant="body2">リマインダー</Typography>
            <Controller
              name="sendRemindDayBeforeWork"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  {...field}
                  checked={field.value}
                  control={<Checkbox />}
                  label={
                    <Typography variant="body2">
                      勤務前日リマインダーを送る
                    </Typography>
                  }
                />
              )}
            />
          </Stack>
          <Stack>
            <Typography variant="body2">新規求人登録情報</Typography>
            <Controller
              name="newProductByFollowedOrg"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  {...field}
                  checked={field.value}
                  control={<Checkbox />}
                  label={
                    <Typography variant="body2">
                      フォローしたクリニックの新規求人情報
                    </Typography>
                  }
                />
              )}
            />
          </Stack>
        </Stack>
        <Box sx={{ p: 2 }}>
          <LoadingButton
            type="submit"
            color="primary"
            variant="contained"
            fullWidth
            loading={isLoading}
            disabled={!isValid}
          >
            保存
          </LoadingButton>
        </Box>
      </form>
    </Box>
  );
}
