import { atom } from 'recoil';

import {
  AttachmentCreateByTypes,
  Preference,
  UserUpdate,
} from '@app/types/user';

export const userUpdateState = atom<UserUpdate | null>({
  default: null,
  key: 'userUpdateState',
});

export const userUploadFilesState = atom<AttachmentCreateByTypes | null>({
  default: null,
  key: 'userUploadFilesState',
});

export const preferenceSearchConditionsState = atom<Preference[]>({
  default: [],
  key: 'userPreferenceSearchConditionsState',
});

export const userIvCodeState = atom<string>({
  default: '',
  key: 'userIvCodeState',
});
