import { Button, Stack } from '@mui/material';
import React, { ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import { useRecoilState } from 'recoil';

import { ExhibitionFormFields } from '@app/components/Order/ExhibitionFormFields';
import { InquiryStepTwoState } from '@app/domain/order';
import { theme } from '@app/theme';
import { InquiryStepTwoFormData } from '@app/types/order';
import {
  InquiryStepTwoQuestion,
  InquiryType,
  OrganizationCustomFields,
} from '@app/types/organization';

interface InquiryStepTwoProps {
  defaultValues?: InquiryStepTwoFormData;
  inquiryType: InquiryType;
  onBack: () => void;
  onSubmit: (data: InquiryStepTwoFormData) => void;
  organizationCustomField?: OrganizationCustomFields;
}

export function InquiryStepTwo({
  onSubmit,
  onBack,
  defaultValues,
  organizationCustomField,
  inquiryType,
}: InquiryStepTwoProps): ReactElement {
  const [stepData, setStepData] = useRecoilState(InquiryStepTwoState);

  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm<InquiryStepTwoFormData>({
    defaultValues: defaultValues || stepData,
  });

  const handleBack = async () => {
    const currentData = getValues();
    await setStepData((prevData: InquiryStepTwoFormData) => ({
      ...prevData,
      ...currentData,
    }));
    onBack();
  };

  // inquiryTypeに応じて参照する値を切り替え
  let questions: InquiryStepTwoQuestion[] = [];
  switch (inquiryType) {
    case InquiryType.ExhibitionVisitor:
      questions =
        organizationCustomField?.exhibitionVisitorReserveQuestions || [];
      break;
    case InquiryType.ExhibitionRequestDoc:
      questions = organizationCustomField?.exhibitionRequestDocQuestions || [];
      break;
    case InquiryType.PropertyVisitor:
      questions =
        organizationCustomField?.propertyVisitorReserveQuestions || [];
      break;
    case InquiryType.PropertyRequestDoc:
      questions = organizationCustomField?.propertyRequestDocQuestions || [];
      break;
    default:
      questions = [];
  }

  return (
    <Stack
      sx={{
        alignItems: 'center',
        width: '100%',
      }}
      spacing={4}
    >
      <Stack
        component="form"
        onSubmit={handleSubmit((data) => {
          setStepData((prevData: InquiryStepTwoFormData) => ({
            ...prevData,
            ...data,
          }));
          onSubmit(data);
        })}
        sx={{
          backgroundColor: theme.palette.neutral.white,
          borderRadius: '8px',
          flexDirection: 'column',
          maxWidth: '800px',
          padding: '40px',
          width: '100%',
        }}
      >
        <ExhibitionFormFields
          questions={questions}
          control={control}
          errors={errors}
          isDisabled={false}
        />
        <Stack
          direction="row"
          spacing={2}
          sx={{ mb: 12, mt: 10, width: '100%' }}
        >
          <Button
            type="button"
            variant="outlined"
            color="secondary"
            sx={{ flex: 1 }}
            onClick={handleBack}
          >
            戻る
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{ flex: 1 }}
          >
            次へ
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
}
