import { atom } from 'recoil';

import {
  UserFormData,
  USER_FORM_DEFAULT_VALUES,
} from '../schemas/registration';

export const registerState = atom<UserFormData>({
  default: USER_FORM_DEFAULT_VALUES,
  key: 'registerState',
});
