import { atom, selector } from 'recoil';

import {
  getAttributes,
  getCategoryTree,
  getLocations,
  getLocationTree,
} from '@app/adapter/catalog-service';
import {
  Attribute,
  AttributeGroupName,
  Category,
  CategoryParentName,
  CategoryStatus,
  Product,
  ProductLocation,
  ProductLocationType,
  SearchCondition,
  SearchResultProducts,
} from '@app/types/catalog';

export const categoriesState = atom<Category[]>({
  default: [],
  key: 'catalog/categoriesState',
});
export const categorySelector = selector<Category[]>({
  get: async ({ get }) => {
    const state = get(categoriesState);
    if (state?.length > 0) return state;

    try {
      const {
        data: { value },
      } = await getCategoryTree({
        name: CategoryParentName.JOB_TYPE,
        status: CategoryStatus.ACTIVE,
      });
      return value || [];
    } catch (error) {
      console.error(error);
      return [];
    }
  },
  key: 'catalog/categorySelector',
  set: ({ set }, newData) => {
    set(categoriesState, newData);
  },
});

export const locationsTreeState = atom<ProductLocation[]>({
  default: [],
  key: 'catalog/locationsTreeState',
});
export const locationsTreeSelector = selector<ProductLocation[]>({
  get: async ({ get }) => {
    const state = get(locationsTreeState);
    if (state?.length > 0) return state;

    try {
      const {
        data: { value },
      } = await getLocationTree({
        type: ProductLocationType.REGION,
      });
      return value;
    } catch (error) {
      console.error(error);
      return [];
    }
  },
  key: 'catalog/locationsTreeSelector',
  set: ({ set }, newData) => {
    set(locationsTreeState, newData);
  },
});

export const locationsByPrefectureState = atom<ProductLocation[]>({
  default: [],
  key: 'catalog/locationsByPrefectureState',
});
export const locationsByPrefectureSelector = selector<ProductLocation[]>({
  get: async ({ get }) => {
    const state = get(locationsByPrefectureState);
    if (state?.length > 0) return state;

    try {
      const {
        data: { value },
      } = await getLocations({
        type: ProductLocationType.PREFECTURE,
      });
      return value;
    } catch (error) {
      console.error(error);
      return [];
    }
  },
  key: 'catalog/locationsByPrefectureSelector',
  set: ({ set }, newData) => {
    set(locationsByPrefectureState, newData);
  },
});

export const attributesByClinicalDepartmentState = atom<Attribute[]>({
  default: [],
  key: 'catalog/attributesByClinicalDepartmentState',
});
export const attributesByClinicalDepartmentSelector = selector<Attribute[]>({
  get: async ({ get }) => {
    const state = get(attributesByClinicalDepartmentState);
    if (state?.length > 0) return state;

    try {
      const {
        data: { value },
      } = await getAttributes({
        groupName: [AttributeGroupName.CLINICAL_DEPARTMENT],
      });
      return value;
    } catch (error) {
      console.error(error);
      return [];
    }
  },
  key: 'catalog/attributesByClinicalDepartmentSelector',
  set: ({ set }, newData) => {
    set(attributesByClinicalDepartmentState, newData);
  },
});

export const attributesByJobTypeState = atom<Attribute[]>({
  default: [],
  key: 'catalog/attributesByJobTypeState',
});
export const attributesByJobTypeSelector = selector<Attribute[]>({
  get: async ({ get }) => {
    const state = get(attributesByJobTypeState);
    if (state?.length > 0) return state;

    try {
      const {
        data: { value },
      } = await getAttributes({
        groupName: [AttributeGroupName.JOB_TYPE],
      });
      return value;
    } catch (error) {
      console.error(error);
      return [];
    }
  },
  key: 'catalog/attributesByJobTypeSelector',
  set: ({ set }, newData) => {
    set(attributesByJobTypeState, newData);
  },
});

export const searchResultProductsState = atom<SearchResultProducts>({
  default: {
    data: [],
    nextLink: '',
    scrollY: 0,
    total: 0,
  },
  key: 'searchResultProductsState',
});

export const searchResultProductsDummy = atom<SearchResultProducts>({
  default: {
    data: [
      {
        additionalInformation: '',
        addressLine1: '大阪府',
        addressLine2: '大阪市天王寺区',
        addressLine3: '南河堀町22番3（地番）',
        attributes: [],
        categoryId: '',
        customFields: {
          access: 'JR大阪環状線「天王寺」駅 徒歩7分',
          addressLine4: '',
          areaRatio: '',
          breakTime: '',
          breakTimeNoParticular: false,
          buildingSystem: '2鉄筋コンクリート造',
          calculatedDairyPay: 0,
          calculatedHourlyPay: 0,
          closingTime: '18:00',
          commutingCost: 0,
          companyName: '株式会社アーネスト',
          completionDate: '',
          constructionCompany: '',
          contactAddress:
            'ご家庭のライフスタイルに寄り添う、世界でいちばん幸せな「わが家」をご提案',
          contactPhoneNumber: '06-0000-000',
          count: 0,
          day: '',
          deliveryDateType: '即入居可',
          endTime: '',
          eventContent: '',
          floorPlanDescription1:
            '「邸別自由設計」で理想の住まいをカタチにしてきた積水ハウスでは、広がる大空間を叶える、「ダイナミックフレームシステム（鉄骨1・2階建て）」、各階で自由に間取りをプラン二ングできる「フレキシブルβシステム（鉄骨3・4階建て）」、光と風を取り入れて四季を通して快適に住まう「シャーウッド（木造住宅）」という3つの独自構法をベースに、様々なニーズに応える多彩な商品ラインナップを展開している。',
          floorPlanDescription2:
            '理想の住まい実現には、家族の暮らしに沿ったアイデアが重要です。ヤマダホームズは家事を楽にする生活動線や収納計画、インテリアコーディネートなど、17万棟の実績から得た知識で、あなたにふさわしいアイデアをご提案します。',
          floorPlanDescription3:
            '構造強度と耐震性を兼ね備えた「制震ダンパー」を標準仕様として採用。これにより、地震時の揺れを大幅に低減し、壁の損壊や家具転倒を防ぎます。さらに、「ティンバーメタル工法」により、柱や梁の接合部をしっかりと固定。阪神・淡路大震災の教訓を活かし、高い強度と耐震性を実現し、安心の住まいを提供します。',
          floorPlanDescription4:
            '一年を通じての快適な住まいは気密性と断熱性が重要です。硬質ウレタンフォームにより高断熱、気密性を向上させ、光熱費の節約、結露や音漏れを防止しています。また、長期優良住宅仕様で、全熱交換型全館24時間換気システムや清浄空気保持機器を全居室に標準装備しています。',
          floorPlanDescription5:
            '安心の60年サポートシステムで「建てた後も永く安心できる家」を実現。定期メンテナンスにより構造躯体や防水・防蟻を最長60年保証。(※長期優良住宅の認定を取得した建物)。住宅設備は通常1〜2年のメーカー保証を10年間はヤマダホームズがサポートいたします。',
          floorPlanImage1:
            'https://img01.suumo.com/front/gazo/chumon/220/46/main/00024600000044p01.jpg',
          floorPlanImage2:
            'https://www.narahotel.co.jp/files/images/home/img_room_honkan-sp.jpg',
          floorPlanImage3:
            'http://reve-bois-michel.com/build/files/page-body/photo006600.png',
          floorPlanImage4:
            'https://yamadahomes.jp/wp-content/themes/yamadahomes/assets/images/felidia/img03.jpg',
          floorPlanImage5:
            'https://img01.suumo.com/front/gazo/chumon/100/33/main/000733p02.jpg',
          floorPlanTitle1: '70年の歴史が究めた唯一無二のデザイン力',
          floorPlanTitle2: 'アイデア次第で変わる、あなただけの住まいづくり',
          floorPlanTitle3: '揺れに強く、長く安心して住むための技術',
          floorPlanTitle4: '夏涼しく冬暖かい、 高気密住宅で光熱費節約を',
          floorPlanTitle5:
            '信頼の証、 最長60年の構造躯体保証と 10年の住宅設備保証',
          landCategory: '',
          landRightType: '所有権',
          legalOtherLimitationNote:
            '担当者：金澤繁木、設備：電気：大和ハウス工業（他の小売電気事企業の選択も可能です）、ガス：都市ガス、水道：公営水道、下水：公共下水道、建築確認番号：第OUR-938789489号他',
          legalOtherLimitations: '先着順申込受付中',
          mainImages: [],
          maxBuildingArea: '118.15㎡',
          maxLandArea: '170.66㎡',
          maxPrice: 5300,
          maxRoadWidth: '9m',
          minBuildingArea: '103.02㎡',
          minLandArea: '165.73㎡',
          minPrice: 4800,
          minRoadWidth: '6m',
          mostPriceRanges: ['8000', '5000', '4400'],
          openingTime: '10:00',
          orderConditions: '',
          otherCost: 'なし',
          overWorkConditions: [],
          reformExterior: 'none',
          regularHoliday: '年中無休',
          repeatDetail: '',
          repeatWeek: [],
          roadNote: '（7号地）〜（11号地）全て私道負担なし',
          roadPainting: 'アスファルト舗装',
          salesHouseCount: 4,
          salesStartDate: '',
          selection: '',
          startTime: '',
          strengths: [
            'アイデア次第で変わる、あなただけの住まいづくり',
            '70年の歴史が究めた唯一無二のデザイン力',
            '揺れに強く、長く安心して住むための技術',
            'アイデア次第で変わる、あなただけの住まいづくり',
            '70年の歴史が究めた唯一無二のデザイン力',
            '揺れに強く、長く安心して住むための技術',
          ],
          strengthsDescription:
            '積水ハウスは、お客様の感性に合わせた住まいを実現するために、「life knit design」というデザイン提案システムのもと、邸別自由設計で一人ひとりに寄り添う住まいづくりを行っています。それを支えるのは、営業、設計、インテリアコーディネータ。積水ハウスは、お客様の感性に合わせた住まいを実現するために、「life knit design」というデザイン提案システムのもと、邸別自由設計で一人ひとりに寄り添う住まいづくりを行っています。それを支えるのは、営業、設計、インテリアコーディネータ',
          strengthsTitle: '「邸別自由設計」をすべての住まいづくりの基本に',
          totalHouseCount: 10,
          useDistrict: [],
          workAddress1: '',
          workAddress2: '',
          workPostalCode: '',
          workPublicHoliday: true,
        },
        description: '',
        dimensions: '',
        favorite: true,
        features: [],
        id: '1',
        images: [
          {
            id: '',
            isPublic: false,
            ownerId: 'xxx',
            ownerType: 'organization',
            type: 'png',
            url: 'https://www.metaexpo.jp/wp-content/uploads/2022/07/29-expo-model-house-visit-001.jpg',
          },
          {
            id: '',
            isPublic: false,
            ownerId: 'xxx',
            ownerType: 'organization',
            type: 'png',
            url: 'https://kaitai-mado.jp/wp/wp-content/uploads/2022/01/%E4%BD%8F%E5%AE%85%E5%B1%95%E7%A4%BA%E5%A0%B4.jpg',
          },
          {
            id: '',
            isPublic: false,
            ownerId: 'xxx',
            ownerType: 'organization',
            type: 'png',
            url: 'https://www.jutakutenjijo.com/wp-content/uploads/2022/02/%E3%83%8F%E3%83%93%E3%82%BF%E3%80%80%E3%81%95%E3%81%84%E3%81%9F%E3%81%BE%E6%96%B0%E9%83%BD%E5%BF%83%E5%B1%95%E7%A4%BA%E5%A0%B4%E3%80%80%E5%86%99%E7%9C%9F-2.jpg',
          },
          {
            id: '',
            isPublic: false,
            ownerId: 'xxx',
            ownerType: 'organization',
            type: 'png',
            url: 'https://www.idh.co.jp/wp-content/uploads/2022/05/eigyo_img-600x400.jpg',
          },
          {
            id: '',
            isPublic: false,
            ownerId: 'xxx',
            ownerType: 'organization',
            type: 'png',
            url: 'https://www.universalhome.co.jp/iebana/wp-content/uploads/2018/03/1_1_8_22252278-750x500.jpg',
          },
          {
            id: '',
            isPublic: false,
            ownerId: 'xxx',
            ownerType: 'organization',
            type: 'png',
            url: 'https://kaitai-mado.jp/wp/wp-content/uploads/2022/01/%E4%BD%8F%E5%AE%85%E5%B1%95%E7%A4%BA%E5%A0%B4.jpg',
          },
          {
            id: '',
            isPublic: false,
            ownerId: 'xxx',
            ownerType: 'organization',
            type: 'png',
            url: 'https://www.metaexpo.jp/wp-content/uploads/2022/07/29-expo-model-house-visit-001.jpg',
          },
          {
            id: '',
            isPublic: false,
            ownerId: 'xxx',
            ownerType: 'organization',
            type: 'png',
            url: 'https://kaitai-mado.jp/wp/wp-content/uploads/2022/01/%E4%BD%8F%E5%AE%85%E5%B1%95%E7%A4%BA%E5%A0%B4.jpg',
          },
          {
            id: '',
            isPublic: false,
            ownerId: 'xxx',
            ownerType: 'organization',
            type: 'png',
            url: 'https://www.jutakutenjijo.com/wp-content/uploads/2022/02/%E3%83%8F%E3%83%93%E3%82%BF%E3%80%80%E3%81%95%E3%81%84%E3%81%9F%E3%81%BE%E6%96%B0%E9%83%BD%E5%BF%83%E5%B1%95%E7%A4%BA%E5%A0%B4%E3%80%80%E5%86%99%E7%9C%9F-2.jpg',
          },
          {
            id: '',
            isPublic: false,
            ownerId: 'xxx',
            ownerType: 'organization',
            type: 'png',
            url: 'https://www.idh.co.jp/wp-content/uploads/2022/05/eigyo_img-600x400.jpg',
          },
          {
            id: '',
            isPublic: false,
            ownerId: 'xxx',
            ownerType: 'organization',
            type: 'png',
            url: 'https://www.universalhome.co.jp/iebana/wp-content/uploads/2018/03/1_1_8_22252278-750x500.jpg',
          },
          {
            id: '',
            isPublic: false,
            ownerId: 'xxx',
            ownerType: 'organization',
            type: 'png',
            url: 'https://kaitai-mado.jp/wp/wp-content/uploads/2022/01/%E4%BD%8F%E5%AE%85%E5%B1%95%E7%A4%BA%E5%A0%B4.jpg',
          },
        ],
        isPromoted: true,
        locationIds: [],
        locations: [],
        name: 'YAMADA HOMES',
        organization: {
          addressLine1: 'addressLine1',
          addressLine2: 'addressLine2',
          addressLine3: 'addressLine3',
          customFields: {
            access: '',
            addressByCity: '東京都〇〇市△区',
            addressLine4: '1-1-1',
            chatContact: '',
            clinicUrl: '',
            department: '',
            electronicChartMaker: '',
            electronicCharts: [],
            equipmentOther: '',
            equipments: [],
            mainImages: '',
            ownerName: '',
            ownerUserId: '',
            phoneNumber: '',
          },
          id: 'org1',
          name: 'Organization 1',
          phoneNumber: '09011111111',
          postalCode: '1000001',
        },
        organizationId: 'org1',
        publication: { status: 'ACTIVE' },
        tags: ['Tag1', 'Tag2'],
        variantNote: '',
        variants: [
          {
            customFields: {
              exclusiveArea: '64.79',
              floorPlan: '2LDK+WIC+SC+S（納戸）',
            },
            description: '',
            id: '01',
            images: [
              {
                id: '',
                isPublic: true,
                ownerId: '',
                ownerType: '',
                type: '',
                url: 'https://www.takenote1101.com/wp-content/uploads/2019/06/20171206224620.png',
              },
            ],
            price: {
              amount: 1000,
              currency: '',
              taxIncluded: false,
            },
            sku: 'floorPlan',
            title: '間取り０１',
          },
          {
            customFields: {
              exclusiveArea: '64.79',
              floorPlan: '2LDK+WIC+SC+S（納戸）',
            },
            description: '',
            id: '02',
            images: [
              {
                id: '',
                isPublic: true,
                ownerId: '',
                ownerType: '',
                type: '',
                url: 'https://www.nomu.com/mansion/library/trend/files/inoue_20180626_04.jpg',
              },
            ],
            price: {
              amount: 1000,
              currency: '',
              taxIncluded: false,
            },
            sku: 'floorPlan',
            title: '間取り０２',
          },
          {
            customFields: {},
            description:
              '南側道路を挟んで大学敷地となる、開放的な三方角地を活かし、ゆとりある全邸南向きを実現した地上13階建、総62邸の邸宅。都市部にありながら文教の中心地の邸宅として相応しい建築デザインを追求。モダンでありながら、重厚感ある佇まいを創造しました。',
            id: '03',
            images: [
              {
                id: '',
                isPublic: true,
                ownerId: '',
                ownerType: '',
                type: '',
                url: 'https://radiance.ria-partners.co.jp/w/wp-content/uploads/2020/01/2022efb90287d70be888566cc66bbc67.jpg',
              },
            ],
            price: {
              amount: 1000,
              currency: '',
              taxIncluded: false,
            },
            sku: 'exterior',
            title: '外観',
          },
          {
            customFields: {},
            description:
              '自邸の顔となるアプローチの表情を形成するのは、濃色の天然石タイル貼りの上質感溢れる壁面。豊かな植栽やライトアップによって、建築意匠をさらに誇り高い表情を醸成しています。',
            id: '04',
            images: [
              {
                id: '',
                isPublic: true,
                ownerId: '',
                ownerType: '',
                type: '',
                url: 'https://www.kitahorie-kanban.com/blog/wp-content/uploads/2018/09/IMG_1186-e1537501304987.jpg',
              },
            ],
            price: {
              amount: 1000,
              currency: '',
              taxIncluded: false,
            },
            sku: 'exterior',
            title: 'エントランスアプローチ',
          },
          {
            customFields: {},
            description: '',
            id: '05',
            images: [
              {
                id: '',
                isPublic: true,
                ownerId: '',
                ownerType: '',
                type: '',
                url: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRWkIneatUpv9QePs6scI-8zMPGtyNgEUfnVQ&s',
              },
            ],
            price: {
              amount: 1000,
              currency: '',
              taxIncluded: false,
            },
            sku: 'interior',
            title: 'リビング・ダイニング',
          },
          {
            customFields: {},
            description: '',
            id: '06',
            images: [
              {
                id: '',
                isPublic: true,
                ownerId: '',
                ownerType: '',
                type: '',
                url: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS1-tTZuyLeqK9YAMSSVGf1ibBpAjyl1tckhA&s',
              },
            ],
            price: {
              amount: 1000,
              currency: '',
              taxIncluded: false,
            },
            sku: 'interior',
            title: 'プライベートルーム',
          },
          {
            customFields: {},
            description:
              '食器類の出し入れが簡単なスライド式の食器洗浄乾燥機を標準装備。',
            id: '07',
            images: [
              {
                id: '',
                isPublic: true,
                ownerId: '',
                ownerType: '',
                type: '',
                url: 'https://www.homes.co.jp/cont/wp-content/uploads/cont/124590/img/pixta_41519728_S.jpg',
              },
            ],
            price: {
              amount: 1000,
              currency: '',
              taxIncluded: false,
            },
            sku: 'around',
            title: '食器洗浄乾燥機',
          },
          {
            customFields: {},
            description:
              '油汚れなどのお手入れが楽なホーロー製のキッチンパネルを採用。',
            id: '08',
            images: [
              {
                id: '',
                isPublic: true,
                ownerId: '',
                ownerType: '',
                type: '',
                url: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSzQaCkwAHQE6rpN6ueKzipM2HGwx8JiggMlw&s',
              },
            ],
            price: {
              amount: 1000,
              currency: '',
              taxIncluded: false,
            },
            sku: 'around',
            title: 'ホーローキッチンパネル',
          },
          {
            customFields: {},
            description:
              '食器類の出し入れが簡単なスライド式の食器洗浄乾燥機を標準装備。',
            id: '07',
            images: [
              {
                id: '',
                isPublic: true,
                ownerId: '',
                ownerType: '',
                type: '',
                url: 'https://www.homes.co.jp/cont/wp-content/uploads/cont/124590/img/pixta_41519728_S.jpg',
              },
            ],
            price: {
              amount: 1000,
              currency: '',
              taxIncluded: false,
            },
            sku: 'equipment',
            title: '食器洗浄乾燥機',
          },
          {
            customFields: {},
            description:
              '油汚れなどのお手入れが楽なホーロー製のキッチンパネルを採用。',
            id: '08',
            images: [
              {
                id: '',
                isPublic: true,
                ownerId: '',
                ownerType: '',
                type: '',
                url: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSzQaCkwAHQE6rpN6ueKzipM2HGwx8JiggMlw&s',
              },
            ],
            price: {
              amount: 1000,
              currency: '',
              taxIncluded: false,
            },
            sku: 'equipment',
            title: 'ホーローキッチンパネル',
          },
        ],
      },
      {
        additionalInformation: '',
        addressLine1: '大阪府',
        addressLine2: '大阪市天王寺区',
        addressLine3: '南河堀町22番3（地番）',
        attributes: [],
        categoryId: '',
        customFields: {
          access: 'JR大阪環状線「天王寺」駅 徒歩7分',
          addressLine4: '',
          areaRatio: '',
          breakTime: '',
          breakTimeNoParticular: false,
          buildingSystem: '2鉄筋コンクリート造',
          calculatedDairyPay: 0,
          calculatedHourlyPay: 0,
          closingTime: '18:00',
          commutingCost: 0,
          companyName: '株式会社アーネスト',
          completionDate: '',
          constructionCompany: '',
          contactAddress: '東京都〇〇市△区 1-1-1 ',
          contactPhoneNumber: '06-0000-000',
          count: 0,
          day: '',
          deliveryDateType: '即入居可',
          endTime: '',
          eventContent: '',
          floorPlanDescription1:
            '「邸別自由設計」で理想の住まいをカタチにしてきた積水ハウスでは、広がる大空間を叶える、「ダイナミックフレームシステム（鉄骨1・2階建て）」、各階で自由に間取りをプラン二ングできる「フレキシブルβシステム（鉄骨3・4階建て）」、光と風を取り入れて四季を通して快適に住まう「シャーウッド（木造住宅）」という3つの独自構法をベースに、様々なニーズに応える多彩な商品ラインナップを展開している。',
          floorPlanDescription2:
            '理想の住まい実現には、家族の暮らしに沿ったアイデアが重要です。ヤマダホームズは家事を楽にする生活動線や収納計画、インテリアコーディネートなど、17万棟の実績から得た知識で、あなたにふさわしいアイデアをご提案します。',
          floorPlanDescription3:
            '構造強度と耐震性を兼ね備えた「制震ダンパー」を標準仕様として採用。これにより、地震時の揺れを大幅に低減し、壁の損壊や家具転倒を防ぎます。さらに、「ティンバーメタル工法」により、柱や梁の接合部をしっかりと固定。阪神・淡路大震災の教訓を活かし、高い強度と耐震性を実現し、安心の住まいを提供します。',
          floorPlanDescription4:
            '一年を通じての快適な住まいは気密性と断熱性が重要です。硬質ウレタンフォームにより高断熱、気密性を向上させ、光熱費の節約、結露や音漏れを防止しています。また、長期優良住宅仕様で、全熱交換型全館24時間換気システムや清浄空気保持機器を全居室に標準装備しています。',
          floorPlanDescription5:
            '安心の60年サポートシステムで「建てた後も永く安心できる家」を実現。定期メンテナンスにより構造躯体や防水・防蟻を最長60年保証。(※長期優良住宅の認定を取得した建物)。住宅設備は通常1〜2年のメーカー保証を10年間はヤマダホームズがサポートいたします。',
          floorPlanImage1:
            'https://img01.suumo.com/front/gazo/chumon/220/46/main/00024600000044p01.jpg',
          floorPlanImage2:
            'https://www.narahotel.co.jp/files/images/home/img_room_honkan-sp.jpg',
          floorPlanImage3:
            'http://reve-bois-michel.com/build/files/page-body/photo006600.png',
          floorPlanImage4:
            'https://yamadahomes.jp/wp-content/themes/yamadahomes/assets/images/felidia/img03.jpg',
          floorPlanImage5:
            'https://img01.suumo.com/front/gazo/chumon/100/33/main/000733p02.jpg',
          floorPlanTitle1: '70年の歴史が究めた唯一無二のデザイン力',
          floorPlanTitle2: 'アイデア次第で変わる、あなただけの住まいづくり',
          floorPlanTitle3: '揺れに強く、長く安心して住むための技術',
          floorPlanTitle4: '夏涼しく冬暖かい、 高気密住宅で光熱費節約を',
          floorPlanTitle5:
            '信頼の証、 最長60年の構造躯体保証と 10年の住宅設備保証',
          landCategory: '',
          landRightType: '所有権',
          legalOtherLimitationNote:
            '担当者：金澤繁木、設備：電気：大和ハウス工業（他の小売電気事企業の選択も可能です）、ガス：都市ガス、水道：公営水道、下水：公共下水道、建築確認番号：第OUR-938789489号他',
          legalOtherLimitations: '先着順申込受付中',
          mainImages: [],
          maxBuildingArea: '118.15㎡',
          maxLandArea: '170.66㎡',
          maxPrice: 5300,
          maxRoadWidth: '9m',
          minBuildingArea: '103.02㎡',
          minLandArea: '165.73㎡',
          minPrice: 4800,
          minRoadWidth: '6m',
          mostPriceRanges: ['8000', '5000', '4400'],
          openingTime: '10:00',
          orderConditions: '',
          otherCost: 'なし',
          overWorkConditions: [],
          reformExterior: 'none',
          regularHoliday: '年中無休',
          repeatDetail: '',
          repeatWeek: [],
          roadNote: '（7号地）〜（11号地）全て私道負担なし',
          roadPainting: 'アスファルト舗装',
          salesHouseCount: 4,
          salesStartDate: '',
          selection: '',
          startTime: '',
          strengths: [
            'アイデア次第で変わる、あなただけの住まいづくり',
            '70年の歴史が究めた唯一無二のデザイン力',
            '揺れに強く、長く安心して住むための技術',
          ],
          strengthsDescription:
            '積水ハウスは、お客様の感性に合わせた住まいを実現するために、「life knit design」というデザイン提案システムのもと、邸別自由設計で一人ひとりに寄り添う住まいづくりを行っています。それを支えるのは、営業、設計、インテリアコーディネータ。積水ハウスは、お客様の感性に合わせた住まいを実現するために、「life knit design」というデザイン提案システムのもと、邸別自由設計で一人ひとりに寄り添う住まいづくりを行っています。それを支えるのは、営業、設計、インテリアコーディネータ',
          strengthsTitle: '「邸別自由設計」をすべての住まいづくりの基本に',
          totalHouseCount: 10,
          useDistrict: [],
          workAddress1: '',
          workAddress2: '',
          workPostalCode: '',
          workPublicHoliday: true,
        },
        description: '',
        dimensions: '',
        favorite: true,
        features: [],
        id: '',
        images: [
          {
            id: '',
            isPublic: false,
            ownerId: 'xxx',
            ownerType: 'organization',
            type: 'png',
            url: 'https://thumb.ac-illust.com/e1/e100c43d8a26007a9bb811c9af8e756e_t.jpeg',
          },
          {
            id: '',
            isPublic: false,
            ownerId: 'xxx',
            ownerType: 'organization',
            type: 'png',
            url: 'https://thumb.ac-illust.com/cf/cf8b964d27b4222926f632cda9ad5eb4_t.jpeg',
          },
          {
            id: '',
            isPublic: false,
            ownerId: 'xxx',
            ownerType: 'organization',
            type: 'png',
            url: 'https://cdn.douga-kanji.com/files/media/BXbs2q6WywroTNiMYz7dinoHYo5eIXZSvE28F5gU.jpeg',
          },
          {
            id: '',
            isPublic: false,
            ownerId: 'xxx',
            ownerType: 'organization',
            type: 'png',
            url: 'https://static9.depositphotos.com/1431107/1154/i/450/depositphotos_11542091-stock-photo-sample-stamp.jpg',
          },
          {
            id: '',
            isPublic: false,
            ownerId: 'xxx',
            ownerType: 'organization',
            type: 'png',
            url: 'https://st.depositphotos.com/2274151/4841/i/450/depositphotos_48410095-stock-photo-sample-blue-square-grungy-stamp.jpg',
          },
        ],
        isPromoted: true,
        locationIds: [],
        locations: [],
        name: 'Product 2',
        organization: {
          addressLine1: 'addressLine1',
          addressLine2: 'addressLine2',
          addressLine3: 'addressLine3',
          customFields: {
            access: '',
            addressLine4: '',
            chatContact: '',
            clinicUrl: '',
            department: '',
            electronicChartMaker: '',
            electronicCharts: [],
            equipmentOther: '',
            equipments: [],
            mainImages: '',
            ownerName: '',
            ownerUserId: '',
            phoneNumber: '',
          },
          id: 'org2',
          name: 'Organization 2',
          phoneNumber: '09011111111',
          postalCode: '1000001',
        },
        organizationId: 'org2',
        publication: { status: 'ACTIVE' },
        tags: ['Tag1', 'Tag2'],
        variantNote: '',
        variants: [
          {
            customFields: {
              exclusiveArea: '64.79㎡',
              floorPlan: '2LDK+WIC+SC+S（納戸）',
            },
            description: '',
            id: '01',
            images: [
              {
                id: '',
                isPublic: true,
                ownerId: '',
                ownerType: '',
                type: '',
                url: 'https://lh3.googleusercontent.com/proxy/E4jhwgvvXuvQzkTJMJpx0b0ITx3r-bvNxdXbz8ImYMuvDuL_4_9PoIBSPreRtpINcjL9GXIgGh5fMotTrqpT8-p2Cg',
              },
            ],
            price: {
              amount: 1000,
              currency: '',
              taxIncluded: false,
            },
            title: '間取り０１',
          },
          {
            customFields: {
              exclusiveArea: '64.79㎡',
              floorPlan: '2LDK+WIC+SC+S（納戸）',
            },
            description: '',
            id: '02',
            images: [
              {
                id: '',
                isPublic: true,
                ownerId: '',
                ownerType: '',
                type: '',
                url: 'https://www.nomu.com/mansion/library/trend/files/inoue_20180626_04.jpg',
              },
            ],
            price: {
              amount: 1000,
              currency: '',
              taxIncluded: false,
            },
            title: '間取り０２',
          },
        ],
      },
    ],
    nextLink: '',
    scrollY: 0,
    total: 2,
  },
  key: 'searchResultProductsDummy',
});

export const searchConditionState = atom<SearchCondition | null>({
  default: null,
  key: 'searchConditionState',
});

export const searchProductsState = atom<Product[]>({
  default: [],
  key: 'searchProductsState',
});

export const searchProductTotalState = atom<number>({
  default: 0,
  key: 'searchProductTotalState',
});
