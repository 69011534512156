import { Button, Stack } from '@mui/material';
import React, { ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import { useRecoilState } from 'recoil';

import { ExhibitionFormFields } from '@app/components/Product/company/Inquiry/ExhibitionFormFields';
import { InquiryStepTwoState } from '@app/domain/order';
import { CompanyInquiryStepSecondQuestions } from '@app/static/constants';
import { theme } from '@app/theme';
import { InquiryStepTwoFormData } from '@app/types/order';

interface ExhibitionStep2Props {
  defaultValues?: InquiryStepTwoFormData;
  onBack: () => void;
  onSubmit: (data: InquiryStepTwoFormData) => void;
}

export function ExhibitionStep2({
  onSubmit,
  onBack,
  defaultValues,
}: ExhibitionStep2Props): ReactElement {
  const [stepData, setStepData] = useRecoilState(InquiryStepTwoState);

  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm<InquiryStepTwoFormData>({
    defaultValues: defaultValues || stepData,
  });

  const handleBack = async () => {
    const currentData = getValues();
    await setStepData((prevData: InquiryStepTwoFormData) => ({
      ...prevData,
      ...currentData,
    }));
    onBack();
  };

  return (
    <Stack
      sx={{
        alignItems: 'center',
        width: '100%',
      }}
      spacing={4}
    >
      <Stack
        component="form"
        onSubmit={handleSubmit((data) => {
          setStepData((prevData: InquiryStepTwoFormData) => ({
            ...prevData,
            ...data,
          }));
          onSubmit(data);
        })}
        sx={{
          backgroundColor: theme.palette.neutral.white,
          borderRadius: '8px',
          flexDirection: 'column',
          maxWidth: '800px',
          padding: '40px',
          width: '100%',
        }}
      >
        <ExhibitionFormFields
          questions={CompanyInquiryStepSecondQuestions}
          control={control}
          errors={errors}
          isDisabled={false}
        />
        <Stack
          direction="row"
          spacing={2}
          sx={{ mb: 12, mt: 10, width: '100%' }}
        >
          <Button
            type="button"
            variant="outlined"
            color="secondary"
            sx={{ flex: 1, p: 2 }}
            onClick={handleBack}
          >
            戻る
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{ flex: 1, p: 2 }}
          >
            次へ
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
}
