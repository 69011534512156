import { Box, Container, Typography } from '@mui/material';
import React, { ReactElement } from 'react';
import { useRecoilValue } from 'recoil';

import { HeadBlock } from '@app/components/Shared/HeadBlock';
import { notificationState } from '@app/domain/notification';
import { Message } from '@app/types/message';

export function NotificationDetail(): ReactElement {
  const message = useRecoilValue<Message | undefined>(notificationState);
  return (
    <>
      <HeadBlock />
      <Container sx={{ pt: 3 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
          <Typography variant="subtitle1" fontWeight={600}>
            {message?.title}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'end' }}>
          <Typography variant="body2" fontWeight={600}>
            {message?.publications.since}
          </Typography>
        </Box>
        <Box
          sx={{
            p: 5,
          }}
        >
          {message?.content}
        </Box>
      </Container>
    </>
  );
}
