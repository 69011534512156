import { CanceledError } from 'axios';
import { useState, useEffect, useCallback } from 'react';
import { atom, selector, DefaultValue, useSetRecoilState } from 'recoil';

import { checkToken } from '@app/adapter/auth-service';
import {
  setResponseInterceptor,
  resetResponseInterceptor,
} from '@app/adapter/axios';
import { getUser } from '@app/adapter/user-service';
import { generateFingerPrint } from '@app/domain/fingerprint';
import { ERROR_MESSAGE, STORAGE_KEYS } from '@app/static/constants';
import { User } from '@app/types/user';
import {
  getStoredAccessToken,
  getStoredUserId,
  storeAccessToken,
  storeUserId,
  removeStoredAccessToken,
  removeStoredUserId,
} from '@app/utils/auth';
import { isDemanderUser } from '@app/utils/user';

/**
 * blocks-5f90
 *
 * Store a user's auth information like id and token.
 */
export const userAuthInfoState = atom<{
  accessToken: string;
  fingerPrint: string;
  userId: string;
} | null>({
  default: null,
  key: 'userAuthInfoState',
});

/**
 * blocks-5f90
 *
 * A selector for userAuthInfoState
 */
export const userAuthInfoSelector = selector<{
  accessToken: string;
  fingerPrint: string;
  userId: string;
} | null>({
  get: ({ get }) => {
    // If the user auth info is in atom state.
    const authInfo = get(userAuthInfoState);
    if (
      Boolean(authInfo?.userId) &&
      Boolean(authInfo?.accessToken) &&
      Boolean(authInfo?.fingerPrint)
    ) {
      return authInfo;
    }

    // If the user auth info is in localStorage.
    const permanent = {
      token: getStoredAccessToken(),
    };
    if (permanent?.token) {
      // TODO: 元実装の意図確認, API request しなければ null のはず?
      return authInfo;
    }

    // Otherwise, return null.
    return null;
  },
  key: 'userAuthInfoSelector',
  set: ({ set }, newValue) => {
    if (newValue instanceof DefaultValue || !newValue) {
      set(userAuthInfoState, newValue);
      removeStoredAccessToken();
      return;
    }
    storeAccessToken(newValue.accessToken);
    storeUserId(newValue.userId);
    set(userAuthInfoState, newValue);
  },
});

//
// verify Stored access token hook
//
export const TOKEN_VERIFY_STATUS = {
  INVALID: 'invalid', // 認証NG
  PROGRESS: 'progress', // 処理中
  VALID: 'valid', // 認証OK
};
export type TokenVerifyStatus =
  (typeof TOKEN_VERIFY_STATUS)[keyof typeof TOKEN_VERIFY_STATUS];

export function useVerifyStoredAccessToken() {
  const [verifyState, setVerifyState] = useState<TokenVerifyStatus>(
    TOKEN_VERIFY_STATUS.PROGRESS
  );
  const setUserAuthInfo = useSetRecoilState(userAuthInfoSelector);
  const setLoggedInUser = useSetRecoilState(loggedInUserState);
  const clearAuthStateAndStorage = useClearAuthStateAndStorage();

  const verifyToken = useCallback(
    async (abortController: AbortController) => {
      const accessToken = getStoredAccessToken();
      if (!accessToken) {
        return false;
      }
      const fingerPrint = await generateFingerPrint();
      const checkTokenResponse = await checkToken<{ userId: string }>(
        accessToken,
        fingerPrint,
        abortController
      );
      const userId = checkTokenResponse.data.userId;
      if (userId !== getStoredUserId()) {
        throw new Error(ERROR_MESSAGE.INVALID_USER_ID);
      }

      setUserAuthInfo({
        accessToken,
        fingerPrint,
        userId,
      });
      const userResponse = await getUser(
        userId,
        accessToken,
        fingerPrint,
        abortController
      );
      if (!isDemanderUser(userResponse.data.typeId)) {
        throw new Error(ERROR_MESSAGE.INVALID_USER_TYPE);
      }

      setLoggedInUser(userResponse.data);

      return true;
    },
    [setUserAuthInfo, setLoggedInUser]
  );

  useEffect(() => {
    const abortController = new AbortController();
    const execute = async () => {
      try {
        const isValid = await verifyToken(abortController);
        setVerifyState(
          isValid ? TOKEN_VERIFY_STATUS.VALID : TOKEN_VERIFY_STATUS.INVALID
        );
        setResponseInterceptor(() => {
          clearAuthStateAndStorage();
        });
      } catch (error) {
        if (error instanceof CanceledError) {
          return;
        }
        console.error('verifyToken ERROR', { error });
        clearAuthStateAndStorage();
        resetResponseInterceptor();
        setVerifyState(TOKEN_VERIFY_STATUS.INVALID);
      }
    };
    void execute();

    // CLEAN-UP
    return () => {
      abortController.abort();
    };
  }, [verifyToken, clearAuthStateAndStorage]);

  return verifyState;
}

//
// Sign out utility
//
export function useClearAuthStateAndStorage() {
  const setUserAuthInfo = useSetRecoilState(userAuthInfoSelector);
  const setLoggedInUser = useSetRecoilState(loggedInUserState);

  return useCallback(() => {
    // storage
    removeStoredAccessToken();
    removeStoredUserId();

    // state (recoil)
    setLoggedInUser(null);
    setUserAuthInfo(null);
  }, [setUserAuthInfo, setLoggedInUser]);
}

/**
 * Store a logged in user's data information
 */
const AFTER_LOGIN_ROUTE_KEY = STORAGE_KEYS.AFTER_LOGIN_ROUTE;

export const loggedInUserState = atom<User | null>({
  default: null,
  key: 'loggedInUserState',
});

export const afterLoginRouteState = atom<string | null>({
  default: null,
  key: 'afterLoginRouteState',
});
export const afterLoginRouteSelector = selector<string | null>({
  get: ({ get }) => {
    const data = get(afterLoginRouteState);
    if (data) return data;
    return globalThis.localStorage.getItem(AFTER_LOGIN_ROUTE_KEY) || null;
  },
  key: 'afterLoginRouteSelector',
  set: ({ set }, newValue) => {
    if (newValue instanceof DefaultValue || !newValue) {
      set(afterLoginRouteState, newValue);
      globalThis.localStorage.removeItem(AFTER_LOGIN_ROUTE_KEY);
      return;
    }
    globalThis.localStorage.setItem(AFTER_LOGIN_ROUTE_KEY, newValue);
    set(afterLoginRouteState, newValue);
  },
});
