// import FingerprintJS, { GetResult, BuiltinComponents } from '@fingerprintjs/fingerprintjs';

// type FPComponents = Omit<BuiltinComponents, 'cookiesEnabled'>;

// const waitForPageLoad = new Promise((resolve) => {
//   const handlePageLoad = () => {
//     window.removeEventListener('load', handlePageLoad);
//     console.info('@utils/fingerPrint', 'page loaded');
//     resolve(true);
//   };
//   window.addEventListener('load', handlePageLoad);
// });

// function getComponents(result: GetResult): FPComponents {
//   const { cookiesEnabled, ...rest } = result.components;
//   return rest;
// }

// function hashComponents(result: GetResult): string {
//   const components = getComponents(result);
//   return FingerprintJS.hashComponents(components);
// }

// FIXME: fix generate finger print logic
export async function generateFingerPrint() {
  return btoa('fingerprint');
}
