import { ReactElement } from 'react';

import { Completed } from '@app/components/Order/Completed';
import { BottomMenu } from '@app/components/Shared/BottomMenu';
import { HeadBlock } from '@app/components/Shared/HeadBlock';
import { BOTTOM_MENU_ITEMS } from '@app/static/constants';

export function ExhibitionCompletedPage(): ReactElement {
  return (
    <>
      <HeadBlock />
      <Completed isExhibition={true} />
      <BottomMenu menuItems={BOTTOM_MENU_ITEMS} />
    </>
  );
}
