import 'material-symbols';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';

import floorPlanDefault from '@app/static/images/floorPlanDefault.png';
import homeTop from '@app/static/images/homeTop.jpeg';
import logoWhite from '@app/static/images/logo_white.png';
import logo from '@app/static/images/logo_wide.png';
import orderDetailTop from '@app/static/images/orderDetailTop.jpg';
import productDefault from '@app/static/images/productDefault.png';
import registerMainImage from '@app/static/images/registerMainImage.jpeg';

export const imageAssets = {
  floorPlanDefault,
  homeTop,
  logo,
  logoWhite,
  orderDetailTop,
  productDefault,
  registerMainImage,
};

const palette = {
  black: {
    contrastText: '#fff',
    main: '#000',
  },
  error: {
    contrastText: '#fff',
    main: '#ee6363',
  },
  info: {
    contrastText: '#fff',
    main: '#039c9f',
  },
  neutral: {
    blueDark: '#487AA5',
    blueLight: '#5d95c8',
    contrastText: '#000',
    greyDark: '#8890a0',
    greyLight: '#f8f8f9',
    main: '#dbdfe5',
    redLight: '#FFF2F2',
    silver: '#e8e8e8',
    sky: '#EBF4F9',
    white: '#fff',
  },
  orange: {
    contrastText: '#fff',
    main: '#ff8652',
  },
  primary: {
    contrastText: '#fff',
    main: '#19a7ce',
  },
  purple: {
    contrastText: '#fff',
    main: '#6a46d1',
  },
  secondary: {
    contrastText: '#000',
    main: '#fff',
  },
  text: {
    black: '#2f2f2f',
    primary: '#2f2f2f',
    secondary: '#828a9b',
    white: '#fff',
  },
};

type CustomNeutralPalette = {
  blueDark: string;
  blueLight: string;
  contrastText: string;
  greyDark: string;
  greyLight: string;
  main: string;
  redLight: string;
  silver: string;
  sky: string;
  white: string;
};

declare module '@mui/material/styles' {
  interface Palette {
    neutral: CustomNeutralPalette;
    orange: Palette['primary'];
    purple: Palette['primary'];
  }

  interface PaletteOptions {
    neutral?: CustomNeutralPalette;
    orange?: PaletteOptions['primary'];
    purple?: PaletteOptions['primary'];
  }

  interface TypographyVariants {
    body3: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    body3?: React.CSSProperties;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    black: true;
    neutral: true;
    orange: true;
    purple: true;
  }
}

declare module '@mui/material/IconButton' {
  interface IconButtonPropsColorOverrides {
    black: true;
    neutral: true;
    orange: true;
    purple: true;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body3: true;
  }
}

declare module '@mui/material/SvgIcon' {
  interface SvgIconPropsColorOverrides {
    neutral: true;
  }
}

const internalTheme = createTheme({
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          '& .MuiCollapse-root': {
            borderBottom: '0.5px solid #dbdfe5',
          },
          '&::before': {
            height: 0,
          },
          boxShadow: 'none',
          marginBottom: '0 !important',
          marginTop: '0 !important',
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          '& .MuiAccordionSummary-content': {
            alignItems: 'center',
            margin: '5px 0',
          },
          '& .MuiAccordionSummary-content.Mui-expanded': {
            margin: '5px 0',
          },
          '& .MuiAccordionSummary-expandIconWrapper': {
            color: '#8890a0',
          },
          '&.Mui-expanded': {
            alignItems: 'center',
            minHeight: 'unset !important',
          },
          borderBottom: '0.5px solid #dbdfe5',
          boxShadow: 'none',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          backgroundImage: 'linear-gradient(45deg, #00E0D5 30%, #3B8EC0 90%)',
          color: `${palette.text.white} !important`,
          fontWeight: 'bold',
        },
        outlinedSecondary: {
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
          },
          borderColor: palette.text.primary,
          color: palette.text.primary,
          fontWeight: 'bold',
        },
        root: {
          '&.Mui-disabled': {
            background: '#E0E0E0',
            color: '#fff',
          },
          borderRadius: '8px',
          boxShadow: 'none !important',
          fontWeight: '700',
          minHeight: '40px',
        },
      },
    },

    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '6px',
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          ':last-child': {
            paddingBottom: '16px',
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        sizeSmall: ({ ownerState }) => ({
          borderRadius: '4px',
          fontSize: '0.7rem !important',
          fontWeight: 600,
          ...(ownerState.color === 'primary'
            ? {
                backgroundColor: palette.primary.main,
              }
            : {}),
          '& .MuiChip-icon': {
            fontSize: 'small',
          },
        }),
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          padding: '0 32px',
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          color: palette.text.primary,
          mozOsxFontSmoothing: 'grayscale',
          webkitFontSmoothing: 'antialiased',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: palette.text.primary,
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          '&.AppCheckboxListItem': {
            '& .MuiListItemIcon-root': {
              minWidth: 'unset',
            },
            borderBottom: '0.5px solid #dbdfe5',
            fontSize: '1rem',
            padding: '0.2em 0',
          },
        },
      },
    },
    MuiRating: {
      styleOverrides: {
        root: {
          color: '#f4d116',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          padding: '11px',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontWeight: 400,
          minWidth: '85px',
          padding: '12px 4px',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        body: {
          borderBottom: '0.5px solid #dbdfe5',
          borderTop: 'unset',
          fontSize: '1rem',
        },
        head: {
          borderBottom: '0.5px solid #dbdfe5',
          borderTop: '0.5px solid #dbdfe5',
          paddingBottom: '0.65rem !important',
          paddingTop: '0.65rem !important',
          textAlign: 'center',
        },
        sizeMedium: {
          paddingBottom: '1.65rem',
          paddingTop: '1.65rem',
        },
        sizeSmall: {
          fontSize: '0.875rem',
          paddingBottom: '0.75rem',
          paddingTop: '0.75rem',
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: '#f8f8f9',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-input': {
            padding: '11px',
          },
          marginBottom: 0,
          marginTop: 0,
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          '&.required-label:after': {
            backgroundColor: palette.error.main,
            borderRadius: '4px',
            color: 'white',
            content: '"必須"',
            fontSize: '0.75rem',
            marginLeft: '4px',
            padding: '2px 4px',
          },
        },
      },
    },
  },
  palette,
  shadows: {
    ...createTheme().shadows,
    1: `0px 2px 2px ${palette.secondary.main}`,
  },
  typography: {
    body1: {
      fontSize: '1rem',
    },
    body2: {
      fontSize: '0.875rem',
    },
    body3: {
      fontSize: '0.75rem',
    },
    fontFamily: [
      '"M PLUS 1"',
      '"Noto Sans JP"',
      '"Helvetica Neue"',
      'Arial',
      '"Meiryo"',
      'sans-serif',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
      '"Apple Color Emoji"',
    ].join(','),

    fontSize: 16,
    h2: {
      fontSize: '1.75rem',
    },
    h3: {
      fontSize: '1.5rem',
    },
    h5: {
      fontSize: '0.6875rem',
    },
  },
});

export const theme = responsiveFontSizes(internalTheme);
