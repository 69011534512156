import { Button, Stack, Typography } from '@mui/material';
import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';

import { theme } from '@app/theme';
import { TAB_NAME } from '@app/views/User/Index';

const dataFields = [
  {
    key: 'key_1',
    label: '姓名',
    value: '山田太郎',
  },
  {
    key: 'key_2',
    label: '姓名（かな）',
    value: 'やまだたろう',
  },
  {
    key: 'key_3',
    label: '性別',
    value: '男性',
  },
  {
    key: 'key_4',
    label: '生年月日',
    value: '2000年1月1日',
  },
  {
    key: 'key_5',
    label: '住所',
    value: (
      <>
        <Typography>〒100-0001</Typography>
        <Typography>渋谷区渋谷1-1-1</Typography>
      </>
    ),
  },
  {
    key: 'key_6',
    label: '電話番号',
    value: '090-1111-1111',
  },
  {
    key: 'key_7',
    label: '職業',
    value: '会社員',
  },
  {
    key: 'key_8',
    label: '勤務先',
    value: '〇〇商事',
  },
  {
    key: 'key_9',
    label: '世帯年収',
    value: '1000万円',
  },
  {
    key: 'key_10',
    label: '家族・世帯構成',
    value: '配偶者,お子様,その他',
  },
];
export function ProfileInfo(): ReactElement {
  const navigate = useNavigate();
  const navigateToEditProfile = () => {
    navigate(TAB_NAME.PROFILE_EDIT);
  };
  return (
    <>
      <Stack justifyContent="space-between" direction="row" mb={4}>
        <Typography variant="h3" fontWeight="bold">
          個人情報
        </Typography>
        <Button
          onClick={navigateToEditProfile}
          sx={{
            alignItems: 'center',
            border: 1,
            borderColor: theme.palette.grey[400],
            borderRadius: 1,
            color: '#333',
            display: 'inline-flex',
            fontSize: '14px',
            fontWeight: 'bold',
            px: 4,
          }}
        >
          編集する
        </Button>
      </Stack>
      <Stack
        spacing={3}
        sx={{
          border: 1,
          borderColor: theme.palette.grey[400],
          borderRadius: 1,
          mb: 12,
          p: 3,
        }}
      >
        {dataFields.map((field) => (
          <Stack key={field.key}>
            <Typography variant="body3" mb={1}>
              {field.label}
            </Typography>
            <Typography variant="body2">{field.value}</Typography>
          </Stack>
        ))}
      </Stack>
    </>
  );
}
