import {
  Box,
  Typography,
  MenuItem,
  FormControl,
  Select,
  Button,
  Stack,
  TextField,
  FormHelperText,
} from '@mui/material';
import React, { ReactElement } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useRecoilState } from 'recoil';

import { FormLabelComponent } from '@app/components/Order/FormLabelComponents';
import { InquiryStepOneState } from '@app/domain/order';
import {
  InquiryStepOneForm,
  defaultInquiryStepOneFormData,
} from '@app/schemas/order';
import { theme } from '@app/theme';
import { InquiryStepOneFormData } from '@app/types/order';
import { OrderStep } from '@app/views/Product/Exhibitions/company/Register';

interface ExhibitionStep1Props {
  defaultValues?: InquiryStepOneFormData;
  goToStep2: (value: React.SetStateAction<OrderStep>) => void;
  onSubmit: (data: InquiryStepOneFormData) => void;
}

export function ExhibitionStep1({
  onSubmit,
  defaultValues = defaultInquiryStepOneFormData,
  goToStep2,
}: ExhibitionStep1Props): ReactElement {
  const [stepData, setStepData] = useRecoilState(InquiryStepOneState);

  const initialData: InquiryStepOneFormData = {
    ...defaultValues,
    ...stepData,
  };

  const { control, handleSubmit } = useForm<InquiryStepOneFormData>({
    defaultValues: initialData,
    mode: 'onChange',
    resolver: InquiryStepOneForm.resolver,
  });

  const handleStep1Submit = (data: InquiryStepOneFormData) => {
    setStepData((prevData) => ({
      ...prevData,
      customFields: {
        ...prevData.customFields,
        ...data.customFields,
      },
      customer: {
        ...prevData.customer,
        ...data.customer,
      },
    }));
    onSubmit({
      ...data,
      customFields: {
        ...data.customFields,
      },
    });
  };

  return (
    <Stack
      sx={{
        alignItems: 'center',
        pb: 4,
        width: '100%',
      }}
      spacing={4}
    >
      <Box
        sx={{
          maxWidth: '800px',
          position: 'relative',
          width: '100%',
        }}
      >
        <Stack
          component="form"
          onSubmit={handleSubmit(handleStep1Submit, () => {
            console.log();
            goToStep2(OrderStep.STEP2);
          })}
          sx={{
            backgroundColor: theme.palette.neutral.white,
            maxWidth: '800px',
            mb: 20,
            padding: '40px',
            width: '100%',
          }}
        >
          <Typography variant="body1" fontWeight="bold" sx={{ mb: 4 }}>
            2. あなたについて教えてください
          </Typography>
          <FormControl fullWidth variant="outlined" sx={{ mb: 4 }}>
            {FormLabelComponent('名前', true)}
            <Controller
              name="customer.name"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  error={!!error}
                  helperText={error?.message}
                  placeholder="山田太郎"
                />
              )}
            />
          </FormControl>

          <FormControl fullWidth variant="outlined" sx={{ mb: 4 }}>
            {FormLabelComponent('フリガナ', true)}
            <Controller
              name="customer.nameKana"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  error={!!error}
                  helperText={error?.message}
                  placeholder="ヤマダタロウ"
                />
              )}
            />
          </FormControl>

          <FormControl fullWidth variant="outlined" sx={{ mb: 4 }}>
            {FormLabelComponent('年齢', true)}
            <Stack>
              <Controller
                name="customer.age"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <>
                    <Stack direction="row" alignItems="center">
                      <TextField
                        {...field}
                        type="number"
                        error={!!error}
                        placeholder="30"
                        sx={{ width: '100px' }}
                      />
                      <Typography sx={{ ml: 1 }}>歳</Typography>
                    </Stack>
                    {error && (
                      <FormHelperText error>{error.message}</FormHelperText>
                    )}
                  </>
                )}
              />
            </Stack>
          </FormControl>

          <FormControl fullWidth variant="outlined" sx={{ mb: 4 }}>
            {FormLabelComponent('電話番号', true)}
            <Controller
              name="customer.phone"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  type="text"
                  error={!!error}
                  helperText={error?.message}
                  placeholder="09012345678"
                />
              )}
            />
          </FormControl>

          <FormControl fullWidth variant="outlined" sx={{ mb: 4 }}>
            {FormLabelComponent('メールアドレス', true)}
            <Controller
              name="customer.email"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  type="email"
                  error={!!error}
                  helperText={error?.message}
                  placeholder="email@example.com"
                />
              )}
            />
            <Typography
              color={theme.palette.neutral.greyDark}
              sx={{ ml: 1, mt: 1 }}
            >
              ※記入したメールアドレスに申し込み完了メールが届きます。
            </Typography>
          </FormControl>

          <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
            {FormLabelComponent('住所', true)}
            <Stack spacing={2}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography
                  color="textPrimary"
                  variant="body2"
                  fontWeight={400}
                  sx={{ mb: 1, minWidth: '145px' }}
                >
                  郵便番号
                </Typography>
                <Typography>〒</Typography>
                <Controller
                  name="customFields.postalCode"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <>
                      <Stack direction="row" alignItems="center">
                        <TextField
                          {...field}
                          type="text"
                          placeholder="123"
                          error={!!error}
                          sx={{ mr: 1.5, width: '80px' }}
                        />
                        ー
                        <TextField
                          {...field}
                          type="text"
                          placeholder="0000"
                          error={!!error}
                          sx={{ ml: 1.5, width: '80px' }}
                        />
                        {error && (
                          <FormHelperText error>{error.message}</FormHelperText>
                        )}
                      </Stack>
                    </>
                  )}
                />
              </Stack>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography
                  color="textPrimary"
                  variant="body2"
                  fontWeight={400}
                  sx={{ mb: 1, minWidth: '145px' }}
                >
                  都道府県
                </Typography>
                <Controller
                  name="customer.addressLine1"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <FormControl error={!!error} sx={{ width: '200px' }}>
                      <Select
                        {...field}
                        displayEmpty
                        sx={{
                          '.MuiSvgIcon-root': {
                            color: theme.typography.body1,
                          },
                          backgroundColor: theme.palette.neutral.white,
                          color: theme.typography.body1,
                        }}
                      >
                        <MenuItem value="" disabled>
                          <em>選択する</em>
                        </MenuItem>
                        <MenuItem value="東京都">東京都</MenuItem>
                        <MenuItem value="大阪府">大阪府</MenuItem>
                        <MenuItem value="愛知県">愛知県</MenuItem>
                      </Select>
                      {error && (
                        <FormHelperText>{error.message}</FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
              </Stack>

              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography
                  color="textPrimary"
                  variant="body2"
                  fontWeight={400}
                  sx={{ mb: 1, minWidth: '145px' }}
                >
                  市区町村
                </Typography>
                <Controller
                  name="customer.addressLine2"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      type="text"
                      placeholder="葛飾区"
                      error={!!error}
                      helperText={error?.message}
                      sx={{ flex: 1 }}
                    />
                  )}
                />
              </Stack>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography
                  color="textPrimary"
                  variant="body2"
                  fontWeight={400}
                  sx={{ mb: 1, minWidth: '145px' }}
                >
                  町名
                </Typography>
                <Controller
                  name="customer.addressLine3"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      type="text"
                      placeholder="金町"
                      error={!!error}
                      helperText={error?.message}
                      sx={{ flex: 1 }}
                    />
                  )}
                />
              </Stack>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography
                  color="textPrimary"
                  variant="body2"
                  fontWeight={400}
                  sx={{ mb: 1, minWidth: '145px' }}
                >
                  番地
                </Typography>
                <Controller
                  name="customFields.addressLine4"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      type="text"
                      placeholder="1-2-3"
                      error={!!error}
                      helperText={error?.message}
                      sx={{ flex: 1 }}
                    />
                  )}
                />
              </Stack>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography
                  color="textPrimary"
                  variant="body2"
                  fontWeight={400}
                  sx={{ mb: 1, minWidth: '145px' }}
                >
                  建物名称・部屋番号
                </Typography>
                <Controller
                  name="customFields.addressLine5"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      type="text"
                      placeholder=""
                      error={!!error}
                      helperText={error?.message}
                      sx={{ flex: 1 }}
                    />
                  )}
                />
              </Stack>
            </Stack>
          </FormControl>

          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{ mt: 8, p: 2 }}
          >
            次へ
          </Button>
        </Stack>
      </Box>
    </Stack>
  );
}
