import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import {
  PreferenceNotification,
  UserEmailUpdate,
  UserSendResetPassword,
  UserUpdate,
  UserPasswordUpdate,
} from '@app/types/user';
import { generatePasswordPattern } from '@app/utils/pattern';

/**
 * ユーザー情報の更新フォーム
 */
export const UserUpdateDefaultValues: UserUpdate = {
  addressLine1: '',
  addressLine2: '',
  addressLine3: '',
  customFields: {
    addressLine4: '',
    birthday: new Date('1980-01-01').toISOString(),
    clinicalDepartments: [],
    confidences: [],
    currentDepartment: '',
    currentHospital: '',
    familyName: '',
    familyNameKana: '',
    firstClinicalDepartments: [],
    firstName: '',
    firstNameKana: '',
    gender: '',
    genderSelf: '',
    jobChange: '',
    license: '',
    medicalLicenseYear: 0,
    medicalRegisterNumber: '',
    notes: '',
    orderAfterFiles: [],
    orderMatchedFiles: [],
    postalCode: '',
    universityName: '',
  },
  phoneNumber: '',
};

const UserUpdateFormSchema = z.object({
  addressLine1: z.string().min(1, { message: '都道府県を入力してください' }),
  addressLine2: z.string().min(1, { message: '市町村区を入力してください' }),
  addressLine3: z.string().min(1, { message: '町名・番地を入力してください' }),
  customFields: z.object({
    addressLine4: z.string(),
    birthday: z.string().min(1, { message: '生年月日を入力してください' }),
    clinicalDepartments: z.array(z.string()).nonempty({
      message: '主な診療科を選択してください',
    }),
    confidences: z.array(z.string()).nonempty({
      message: '自身があるものを選択してください',
    }),
    currentDepartment: z
      .string()
      .min(1, { message: '現在の所属(診療科)を入力してください' }),
    currentHospital: z
      .string()
      .min(1, { message: '現在の所属(病院)を入力してください' }),
    familyName: z.string().min(1, { message: '姓を入力してください' }),
    familyNameKana: z
      .string()
      .min(1, { message: '姓（かな）を入力してください' }),
    firstClinicalDepartments: z.array(z.string()),
    firstName: z.string().min(1, { message: '名を入力してください' }),
    firstNameKana: z
      .string()
      .min(1, { message: '名（かな）を入力してください' }),
    gender: z.string().min(1, { message: '性別を選択してください' }),
    genderSelf: z.string(),
    jobChange: z.string().min(1, { message: '転職意欲を選択してください' }),
    license: z.string(),
    medicalLicenseYear: z
      .number()
      .min(1, { message: '医師免許取得年を選択してください' }),
    medicalRegisterNumber: z
      .string()
      .min(1, { message: '医籍番号を入力してください' }),
    notes: z.string(),
    postalCode: z.string(),
    universityName: z
      .string()
      .min(1, { message: '出身大学を入力してください' }),
  }),
  phoneNumber: z
    .string()
    .min(1, { message: '連絡先電話番号を入力してください' })
    .regex(/^0[0-9]{9,12}$/, {
      message: '連絡先電話番号はハイフンなしの数字を入力してください',
    }),
});

export const UserUpdateForm = {
  defaultValues: UserUpdateDefaultValues,
  resolver: zodResolver(UserUpdateFormSchema),
  schema: UserUpdateFormSchema,
} as const;

/**
 * ユーザー情報のメールアドレス更新フォーム
 */
export const UserEmailDefaultValues: UserEmailUpdate = {
  email: '',
};

const UserEmailUpdateFormSchema = z.object({
  email: z
    .string()
    .email({ message: '新しいメールアドレスを入力してください' }),
});

export const UserEmailUpdateForm = {
  defaultValues: UserEmailDefaultValues,
  resolver: zodResolver(UserEmailUpdateFormSchema),
  schema: UserEmailUpdateFormSchema,
} as const;

/**
 * ユーザー情報のパスワード更新フォーム
 */
export const UserPasswordDefaultValues: UserPasswordUpdate = {
  newPasswords: {
    confirmPassword: '',
    password: '',
  },
  password: '',
};

const UserPasswordUpdateFormSchema = z.object({
  newPasswords: z
    .object({
      confirmPassword: z.string(),
      password: z
        .string()
        .min(8, 'パスワードは記号を除く、英数字含む8文字以上を入力してください')
        .max(24, '24文字以下のパスワードを入力してください')
        .regex(generatePasswordPattern(), {
          message:
            '少なくとも1文字以上の小文字英字、数字が必要です。許可されている文字は、半角英数字です',
        }),
    })
    .refine(({ password, confirmPassword }) => password === confirmPassword, {
      message: 'パスワードが一致しません',
      path: ['confirmPassword'],
    }),
  password: z
    .string()
    .min(8, 'パスワードは記号を除く、英数字含む8文字以上を入力してください')
    .max(24, '24文字以下のパスワードを入力してください')
    .regex(generatePasswordPattern(), {
      message:
        '少なくとも1文字以上の小文字英字、数字が必要です。許可されている文字は、半角英数字です',
    }),
});

export const UserPasswordUpdateForm = {
  defaultValues: UserPasswordDefaultValues,
  resolver: zodResolver(UserPasswordUpdateFormSchema),
  schema: UserPasswordUpdateFormSchema,
} as const;

/**
 * ユーザー情報のパスワード再設定フォーム
 */
export const ResetPasswordDefaultValues: UserPasswordUpdate = {
  newPasswords: {
    confirmPassword: '',
    password: '',
  },
};

const ResetPasswordUpdateFormSchema = z.object({
  newPasswords: z
    .object({
      confirmPassword: z.string(),
      password: z
        .string()
        .min(8, 'パスワードは記号を除く、英数字含む8文字以上を入力してください')
        .max(24, '24文字以下のパスワードを入力してください')
        .regex(generatePasswordPattern(), {
          message:
            '少なくとも1文字以上の小文字英字、数字が必要です。許可されている文字は、半角英数字です',
        }),
    })
    .refine(({ password, confirmPassword }) => password === confirmPassword, {
      message: 'パスワードが一致しません',
      path: ['confirmPassword'],
    }),
});

export const ResetPasswordUpdateForm = {
  defaultValues: ResetPasswordDefaultValues,
  resolver: zodResolver(ResetPasswordUpdateFormSchema),
  schema: ResetPasswordUpdateFormSchema,
} as const;

/**
 * ユーザー情報のパスワード再設定フォーム
 */
export const SendResetPasswordDefaultValues: UserSendResetPassword = {
  email: '',
};

const SendResetPasswordFormSchema = z.object({
  email: z
    .string()
    .email({ message: '新しいメールアドレスを入力してください' }),
});

export const SendResetPasswordForm = {
  defaultValues: SendResetPasswordDefaultValues,
  resolver: zodResolver(SendResetPasswordFormSchema),
  schema: SendResetPasswordFormSchema,
} as const;

/**
 * ユーザー情報のパスワード再設定フォーム
 */
export const PreferenceNotificationDefaultValues: PreferenceNotification = {
  newMessageByEmail: false,
  newProductByFollowedOrg: false,
  sendRemindDayBeforeWork: false,
};

const PreferenceNotificationFormSchema = z.object({
  newMessageByEmail: z.boolean(),
  newProductByFollowedOrg: z.boolean(),
  sendRemindDayBeforeWork: z.boolean(),
});

export const PreferenceNotificationForm = {
  defaultValues: PreferenceNotificationDefaultValues,
  resolver: zodResolver(PreferenceNotificationFormSchema),
  schema: PreferenceNotificationFormSchema,
} as const;

/**
 * password reset email form
 */
export interface PasswordResetEmailFormData {
  email: string;
}
export const PasswordResetEmailDefaultValues: PasswordResetEmailFormData = {
  email: '',
};
export const PasswordResetEmailFormSchema = z.object({
  email: z.string().email({ message: 'メールアドレスを入力してください' }),
});
export const PasswordResetEmailForm = {
  defaultValues: PasswordResetEmailDefaultValues,
  mode: 'all',
  resolver: zodResolver(PasswordResetEmailFormSchema),
} as const;

/**
 * password reset new password form
 */
export interface PasswordResetFormData {
  password: string;
  passwordConfirm: string;
}
export const PasswordResetDefaultValues: PasswordResetFormData = {
  password: '',
  passwordConfirm: '',
};
export const PasswordResetFormSchema = z
  .object({
    password: z
      .string()
      .min(8, 'パスワードは記号を除く、英数字含む8文字以上を入力してください')
      .max(24, '24文字以下のパスワードを入力してください')
      .regex(generatePasswordPattern(), {
        message: '小文字英字と数字を組み合わせてください',
      }),
    passwordConfirm: z
      .string()
      .min(8, 'パスワードは記号を除く、英数字含む8文字以上を入力してください')
      .max(24, '24文字以下のパスワードを入力してください')
      .regex(generatePasswordPattern(), {
        message: '小文字英字と数字を組み合わせてください',
      }),
  })
  .refine(({ password, passwordConfirm }) => password === passwordConfirm, {
    message: 'パスワードが一致しません',
    path: ['passwordConfirm'],
  });
export const PasswordResetForm = {
  defaultValues: PasswordResetDefaultValues,
  mode: 'all',
  resolver: zodResolver(PasswordResetFormSchema),
} as const;
