import { Box, Button, CardMedia, Grid, Typography } from '@mui/material';
import { ReactElement } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';

import { ProgressStepper } from '@app/components/Order/ProgressStepper';
import companyLogo from '@app/mockData/company-logo.svg';
import { theme } from '@app/theme';

export function Completed(): ReactElement {
  const navigate = useNavigate();
  const { productId } = useParams();

  const handleGoToTopPage = () => {
    navigate('/');
  };

  const handleRegister = () => {
    navigate('/register');
  };
  return (
    <Box
      sx={{
        margin: '0 auto',
        maxWidth: '800px',
        mt: 1,
        padding: 3,
      }}
    >
      <Grid
        container
        direction="column"
        alignItems="center"
        textAlign="center"
        sx={{ mt: 10 }}
      >
        <CardMedia
          component="img"
          image={companyLogo}
          alt="Company logo"
          sx={{
            borderRadius: 2,
            height: '36px',
            mb: '12px',
            objectFit: 'cover',
            width: '360px',
          }}
        />
        <Typography variant="h6" fontWeight="bold">
          予約を受け付けました
        </Typography>
        <Grid sx={{ my: 3 }}>
          <Typography color="textSecondary">
            住宅展示場見学を予約いただきありがとうございました。
          </Typography>
          <Typography color="textSecondary">
            ただいま、見学予約確認のメールをお送りさせていただきました。
          </Typography>
          <Typography color="textSecondary">
            もし確認メールが届かない場合はお手数ではございますが、下記メールにてお問い合わせください。
          </Typography>
        </Grid>
      </Grid>

      <ProgressStepper activeStep={4} />
      <Box
        sx={{
          border: `1px solid ${theme.palette.neutral.silver}`,
          borderRadius: 1,
          color: theme.palette.text.primary,
          mb: 10,
          padding: 3,
          width: '100%',
        }}
      >
        <Typography variant="body1" fontWeight={'bold'} sx={{ mb: 3 }}>
          お問い合わせ先
        </Typography>
        <Typography variant="body1">住宅展示場運営事務局</Typography>
        <Typography variant="body1">
          メールアドレス：email@sample.com
        </Typography>
        <Typography variant="body1">
          受付時間：平日9：30～12：00／13：00～17：30
        </Typography>
        <Typography variant="body1">
          ※土日祝日のお問い合わせは、翌平日でのご対応とさせていただきます。
        </Typography>
      </Box>

      <Box
        sx={{
          backgroundColor: theme.palette.neutral.sky,
          borderRadius: 2,
          margin: '0 auto',
          maxWidth: '800px',
          mb: 5,
          padding: 3,
          textAlign: 'center',
        }}
      >
        <Typography variant="h6" fontWeight="bold" sx={{ mb: 2 }}>
          会員登録
        </Typography>
        <Typography variant="body1" sx={{ mb: 5 }}>
          会員登録をすると、メリットを記載する。一般的には履歴の管理ができる、次の申し込みの際にお客様情報の入力を省略できるようになる
        </Typography>
        <Button
          variant="contained"
          color="primary"
          sx={{ p: 2, width: '100%' }}
          onClick={handleRegister}
        >
          会員登録をする
        </Button>
      </Box>

      <Button
        variant="outlined"
        color="secondary"
        onClick={handleGoToTopPage}
        sx={{ my: 7, p: 2, width: '100%' }}
      >
        トップページへ
      </Button>
      <Box sx={{ mb: 14, textAlign: 'center' }}>
        <Link
          to={`/companies/${productId}`}
          style={{
            color: theme.palette.primary.main,
            textDecoration: 'none',
          }}
        >
          物件詳細ページ
        </Link>
      </Box>
    </Box>
  );
}
