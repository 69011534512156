import { ReactElement, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import {
  loggedInUserState,
  userAuthInfoSelector,
  useClearAuthStateAndStorage,
} from '@app/domain/app';

interface ProtectedRouteProps {
  children: ReactElement;
  redirectPath?: string;
}

export function ProtectedRoute({
  redirectPath = '/',
  children,
}: ProtectedRouteProps) {
  const userAuthInfo = useRecoilValue(userAuthInfoSelector);
  const loggedInUser = useRecoilValue(loggedInUserState);
  const clearAuthStateAndStorage = useClearAuthStateAndStorage();

  useEffect(() => {
    if ((!userAuthInfo && loggedInUser) || (userAuthInfo && !loggedInUser)) {
      clearAuthStateAndStorage();
    }
  }, [userAuthInfo, loggedInUser, clearAuthStateAndStorage]);

  if (!userAuthInfo || !loggedInUser) {
    return <Navigate to={redirectPath} replace />;
  }

  return children;
}
