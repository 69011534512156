import { Tab, Tabs } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ReactElement, useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Category, CategoryName } from '@app/types/catalog';

const tabs = [
  { label: '住宅展示場を探す', value: CategoryName.EXHIBITION },
  { label: '分譲を探す', value: CategoryName.PROPERTY },
] as const;

interface SearchTypeTabProps {
  items: Category[];
  onChangeTab: (selectedTab: string) => void;
  value?: string | null;
}

export function SearchTypeTab({
  items,
  onChangeTab,
  value,
}: SearchTypeTabProps): ReactElement {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();

  const selected = useMemo(() => {
    if (location.pathname.includes('/exhibitions')) {
      return CategoryName.EXHIBITION;
    } else if (location.pathname.includes('/properties')) {
      return CategoryName.PROPERTY;
    }
    return tabs[0].value;
  }, [location.pathname]);

  const handleChange = useCallback(
    (event: React.SyntheticEvent, selectedTab: string) => {
      onChangeTab(selectedTab);
      let categoryId: string | undefined;
      if (selectedTab === CategoryName.EXHIBITION) {
        categoryId = items.find((item) => item.name === 'exhibition')?.id;
      } else if (selectedTab === CategoryName.PROPERTY) {
        categoryId = items.find((item) => item.name === 'property')?.id;
      }

      if (categoryId) {
        const searchParams = new URLSearchParams(location.search);
        searchParams.set('category', categoryId);
        if (selectedTab === CategoryName.EXHIBITION) {
          navigate(`/exhibitions?${searchParams.toString()}`);
        } else if (selectedTab === CategoryName.PROPERTY) {
          navigate(`/properties?${searchParams.toString()}`);
        }
      }
    },
    [onChangeTab, navigate, location.search, items]
  );

  return (
    <Tabs
      value={selected}
      onChange={handleChange}
      variant="fullWidth"
      TabIndicatorProps={{
        style: {
          backgroundColor: theme.palette.neutral.blueLight,
        },
      }}
      sx={{
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
        m: 5,
      }}
    >
      {tabs.map(({ label, value }, index) => (
        <Tab
          key={index}
          label={label}
          value={value}
          sx={{
            '&.Mui-selected': {
              backgroundColor: theme.palette.neutral.blueLight,
              color: 'white',
            },
          }}
        />
      ))}
    </Tabs>
  );
}
