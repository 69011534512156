import { Box } from '@mui/material';
import { ReactElement, useEffect, Suspense } from 'react';
import { useNavigate, useLocation, Outlet } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { Loading } from '@app/components/Shared/Loading';
import { TopNav } from '@app/components/TopNav';
import {
  TOKEN_VERIFY_STATUS,
  useVerifyStoredAccessToken,
  loggedInUserState,
  userAuthInfoSelector,
} from '@app/domain/app';
import { AppContext } from '@app/hooks/appContext';

const PROFILE_EDIT_PAGE_PATH = '/profile/edit';
const PROFILE_CONFIRM_PAGE_PATH = '/profile/edit-confirm';

const TOTAL_NAV_FOOTER_HEIGHT = '150px';

export function DefaultLayout({ showAccount = true }): ReactElement {
  const authInfo = useRecoilValue(userAuthInfoSelector);

  // ログイン状態検証
  const verifyState = useVerifyStoredAccessToken();
  const loggedInUser = useRecoilValue(loggedInUserState);
  const navigate = useNavigate();
  const location = useLocation();

  // 認証済なのに名前等が未登録の場合はプロフィール登録へ強制遷移
  // (無名のままchatとかできてしまうと混乱しそうなため)
  useEffect(() => {
    if (
      [PROFILE_EDIT_PAGE_PATH, PROFILE_CONFIRM_PAGE_PATH].includes(
        location.pathname
      ) ||
      verifyState !== TOKEN_VERIFY_STATUS.VALID ||
      !loggedInUser
    ) {
      return; // SKIP
    }
    if (
      loggedInUser.id &&
      (!loggedInUser.customFields.familyName ||
        !loggedInUser.customFields.firstName)
    ) {
      navigate(PROFILE_EDIT_PAGE_PATH);
    }
  }, [verifyState, loggedInUser, location, navigate]);

  return verifyState === TOKEN_VERIFY_STATUS.PROGRESS ? (
    <Loading />
  ) : (
    <AppContext.Provider value={{ authInfo }}>
      <TopNav showAccount={showAccount} />
      <Suspense fallback={<Loading />}>
        <Box
          component="main"
          minHeight={`calc(100vh - ${TOTAL_NAV_FOOTER_HEIGHT})`}
        >
          <Outlet />
        </Box>
      </Suspense>
    </AppContext.Provider>
  );
}
