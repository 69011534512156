import {
  ReactElement,
  Suspense,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { PropertyDetailInfo } from '@app/components/Product/PropertyDetailInfo';
import { BottomMenu } from '@app/components/Shared/BottomMenu';
import { Breadcrumb } from '@app/components/Shared/Breadcrumb';
import { HeadBlock } from '@app/components/Shared/HeadBlock';
import { Loading } from '@app/components/Shared/Loading';
import { searchConditionState } from '@app/domain/catalog';
import { getProduct } from '@app/domain/network-actions';
import { useSetSnackbar } from '@app/hooks/useSetSnackbar';
import { BOTTOM_MENU_ITEMS } from '@app/static/constants';
import { Product } from '@app/types/catalog';
import { getSearchResultUrl } from '@app/utils/catalog';
import { isError } from '@app/utils/error';

export function Show(): ReactElement {
  const { productId } = useParams();
  const setSnackbar = useSetSnackbar();
  const conditionState = useRecoilValue(searchConditionState);
  const [product, setProduct] = useState<Product | null>(null);

  const fetchProduct = useCallback(async () => {
    if (!productId) return;
    try {
      const result = await getProduct(productId);
      setProduct(result.data);
    } catch (e) {
      if (isError(e)) {
        console.error('fetchProduct', e.message);
      }
      setSnackbar(true, '分譲地が見つかりません');
    }
  }, [productId, setSnackbar]);

  useEffect(() => {
    void fetchProduct();
    // eslint-disable-next-line
  }, []);

  const backLink = getSearchResultUrl(conditionState);

  const breadcrumbItems = [
    { label: '分譲地検索結果', link: backLink },
    { label: '分譲詳細' },
  ];

  return (
    <>
      <HeadBlock />
      <Breadcrumb items={breadcrumbItems} />
      {product && (
        <Suspense fallback={<Loading />}>
          <PropertyDetailInfo product={product} />
        </Suspense>
      )}
      <BottomMenu menuItems={BOTTOM_MENU_ITEMS} />
    </>
  );
}
