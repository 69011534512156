import { Grid, Portal, Toolbar } from '@mui/material';
import { ReactElement, ReactNode } from 'react';
import { useRecoilValue } from 'recoil';

import { TopNavBackButton } from './TopNavBackButton';
import { TopNavTitle } from './TopNavTitle';

import { navPortalContainerRef, navPortalSpacerRef } from '@app/domain/top-nav';

interface TopNavPortalProps {
  endAdornment?: ReactNode;
  onGoBack?: () => void;
  title?: string;
}

export function TopNavPortal({
  endAdornment,
  title,
  onGoBack,
}: TopNavPortalProps): ReactElement {
  const portalContainer = useRecoilValue(navPortalContainerRef);
  const portalSpacer = useRecoilValue(navPortalSpacerRef);

  return (
    <>
      {portalContainer && (title || onGoBack) && (
        <Portal container={portalContainer.current}>
          <Toolbar sx={{ minHeight: '44px' }} data-e2e="titleBar">
            <Grid container>
              <Grid item xs={3} display="flex" alignItems="center">
                {onGoBack && <TopNavBackButton onGoBack={onGoBack} />}
              </Grid>
              <Grid item xs={6} display="flex" alignItems="center">
                {title && <TopNavTitle title={title} />}
              </Grid>
              <Grid item xs={3} display="flex" alignItems="center">
                {endAdornment}
              </Grid>
            </Grid>
          </Toolbar>
        </Portal>
      )}
      {portalSpacer && (title || onGoBack) && (
        <Portal container={portalSpacer.current}>
          <Toolbar sx={{ minHeight: '44px' }} />
        </Portal>
      )}
    </>
  );
}
