import { imageAssets } from '@app/theme';
import { CategoryType } from '@app/types/catalog';
import { InquiryStepTwoQuestion, FormType } from '@app/types/order';

export const PREFECTURE_OPTIONS = [
  '北海道',
  '青森県',
  '岩手県',
  '宮城県',
  '秋田県',
  '山形県',
  '福島県',
  '茨城県',
  '栃木県',
  '群馬県',
  '埼玉県',
  '千葉県',
  '東京都',
  '神奈川県',
  '新潟県',
  '富山県',
  '石川県',
  '福井県',
  '山梨県',
  '長野県',
  '岐阜県',
  '静岡県',
  '愛知県',
  '三重県',
  '滋賀県',
  '京都府',
  '大阪府',
  '兵庫県',
  '奈良県',
  '和歌山県',
  '鳥取県',
  '島根県',
  '岡山県',
  '広島県',
  '山口県',
  '徳島県',
  '香川県',
  '愛媛県',
  '高知県',
  '福岡県',
  '佐賀県',
  '長崎県',
  '熊本県',
  '大分県',
  '宮崎県',
  '鹿児島県',
  '沖縄県',
] as const;

export const BOTTOM_MENU_ITEMS = [
  {
    key: 3,
    target: '_blank',
    text: 'Home',
    to: '#',
  },
  {
    key: 4,
    target: '_blank',
    text: 'Company',
    to: '#',
  },
  {
    key: 5,
    target: '_blank',
    text: 'Blog',
    to: '#',
  },
  {
    key: 6,
    target: '_blank',
    text: 'Instagram',
    to: '#',
  },
  {
    key: 7,
    target: '_blank',
    text: 'Twitter',
    to: '#',
  },
];

export const SIDE_BAR_JOB_MENU_ITEMS = [
  { isDisabled: true, key: 1, path: '/', text: '仕事一覧' },
  { isDisabled: true, key: 2, path: '/chats', text: '通知一覧' },
];

export const SIDE_BAR_SYSTEM_MENU_ITEMS_NO_LOGIN = [
  {
    isDisabled: false,
    key: 1,
    path: 'https://med-pro.jp/media.dr/clp/hp/',
    target: '_blank',
    text: '掲載希望の方へ',
  },
];

export const SIDE_BAR_SYSTEM_MENU_ITEMS = [
  {
    isDisabled: false,
    key: 1,
    path: 'https://med-pro.jp/lp.dr/',
    target: '_blank',
    text: 'Pro Doctorsについて',
  },
  {
    isDisabled: false,
    key: 2,
    path: 'https://docs.google.com/forms/d/e/1FAIpQLSfStJDKdx1mouZ5ncVftqaS4gfBbUvOtahJpbBoCfEZAYwB5Q/viewform?usp=send_form',
    target: '_blank',
    text: 'お問い合わせ',
  },
];

/*
  localStorage, sessionStorage を使う場合はここに key を追加
  重複しないように一元管理
*/
export const STORAGE_KEYS = {
  ACCESS_TOKEN: 'accessToken',
  AFTER_LOGIN_ROUTE: 'afterLoginRoute',
  FINGERPRINT: 'fingerprint', // 予約
  REFRESH_TOKEN: 'refreshToken', // 予約
  USER_ID: 'userId',
};

export const ERROR_MESSAGE = {
  EMAIL_NOT_FOUND:
    '入力されたメールアドレスの会員情報が見つかりません。間違ったメールアドレスを入力されていないかご確認ください。',
  INVALID_USER_ID: 'ログアウトします',
  INVALID_USER_TYPE: 'ProDoctorsの医療機関側のURLで再度お試しください。',
  TOKEN_EXPIRED: '認証の有効期限が切れました。再ログインしてください。',
};

export const occupationOptions = [
  '管理的職業',
  '研究・技術の職業',
  '法務・経営・文化芸術等の専門的職業',
  '医療・看護・保健の職業',
  '保育・教育の職業',
  '事務的職業',
  '販売・営業の職業',
  '福祉・介護の職業',
  'サービスの職業',
  '警備・保安の職業',
  '農林漁業の職業',
  '製造・修理・塗装・製図等の職業',
  '配送・輸送・機械運転の職業',
  '建設・土木・電気工事の職業',
  '運搬・清掃・包装・選別等の職業',
];

export const SEARCH_OPTIONS = [
  { label: '住宅展示場を探す', value: CategoryType.EXHIBITION },
  { label: '分譲を探す', value: CategoryType.PROPERTY },
];

export const productListDummyData = [
  {
    address: '住所 1',
    imageSrc: imageAssets.productDefault,
    minPrice: 4060,
    title: '住宅展示場タイトル 1',
  },
  {
    address: '住所 2',
    imageSrc: imageAssets.productDefault,
    minPrice: 4060,
    title: '住宅展示場タイトル 2',
  },
  {
    address: '住所 3',
    imageSrc: imageAssets.productDefault,
    minPrice: 4060,
    title: '住宅展示場タイトル 3',
  },
  {
    address: '住所 4',
    imageSrc: imageAssets.productDefault,
    minPrice: 4060,
    title: '住宅展示場タイトル 4',
  },
  {
    address: '住所 5',
    imageSrc: imageAssets.productDefault,
    minPrice: 4060,
    title: '住宅展示場タイトル 5',
  },
  {
    address: '住所 6',
    imageSrc: imageAssets.productDefault,
    minPrice: 4060,
    title: '住宅展示場タイトル 6',
  },
  {
    address: '住所 7',
    imageSrc: imageAssets.productDefault,
    minPrice: 4060,
    title: '住宅展示場タイトル 7',
  },
  {
    address: '住所 8',
    imageSrc: imageAssets.productDefault,
    minPrice: 4060,
    title: '住宅展示場タイトル 8',
  },
  {
    address: '住所 9',
    imageSrc: imageAssets.productDefault,
    minPrice: 4060,
    title: '住宅展示場タイトル 9',
  },
];

export const showrooms = [
  {
    image:
      'https://www.jutakutenjijo.com/wp-content/uploads/2022/02/%E3%83%8F%E3%83%93%E3%82%BF%E3%80%80%E3%81%95%E3%81%84%E3%81%9F%E3%81%BE%E6%96%B0%E9%83%BD%E5%BF%83%E5%B1%95%E7%A4%BA%E5%A0%B4%E3%80%80%E5%86%99%E7%9C%9F-2.jpg',
    subtitle: 'サブテキスト',
    title: '住宅展示場A',
  },
  {
    image:
      'https://d2goguvysdoarq.cloudfront.net/system/facility_photos/photos/87554/normal.jpg?1481861516',
    subtitle: 'サブテキスト',
    title: '住宅展示場B',
  },
  {
    image:
      'https://lifull-homes-press.s3.ap-northeast-1.amazonaws.com/uploads/press/2019/01/pixta_43719521_S.jpg',
    subtitle: 'サブテキスト',
    title: '住宅展示場C',
  },
  {
    image:
      'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSb5mUB8B_sgqRaFIdBROTTDGBq8zO4jlZAeQ&s',
    subtitle: 'サブテキスト',
    title: '住宅展示場D',
  },
  {
    image:
      'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSgvkiJ42O8JrOKD_g4swl6p7uk8GzFl_yG1w&s',
    subtitle: 'サブテキスト',
    title: '住宅展示場E',
  },
  {
    image: 'https://www.nikkenhomes.co.jp/20170328170723.jpg',
    subtitle: 'サブテキスト',
    title: '住宅展示場F',
  },
  {
    image:
      'https://www.hitweb.co.jp/wp/wp-content/themes/hitweb/img/ph_exhibition-list_02.jpg',
    subtitle: 'サブテキスト',
    title: '住宅展示場G',
  },
];

export const InquiryStepSecondQuestions: InquiryStepTwoQuestion[] = [
  {
    formType: FormType.CHECKBOX,
    otherFlg: true,
    required: false,
    selections: ['配偶者', 'お子様'],
    subText: 'その他',
    title: '家族・世帯構成',
  },
  {
    formType: FormType.RADIO,
    otherFlg: false,
    required: true,
    selections: ['男性', '女性', 'その他'],
    subText: '',
    title: '性別',
  },
  {
    formType: FormType.PULLDOWN,
    otherFlg: false,
    required: false,
    selections: [
      '0 ~ 300万円',
      '301万円 ~ 500万円',
      '501万円 ~ 700万円',
      '701万円 ~ 900万円',
      '901万円 ~ 1100万円',
      '1100万円 ~ ',
    ],
    subText: '',
    title: '世帯年収',
  },
  {
    formType: FormType.TEXT,
    otherFlg: false,
    required: false,
    selections: [],
    subText: '',
    title: '建築予定地',
  },
];

export const CompanyInquiryStepSecondQuestions: InquiryStepTwoQuestion[] = [
  {
    formType: FormType.CHECKBOX,
    otherFlg: true,
    otherFlg2: true,
    required: false,
    selections: ['配偶者', 'お子様'],
    subText: 'その他',
    subText2: '2',
    subUnit2: '人',
    title: '家族・世帯構成',
  },
  {
    formType: FormType.RADIO,
    otherFlg: false,
    required: false,
    selections: ['男性', '女性', 'その他'],
    subText: '',
    title: '性別',
  },
  {
    formType: FormType.TEXT,
    otherFlg: false,
    required: false,
    selections: [],
    subText: '',
    title: '建築予定地',
  },
];

export const ROOM_TYPE_OPTIONS = [
  { label: 'ワンルーム', value: 0 },
  { label: '1K/DK/LDK', value: 1 },
  { label: '2K/DK/LDK', value: 2 },
  { label: '3K/DK/LDK', value: 3 },
  { label: '4K/DK/LDK', value: 4 },
  { label: '5K/DK/LDK以上', value: 5 },
];
