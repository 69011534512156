import { Typography, FormLabel } from '@mui/material';
import React, { ReactElement } from 'react';

export function FormLabelComponent(
  label: string,
  require?: boolean
): ReactElement {
  return (
    <FormLabel>
      <Typography
        color="textPrimary"
        variant="subtitle2"
        fontWeight={600}
        className={require ? 'required-label' : ''}
        sx={{ mb: 1 }}
      >
        {label}
      </Typography>
    </FormLabel>
  );
}
