import { LoadingButton } from '@mui/lab';
import {
  Container,
  FormLabel,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { ReactElement, useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';

import { update } from '@app/adapter/user-service';
import { HeadBlock } from '@app/components/Shared/HeadBlock';
import { loggedInUserState, userAuthInfoSelector } from '@app/domain/app';
import { useSetSnackbar } from '@app/hooks/useSetSnackbar';
import { UserEmailUpdateForm } from '@app/schemas/user';
import { UserEmailUpdate } from '@app/types/user';
import { isError } from '@app/utils/error';

export function ChangeEmail(): ReactElement {
  const navigate = useNavigate();
  const setSnackbar = useSetSnackbar();
  const userInfo = useRecoilValue(userAuthInfoSelector);
  const [loggedInUser, setLoggedInUser] = useRecoilState(loggedInUserState);
  const [isLoading, setIsLoading] = useState(false);
  const {
    control,
    formState: { isValid },
    handleSubmit,
  } = useForm<UserEmailUpdate>({
    defaultValues: UserEmailUpdateForm.defaultValues,
    mode: 'onChange',
    resolver: UserEmailUpdateForm.resolver,
  });

  const onSubmit = useCallback(
    async (data: UserEmailUpdate) => {
      if (!userInfo || !loggedInUser) return;

      setIsLoading(true);
      try {
        await update(
          userInfo.userId,
          userInfo.accessToken,
          userInfo.fingerPrint,
          data
        );
        setLoggedInUser({ ...loggedInUser, email: data.email });
        setSnackbar(true, '登録しました', 'success');
        navigate('/setting');
      } catch (e) {
        if (isError(e)) {
          console.error(e.message);
        }
        setSnackbar(true, '登録に失敗しました');
      }
      setIsLoading(false);
    },
    [navigate, loggedInUser, setSnackbar, setLoggedInUser, userInfo]
  );

  return (
    <>
      <HeadBlock />
      <Container fixed>
        <Typography
          variant="h5"
          fontWeight={700}
          textAlign="center"
          sx={{ my: 4 }}
        >
          メールアドレス変更
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormLabel>
                <Typography variant="body2">
                  新しいメールアドレス
                  <Typography component="span" color="error">
                    *
                  </Typography>
                </Typography>
              </FormLabel>
              <Controller
                name="email"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    type="email"
                    error={!!error}
                    helperText={error?.message}
                    margin="dense"
                    placeholder="新しいメールアドレスを入力"
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <LoadingButton
                type="submit"
                color="primary"
                variant="contained"
                fullWidth
                loading={isLoading}
                disabled={!isValid}
              >
                保存
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      </Container>
    </>
  );
}
