import { Box } from '@mui/material';
import { ReactElement, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { Loading } from '@app/components/Shared/Loading';

export function SimpleLayout(): ReactElement {
  return (
    <>
      <Suspense fallback={<Loading />}>
        <Box component="main">
          <Outlet />
        </Box>
      </Suspense>
    </>
  );
}
