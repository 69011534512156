import { Typography, Box, Container } from '@mui/material';
import React from 'react';

import { TopNavPortal } from '@app/components/TopNav/TopNavPortal';

export function ErrorFound() {
  return (
    <>
      <TopNavPortal title={`Error`} />
      <Container
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between',
          py: 1,
        }}
      >
        <Box sx={{ m: '30vh auto', opacity: 0.3 }}>
          <Typography
            sx={{
              marginTop: '5em',
              textAlign: 'center',
            }}
            variant="h2"
          >
            情報の取得中に問題が発生しました
          </Typography>
          <Typography
            sx={{
              textAlign: 'center',
            }}
            variant="h2"
          ></Typography>
        </Box>
      </Container>
    </>
  );
}
