import {
  Box,
  Typography,
  MenuItem,
  FormControl,
  Select,
  Button,
  Stack,
  TextField,
  FormHelperText,
  CircularProgress,
} from '@mui/material';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';

import {
  getCategories,
  getOrganizationProducts,
} from '@app/adapter/catalog-service';
import { FormLabelComponent } from '@app/components/Order/FormLabelComponents';
import { locationsTreeSelector } from '@app/domain/catalog';
import { InquiryStepOneState } from '@app/domain/order';
import {
  InquiryStepOneForm,
  defaultInquiryStepOneFormData,
} from '@app/schemas/order';
import { theme } from '@app/theme';
import { CategoryType, Product } from '@app/types/catalog';
import { InquiryStepOneFormData } from '@app/types/order';

interface InquiryStepOneProps {
  defaultValues?: InquiryStepOneFormData;
  isExhibition?: boolean;
  onSubmit: (data: InquiryStepOneFormData) => void;
  organizationId?: string;
}

export function InquiryStepOne({
  onSubmit,
  defaultValues = defaultInquiryStepOneFormData,
  isExhibition,
  organizationId,
}: InquiryStepOneProps): ReactElement {
  const { productId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [stepData, setStepData] = useRecoilState(InquiryStepOneState);
  const locations = useRecoilValue(locationsTreeSelector);
  const [customerPrefecture, setCustomerPrefecture] = useState<{
    id: string;
    name: string;
  }>({
    id:
      typeof defaultValues?.customer.addressLine1 === 'string'
        ? defaultValues.customer.addressLine1
        : '',
    name: defaultValues.customer.prefectureName || '',
  });
  const [prefecture, setPrefecture] = useState<{ id: string; name: string }>({
    id:
      typeof defaultValues?.customFields.prefecture === 'string'
        ? defaultValues.customFields.prefecture
        : '',
    name: '',
  });
  const [exhibition, setExhibition] = useState<{ id: string; name: string }>({
    id:
      typeof defaultValues?.items.product.id === 'string'
        ? defaultValues.items.product.id
        : '',
    name: defaultValues.items.product.name || '',
  });
  const [categoryId, setCategoryId] = useState<string | null>(null);
  const [resultProducts, setResultProducts] = useState<Product[]>([]);

  const filteredProducts = resultProducts.filter((product) =>
    product.locationIds.includes(prefecture.id)
  );

  const initialData: InquiryStepOneFormData = {
    ...defaultValues,
    ...stepData,
  };

  const { control, handleSubmit, getValues } = useForm<InquiryStepOneFormData>({
    defaultValues: initialData,
    mode: 'onChange',
    resolver: InquiryStepOneForm.resolver,
  });

  const isDisabled = isExhibition ? !prefecture || !exhibition : false;

  const fetchCategoryId = useCallback(async () => {
    try {
      const response = await getCategories();
      const propertyCategory = response.data.value.find(
        (category) => category.name === CategoryType.EXHIBITION
      );
      setCategoryId(propertyCategory ? propertyCategory.id : null);
    } catch (error) {
      console.error('Failed to fetch categories', error);
    }
  }, []);

  const fetchProducts = useCallback(async () => {
    if (!categoryId) return;
    if (!organizationId) return;
    try {
      const response = await getOrganizationProducts(
        organizationId,
        categoryId
      );
      setResultProducts(response.data.value);
    } catch (error) {
      console.error('Failed to fetch organization products', error);
    }
  }, [organizationId, categoryId]);

  useEffect(() => {
    void fetchCategoryId();
  }, [fetchCategoryId]);

  useEffect(() => {
    void fetchProducts();
  }, [fetchProducts]);

  useEffect(() => {
    if (productId && resultProducts.length > 0) {
      const matchingProduct = resultProducts.find(
        (product) => product.id === productId
      );
      if (matchingProduct) {
        setExhibition({ id: matchingProduct.id, name: matchingProduct.name });
        setPrefecture({
          id: matchingProduct.locations[0].id,
          name: matchingProduct.locations[0].name,
        });
      }
      setIsLoading(false);
    }
  }, [productId, resultProducts]);

  const handleStep1Submit = (data: InquiryStepOneFormData) => {
    const updatedCustomer = {
      ...data.customer,
      addressLine1: customerPrefecture.id,
      prefectureName: customerPrefecture.name,
    };

    setStepData((prevData) => ({
      ...prevData,
      customFields: {
        ...prevData.customFields,
        ...data.customFields,
        prefecture,
      },
      customer: updatedCustomer,
      items: {
        product: {
          ...exhibition,
        },
        quantity: 1,
        variantId: exhibition?.id,
      },
    }));
    onSubmit({
      ...data,
      customFields: {
        ...data.customFields,
        prefecture,
      },
      customer: updatedCustomer,
    });
  };

  if (isLoading) {
    return (
      <Stack
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress />
      </Stack>
    );
  }

  return (
    <Stack
      sx={{
        alignItems: 'center',
        pb: 4,
        width: '100%',
      }}
      spacing={4}
    >
      {isExhibition && (
        <Box
          component="form"
          onSubmit={(e) => {
            e.preventDefault();
            handleStep1Submit(getValues());
          }}
          sx={{
            backgroundColor: theme.palette.neutral.blueLight,
            left: '50%',
            padding: '40px',
            right: '50%',
            width: '100vw',
          }}
        >
          <Box sx={{ margin: '0 auto', maxWidth: '800px', px: 6 }}>
            <Typography
              variant="h6"
              sx={{ color: theme.palette.neutral.white, mb: 4 }}
            >
              1. 見学予定地をお選びください
            </Typography>
            <FormControl fullWidth sx={{ mb: 4 }}>
              {FormLabelComponent('見学する住宅展示場の都道府県', true)}
              <Select
                labelId="prefecture-label"
                value={prefecture.id}
                onChange={(e) => {
                  const selectedId = e.target.value as string;
                  const selectedPrefecture = locations
                    .flatMap((region) =>
                      region.children ? region.children : []
                    )
                    .find((option) => option.id === selectedId);

                  if (selectedPrefecture) {
                    setPrefecture({
                      id: selectedPrefecture.id,
                      name: selectedPrefecture.name,
                    });
                  }
                }}
                displayEmpty
                sx={{
                  '.MuiSvgIcon-root': {
                    color: theme.typography.body1,
                  },
                  backgroundColor: theme.palette.neutral.white,
                  color: theme.typography.body1,
                }}
              >
                <MenuItem value="" disabled>
                  <em>選択してください</em>
                </MenuItem>
                {locations.flatMap((region) =>
                  region.children
                    ? region.children.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ))
                    : []
                )}
              </Select>
            </FormControl>
            <FormControl fullWidth sx={{ mb: 4 }}>
              {FormLabelComponent('見学する住宅展示場', true)}
              <Select
                labelId="exhibition-label"
                value={exhibition?.id}
                onChange={(e) => {
                  const selectedProduct = resultProducts.find(
                    (product) => product.id === e.target.value
                  );
                  if (selectedProduct) {
                    setExhibition({
                      id: selectedProduct.id,
                      name: selectedProduct.name,
                    });
                  }
                }}
                displayEmpty
                sx={{
                  '.MuiSvgIcon-root': {
                    color: theme.typography.body1,
                  },
                  backgroundColor: theme.palette.neutral.white,
                  color: theme.typography.body1,
                }}
              >
                <MenuItem value="" disabled>
                  <em>選択してください</em>
                </MenuItem>
                {filteredProducts.map((product) => (
                  <MenuItem key={product.id} value={product.id}>
                    {product.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Typography
              variant="body2"
              sx={{ color: theme.palette.neutral.white, mb: 4 }}
            >
              ※ 見学予定地を選択すると下記フォームが入力可能になります。
            </Typography>
          </Box>
        </Box>
      )}

      <Box
        sx={{
          maxWidth: '800px',
          position: 'relative',
          width: '100%',
        }}
      >
        {isDisabled && (
          <Box
            sx={{
              backgroundColor: 'rgba(255, 255, 255, 0.8)',
              borderRadius: '8px',
              height: '100%',
              left: 0,
              position: 'absolute',
              top: 0,
              width: '100%',
              zIndex: 1,
            }}
          />
        )}
        <Stack
          component="form"
          onSubmit={handleSubmit(handleStep1Submit)}
          sx={{
            backgroundColor: theme.palette.neutral.white,
            maxWidth: '800px',
            mb: 20,
            padding: '40px',
            width: '100%',
          }}
        >
          <Typography variant="h6" sx={{ mb: 4 }}>
            お客様の情報
          </Typography>
          <FormControl fullWidth variant="outlined" sx={{ mb: 4 }}>
            {FormLabelComponent('名前', true)}
            <Controller
              name="customer.name"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  error={!!error}
                  helperText={error?.message}
                  placeholder="山田太郎"
                />
              )}
            />
          </FormControl>

          <FormControl fullWidth variant="outlined" sx={{ mb: 4 }}>
            {FormLabelComponent('フリガナ', true)}
            <Controller
              name="customer.nameKana"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  error={!!error}
                  helperText={error?.message}
                  placeholder="ヤマダタロウ"
                />
              )}
            />
          </FormControl>

          <FormControl fullWidth variant="outlined" sx={{ mb: 4 }}>
            {FormLabelComponent('年齢', true)}
            <Stack>
              <Controller
                name="customer.age"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <>
                    <Stack direction="row" alignItems="center">
                      <TextField
                        {...field}
                        type="number"
                        error={!!error}
                        placeholder="30"
                        sx={{ width: '100px' }}
                      />
                      <Typography sx={{ ml: 1 }}>歳</Typography>
                    </Stack>
                    {error && (
                      <FormHelperText error>{error.message}</FormHelperText>
                    )}
                  </>
                )}
              />
            </Stack>
          </FormControl>

          <FormControl fullWidth variant="outlined" sx={{ mb: 4 }}>
            {FormLabelComponent('電話番号', true)}
            <Controller
              name="customer.phone"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  type="text"
                  error={!!error}
                  helperText={error?.message}
                  placeholder="09012345678"
                />
              )}
            />
          </FormControl>

          <FormControl fullWidth variant="outlined" sx={{ mb: 4 }}>
            {FormLabelComponent('メールアドレス', true)}
            <Controller
              name="customer.email"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  type="email"
                  error={!!error}
                  helperText={error?.message}
                  placeholder="email@example.com"
                />
              )}
            />
            <Typography
              color={theme.palette.neutral.greyDark}
              sx={{ ml: 1, mt: 1 }}
            >
              ※記入したメールアドレスに申し込み完了メールが届きます。
            </Typography>
          </FormControl>

          <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
            {FormLabelComponent('住所', true)}
            <Stack spacing={2}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography
                  color="textPrimary"
                  variant="subtitle2"
                  fontWeight={700}
                  sx={{ mb: 1, minWidth: '145px' }}
                >
                  郵便番号
                </Typography>
                <Typography>〒</Typography>
                <Controller
                  name="customFields.postalCode"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <>
                      <Stack>
                        <TextField
                          {...field}
                          type="text"
                          placeholder="123-0000"
                          error={!!error}
                          sx={{ width: '170px' }}
                        />
                        {error && (
                          <FormHelperText error>{error.message}</FormHelperText>
                        )}
                      </Stack>
                    </>
                  )}
                />
              </Stack>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography
                  color="textPrimary"
                  variant="subtitle2"
                  fontWeight={700}
                  sx={{ mb: 1, minWidth: '145px' }}
                >
                  都道府県
                </Typography>
                <Controller
                  name="customer.addressLine1"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <FormControl error={!!error} sx={{ width: '200px' }}>
                      <Select
                        {...field}
                        value={customerPrefecture.id}
                        displayEmpty
                        onChange={(e) => {
                          const selectedId = e.target.value as string;

                          const selectedRegion = locations
                            .flatMap((region) =>
                              region.children ? region.children : []
                            )
                            .find((option) => option.id === selectedId);

                          if (selectedRegion) {
                            setCustomerPrefecture({
                              id: selectedRegion.id,
                              name: selectedRegion.name,
                            });
                            field.onChange(selectedId);
                          }
                        }}
                        sx={{
                          '.MuiSvgIcon-root': {
                            color: theme.typography.body1,
                          },
                          backgroundColor: theme.palette.neutral.white,
                          color: theme.typography.body1,
                        }}
                      >
                        <MenuItem value="" disabled>
                          <em>選択する</em>
                        </MenuItem>
                        {locations.flatMap((region) =>
                          region.children
                            ? region.children.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                  {option.name}
                                </MenuItem>
                              ))
                            : []
                        )}
                      </Select>
                      {error && (
                        <FormHelperText>{error.message}</FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
              </Stack>

              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography
                  color="textPrimary"
                  variant="subtitle2"
                  fontWeight={700}
                  sx={{ mb: 1, minWidth: '145px' }}
                >
                  市区町村
                </Typography>
                <Controller
                  name="customer.addressLine2"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      type="text"
                      placeholder="葛飾区"
                      error={!!error}
                      helperText={error?.message}
                      sx={{ flex: 1 }}
                    />
                  )}
                />
              </Stack>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography
                  color="textPrimary"
                  variant="subtitle2"
                  fontWeight={700}
                  sx={{ mb: 1, minWidth: '145px' }}
                >
                  町名
                </Typography>
                <Controller
                  name="customer.addressLine3"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      type="text"
                      placeholder="金町"
                      error={!!error}
                      helperText={error?.message}
                      sx={{ flex: 1 }}
                    />
                  )}
                />
              </Stack>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography
                  color="textPrimary"
                  variant="subtitle2"
                  fontWeight={700}
                  sx={{ mb: 1, minWidth: '145px' }}
                >
                  番地
                </Typography>
                <Controller
                  name="customFields.addressLine4"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      type="text"
                      placeholder="1-2-3"
                      error={!!error}
                      helperText={error?.message}
                      sx={{ flex: 1 }}
                    />
                  )}
                />
              </Stack>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography
                  color="textPrimary"
                  variant="subtitle2"
                  fontWeight={700}
                  sx={{ mb: 1, minWidth: '145px' }}
                >
                  建物名称・部屋番号
                </Typography>
                <Controller
                  name="customFields.addressLine5"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      type="text"
                      placeholder=""
                      error={!!error}
                      helperText={error?.message}
                      sx={{ flex: 1 }}
                    />
                  )}
                />
              </Stack>
            </Stack>
          </FormControl>

          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{ mt: 8 }}
          >
            次へ
          </Button>
        </Stack>
      </Box>
    </Stack>
  );
}
