import { Box } from '@mui/material';
import { ReactElement, useCallback, useState } from 'react';
import { useRecoilState } from 'recoil';

import { RegistrationInput } from '@app/components/User/RegistrationInput';
import { registerState } from '@app/domain/register';
import { UserFormData } from '@app/schemas/registration';
import { Completed } from '@app/views/User/Completed';
import { InReview, RegistrationFormData } from '@app/views/User/InReview';

type RegistrationStep = 'userInformationForm' | 'inReview' | 'complete';

export function RegistrationConfirmation(): ReactElement {
  const [registerData, setRegisterData] = useRecoilState(registerState);

  const [step, setStep] = useState<RegistrationStep>('userInformationForm');

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const handleSubmitUserForm = useCallback(
    (data: UserFormData) => {
      setRegisterData(data);
      setStep('inReview');
      scrollToTop();
    },
    [setRegisterData]
  );

  const handleSubmitRegistrationForm = useCallback(
    async (formData: RegistrationFormData) => {
      console.log('Form data submitted:', formData);
      // TODO: API結合
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setStep('complete');
      scrollToTop();
    },
    []
  );

  const handleBackInReview = () => {
    setStep('userInformationForm');
  };

  // const handleSubmitRegistrationForm = useCallback(
  //   async (formData: CombinedFormData) => {
  //     const {
  //       organizationData: {
  //         name,
  //         postalCode,
  //         addressLine1,
  //         addressLine2,
  //         addressLine3,
  //         phoneNumber,
  //         description,
  //         customFields: organizationCustomFields,
  //         certificateImageUri,
  //       },
  //       userData: {
  //         customFields: {
  //           birthday,
  //           familyName,
  //           familyNameKana,
  //           firstName,
  //           firstNameKana,
  //           gender,
  //           genderSelf,
  //         },
  //       },
  //     } = formData;

  //     try {
  //       const certificateImageData = await urlToBase64(certificateImageUri);
  //       // mainImagesが空の場合、デフォルトの画像をセット
  //       const iconImageData = await urlToBase64(
  //         organizationCustomFields.mainImages ||
  //           process.env.PUBLIC_URL + '/clinic-sample-image.png'
  //       );
  //       const orgResult = await createOrganization({
  //         addressLine1,
  //         addressLine2,
  //         addressLine3,
  //         certificateImageData,
  //         customFields: {
  //           ...organizationCustomFields,
  //           mainImages: '',
  //           ownerUserId: userAuthInfoState.id,
  //         },
  //         description,
  //         iconImageData,
  //         name,
  //         ownerId: userAuthInfoState.id,
  //         phoneNumber,
  //         postalCode: `${postalCode}`,
  //       });

  //       await update(
  //         userAuthInfoState.id,
  //         birthday,
  //         familyName,
  //         familyNameKana,
  //         firstName,
  //         firstNameKana,
  //         gender,
  //         genderSelf,
  //         userAuthInfoState.accessToken
  //       );

  //       setSnackbarText('医療機関情報の登録が完了しました');
  //       setSnackbarSeverityState('success');
  //       setSnackbarOpen(true);
  //       setOrganization(orgResult.data);
  //       if (loggedInUser) {
  //         setLoggedInUser({
  //           ...loggedInUser,
  //           customFields: {
  //             familyName,
  //             firstName,
  //           } as User['customFields'],
  //         });
  //       }
  //       navigate('/');
  //     } catch (error) {
  //       if (isError(error)) {
  //         setSnackbarText(
  //           `医療機関情報の登録が出来ませんでした, ${error.message}`
  //         );
  //       } else {
  //         setSnackbarText(`医療機関情報の登録が出来ませんでした`);
  //       }
  //       setSnackbarOpen(true);
  //     }
  //   },
  //   [
  //     navigate,
  //     loggedInUser,
  //     setLoggedInUser,
  //     setSnackbarOpen,
  //     setSnackbarSeverityState,
  //     setSnackbarText,
  //     setOrganization,
  //     userAuthInfoState.id,
  //     userAuthInfoState.accessToken,
  //   ]
  // );

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        mt: 6,
      }}
    >
      <Box sx={{ maxWidth: 'unset !important', width: 630 }}>
        <Box sx={{ px: 9, py: 7 }}>
          <Box sx={{ p: '0 !important' }}>
            {step === 'userInformationForm' ? (
              <RegistrationInput
                onSubmit={handleSubmitUserForm}
                defaultValues={registerData}
              />
            ) : undefined}
            {step === 'inReview' ? (
              <InReview
                onSubmit={handleSubmitRegistrationForm}
                onBack={handleBackInReview}
              />
            ) : undefined}
            {step === 'complete' ? <Completed /> : undefined}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
