// 2xx
export const HTTP_STATUS_CODE_OK = 200;
export const HTTP_STATUS_CODE_CREATED = 201;
export const HTTP_STATUS_CODE_NO_CONTENT = 204;

// 4xx
export const HTTP_STATUS_CODE_BAD_REQUEST = 400;
export const HTTP_STATUS_CODE_UNAUTHORIZED = 401;
export const HTTP_STATUS_CODE_FORBIDDEN = 403;
export const HTTP_STATUS_CODE_NOT_FOUND = 404;

export function filterSyntaxGen(list: string[]) {
  const formattedList = list.map((p) => `'${p}'`);
  return `[${formattedList.join(',')}]`;
}
