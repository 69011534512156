import { Typography } from '@mui/material';

export function Copyright() {
  const currentYear = new Date().getFullYear();

  return (
    <Typography
      variant="body3"
      textAlign="center"
      py={1}
      sx={{ color: '#1C295D' }}
    >
      ©Copyright {currentYear}. All Right Reserved
    </Typography>
  );
}
