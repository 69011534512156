import { AxiosPromise, CanceledError } from 'axios';

import { authInstance } from '@app/adapter/axios';

export interface LoginResponse {
  accessToken: string;
  userId: string;
}

/**
 * Login use auth-service
 * @param email
 * @param password
 * @param fingerprint
 * @returns
 */
export function login(
  email: string,
  password: string,
  fingerprint: string
): AxiosPromise<LoginResponse> {
  return authInstance
    .post('/login', { email, fingerprint, password })
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

/**
 * Check token
 * @param token
 * @returns
 */
export function checkToken<T>(
  token: string,
  fingerprint: string,
  abortController?: AbortController
): AxiosPromise<T> {
  return authInstance
    .post(
      '/check-token',
      { fingerprint, token },
      {
        signal: abortController?.signal,
      }
    )
    .catch((error) => {
      if (error instanceof CanceledError) {
        throw error;
      } else if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}
