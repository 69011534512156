import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  TextField,
  Typography,
  Select,
  MenuItem,
  useTheme,
  Stack,
} from '@mui/material';
import axios from 'axios';
import { ReactElement, useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useRecoilState } from 'recoil';

import { ProgressStepper } from '@app/components/User/ProgressStepper';
import { registerState } from '@app/domain/register';
import { UserFormData, UserRegistrationForm } from '@app/schemas/registration';
import { PREFECTURE_OPTIONS, occupationOptions } from '@app/static/constants';
import { isError } from '@app/utils/error';

const genderOptions = ['男性', '女性', 'その他'] as const;

interface RegistrationInputProps {
  defaultValues: UserFormData;
  onSubmit: (data: UserFormData) => void;
}

const formLabel = (label: string, require?: boolean): ReactElement => {
  return (
    <FormLabel>
      <Typography
        color="textPrimary"
        variant="subtitle2"
        fontWeight={600}
        sx={{ mb: 1 }}
      >
        {label}
        {require && <RequiredLabel />}
      </Typography>
    </FormLabel>
  );
};

const RequiredLabel = () => {
  const theme = useTheme();
  return (
    <Typography
      component="span"
      variant="body2"
      sx={{
        backgroundColor: theme.palette.error.main,
        borderRadius: '8px',
        color: theme.palette.common.white,
        ml: 1,
        padding: '2px 4px',
      }}
    >
      必須
    </Typography>
  );
};

const yearOptions = () => {
  const currentYear = new Date().getFullYear();
  return Array.from({ length: 101 }, (item, index) => currentYear - index);
};

const monthOptions = Array.from({ length: 12 }, (item, index) => index + 1);
const dayOptions = Array.from({ length: 31 }, (item, index) => index + 1);

export function RegistrationInput({
  onSubmit,
}: RegistrationInputProps): ReactElement {
  const [registerData, setRegisterData] = useRecoilState(registerState);

  const { control, watch, handleSubmit, formState, setValue, getValues } =
    useForm<UserFormData>({
      defaultValues: registerData,
      mode: 'onChange',
      resolver: UserRegistrationForm.resolver,
    });

  const theme = useTheme();
  const { isValid } = formState;

  const handleClickPostalCodeSearch = async () => {
    const postalCode = getValues('postalCode') || '';
    if (!postalCode || postalCode.length < 7) {
      return;
    }

    try {
      const response = await axios.get(
        `https://zipcloud.ibsnet.co.jp/api/search?zipcode=${postalCode.toString()}`
      );
      const result = response.data.results[0];
      if (result) {
        setValue('addressLine1', result.address1);
        setValue('addressLine2', result.address2);
        setValue('addressLine3', result.address3);

        setRegisterData((prev) => ({
          ...prev,
          addressLine1: result.address1,
          addressLine2: result.address2,
          addressLine3: result.address3,
        }));
      }
    } catch (e) {
      if (isError(e)) {
        throw new Error(e.message);
      }
    }
  };

  const submit = useCallback(
    (data: UserFormData) => {
      setRegisterData((prev) => ({
        ...prev,
        ...data,
        addressLine2: registerData.addressLine2,
        customFields: {
          ...prev.customFields,
          ...data.customFields,
        },
      }));
      onSubmit(data);
    },
    [onSubmit, registerData.addressLine2, setRegisterData]
  );

  const handleDateChange = (field: 'year' | 'month' | 'day', value: string) => {
    const birthday = new Date(watch('birthday') || new Date());
    if (field === 'year') birthday.setFullYear(Number(value));
    if (field === 'month') birthday.setMonth(Number(value) - 1);
    if (field === 'day') birthday.setDate(Number(value));
    const formattedBirthday = birthday.toISOString().split('T')[0];
    setValue('birthday', formattedBirthday);
    setRegisterData((prev) => ({
      ...prev,
      birthday: formattedBirthday,
    }));
  };

  return (
    <>
      <ProgressStepper activeStep={0} />

      <Box
        sx={{
          border: `1px solid ${theme.palette.neutral.silver}`,
          borderRadius: 1,
          mt: 10,
          padding: 3,
        }}
      >
        <form
          onSubmit={handleSubmit(submit, (errors) => {
            console.log(errors);
          })}
        >
          <Typography variant="subtitle2" fontWeight={600} sx={{ mb: 5 }}>
            会員情報登録
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <FormControl fullWidth variant="outlined">
                <FormLabel>
                  <Typography
                    color="textPrimary"
                    variant="subtitle2"
                    fontWeight={700}
                    sx={{ mb: 1 }}
                  >
                    姓
                    <RequiredLabel />
                  </Typography>
                </FormLabel>
                <Controller
                  name="customFields.familyName"
                  control={control}
                  defaultValue={registerData.customFields.familyName}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      value={registerData.customFields.familyName}
                      onChange={(e) => {
                        setRegisterData({
                          ...registerData,
                          customFields: {
                            ...registerData.customFields,
                            familyName: e.target.value,
                          },
                        });
                        field.onChange(e);
                      }}
                      error={error ? true : false}
                      helperText={error?.message}
                      margin="dense"
                      placeholder={'山田'}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth variant="outlined">
                <FormLabel>
                  <Typography
                    color="textPrimary"
                    variant="subtitle2"
                    fontWeight={700}
                    sx={{ mb: 1 }}
                  >
                    名
                    <RequiredLabel />
                  </Typography>
                </FormLabel>
                <Controller
                  name="customFields.firstName"
                  control={control}
                  defaultValue={registerData.customFields.firstName}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      value={registerData.customFields.firstName}
                      onChange={(e) => {
                        setRegisterData({
                          ...registerData,
                          customFields: {
                            ...registerData.customFields,
                            firstName: e.target.value,
                          },
                        });
                        field.onChange(e);
                      }}
                      error={error ? true : false}
                      helperText={error?.message}
                      margin="dense"
                      placeholder={'太郎'}
                    />
                  )}
                />
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl fullWidth variant="outlined">
                <FormLabel>
                  <Typography
                    color="textPrimary"
                    variant="subtitle2"
                    fontWeight={700}
                    sx={{ mb: 1 }}
                  >
                    姓(かな)
                    <RequiredLabel />
                  </Typography>
                </FormLabel>
                <Controller
                  name="customFields.familyNameKana"
                  control={control}
                  defaultValue={registerData.customFields.familyNameKana}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      value={registerData.customFields.familyNameKana}
                      onChange={(e) => {
                        setRegisterData({
                          ...registerData,
                          customFields: {
                            ...registerData.customFields,
                            familyNameKana: e.target.value,
                          },
                        });
                        field.onChange(e);
                      }}
                      error={error ? true : false}
                      helperText={error?.message}
                      margin="dense"
                      placeholder={'やまだ'}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth variant="outlined">
                <FormLabel>
                  <Typography
                    color="textPrimary"
                    variant="subtitle2"
                    fontWeight={700}
                    sx={{ mb: 1 }}
                  >
                    名(かな)
                    <RequiredLabel />
                  </Typography>
                </FormLabel>
                <Controller
                  name="customFields.firstNameKana"
                  control={control}
                  defaultValue={registerData.customFields.firstNameKana}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      value={registerData.customFields.firstNameKana}
                      onChange={(e) => {
                        setRegisterData({
                          ...registerData,
                          customFields: {
                            ...registerData.customFields,
                            firstNameKana: e.target.value,
                          },
                        });
                        field.onChange(e);
                      }}
                      error={error ? true : false}
                      helperText={error?.message}
                      margin="dense"
                      placeholder={'たろう'}
                    />
                  )}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              {formLabel('性別', true)}
              <Controller
                name="gender"
                control={control}
                defaultValue={registerData.gender}
                render={({ field, fieldState: { error } }) => (
                  <FormControl error={!!error} fullWidth>
                    <Select
                      {...field}
                      displayEmpty
                      margin="dense"
                      onChange={(e) => {
                        field.onChange(e);
                        setRegisterData((prev) => ({
                          ...prev,
                          gender: e.target.value as
                            | '男性'
                            | '女性'
                            | 'その他'
                            | '',
                        }));
                      }}
                    >
                      <MenuItem value="" disabled>
                        <span style={{ color: theme.palette.text.disabled }}>
                          選択してください
                        </span>
                      </MenuItem>
                      {genderOptions.map((value, index) => (
                        <MenuItem key={index} value={value}>
                          {value}
                        </MenuItem>
                      ))}
                    </Select>
                    {!!error && (
                      <FormHelperText>{error?.message}</FormHelperText>
                    )}
                  </FormControl>
                )}
              />
            </Grid>

            <Grid item xs={12}>
              {formLabel('生年月日', false)}
              <Stack direction="row" spacing={1} alignItems="center">
                <Controller
                  name="birthday"
                  control={control}
                  render={({ field }) => (
                    <>
                      <Select
                        value={
                          field.value
                            ? String(new Date(field.value).getFullYear())
                            : ''
                        }
                        onChange={(e) =>
                          handleDateChange('year', e.target.value)
                        }
                        margin="dense"
                        fullWidth
                      >
                        <MenuItem value="" disabled>
                          <span style={{ color: theme.palette.text.disabled }}>
                            選択してください
                          </span>
                        </MenuItem>
                        {yearOptions().map((value, index) => (
                          <MenuItem key={index} value={value}>
                            {value}
                          </MenuItem>
                        ))}
                      </Select>
                      <Typography>年</Typography>
                      <Select
                        value={
                          field.value
                            ? String(new Date(field.value).getMonth() + 1)
                            : ''
                        }
                        onChange={(e) =>
                          handleDateChange('month', e.target.value)
                        }
                        margin="dense"
                        fullWidth
                      >
                        <MenuItem value="" disabled>
                          <span style={{ color: theme.palette.text.disabled }}>
                            選択してください
                          </span>
                        </MenuItem>
                        {monthOptions.map((value, index) => (
                          <MenuItem key={index} value={value}>
                            {value}
                          </MenuItem>
                        ))}
                      </Select>
                      <Typography>月</Typography>
                      <Select
                        value={
                          field.value
                            ? String(new Date(field.value).getDate())
                            : ''
                        }
                        onChange={(e) =>
                          handleDateChange('day', e.target.value)
                        }
                        margin="dense"
                        fullWidth
                      >
                        <MenuItem value="" disabled>
                          <span style={{ color: theme.palette.text.disabled }}>
                            選択してください
                          </span>
                        </MenuItem>
                        {dayOptions.map((value, index) => (
                          <MenuItem key={index} value={value}>
                            {value}
                          </MenuItem>
                        ))}
                      </Select>
                      <Typography>日</Typography>
                    </>
                  )}
                />
              </Stack>
            </Grid>

            <Grid item xs={12}>
              {formLabel('住所', true)}
              <Stack spacing={1}>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography variant="body2" fontWeight={600} sx={{ ml: 1 }}>
                    〒
                  </Typography>
                  <Grid item xs={6}>
                    <Controller
                      name="postalCode"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          error={!!error}
                          helperText={error?.message}
                          margin="dense"
                          placeholder={'1000001'}
                          onChange={(e) => {
                            const value = e.target.value;
                            field.onChange(value);
                            setRegisterData((prev) => ({
                              ...prev,
                              postalCode: value,
                            }));
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Button
                    style={{ color: theme.palette.primary.main }}
                    variant="text"
                    onClick={handleClickPostalCodeSearch}
                  >
                    住所を検索
                  </Button>
                </Stack>
                <Grid item xs={7}>
                  <Stack spacing={1}>
                    <Controller
                      name="addressLine1"
                      control={control}
                      defaultValue=""
                      render={({ field, fieldState: { error } }) => (
                        <Select
                          {...field}
                          displayEmpty
                          margin="dense"
                          fullWidth
                          onChange={(e) => {
                            const value = e.target.value;
                            field.onChange(value);
                            setRegisterData((prev) => ({
                              ...prev,
                              addressLine1: value,
                            }));
                          }}
                        >
                          <MenuItem value="" disabled>
                            <span
                              style={{ color: theme.palette.text.disabled }}
                            >
                              都道府県
                            </span>
                          </MenuItem>
                          {PREFECTURE_OPTIONS.map((value, index) => (
                            <MenuItem key={index} value={value}>
                              {value}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                    <Controller
                      name="addressLine2"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          error={!!error}
                          helperText={error?.message}
                          margin="dense"
                          placeholder={'市区町村'}
                          fullWidth
                          onChange={(e) => {
                            const value = e.target.value;
                            field.onChange(value);
                            setRegisterData((prev) => ({
                              ...prev,
                              addressLine2: value,
                            }));
                          }}
                        />
                      )}
                    />
                  </Stack>
                </Grid>
                <Controller
                  name="addressLine3"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      error={!!error}
                      helperText={error?.message}
                      margin="dense"
                      placeholder={'町名・番地'}
                      fullWidth
                      onChange={(e) => {
                        const value = e.target.value;
                        field.onChange(value);
                        setRegisterData((prev) => ({
                          ...prev,
                          addressLine3: value,
                        }));
                      }}
                    />
                  )}
                />
                <Controller
                  name="customFields.addressLine4"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      error={!!error}
                      helperText={error?.message}
                      margin="dense"
                      placeholder={'建物名・部屋番号'}
                      fullWidth
                      onChange={(e) => {
                        const value = e.target.value;
                        field.onChange(value);
                        setRegisterData((prev) => ({
                          ...prev,
                          customFields: {
                            ...prev.customFields,
                            addressLine4: value,
                          },
                        }));
                      }}
                    />
                  )}
                />
              </Stack>
            </Grid>

            <Grid item xs={12} sx={{ px: 3 }}>
              <FormControl fullWidth variant="outlined">
                <FormLabel>
                  <Typography
                    color="textPrimary"
                    variant="subtitle2"
                    fontWeight={700}
                    display={'flex'}
                    component="span"
                    sx={{ mb: 1 }}
                  >
                    電話番号
                    <RequiredLabel />
                  </Typography>
                </FormLabel>
                <Controller
                  name="phoneNumber"
                  control={control}
                  defaultValue={registerData.phoneNumber}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      value={registerData.phoneNumber}
                      onChange={(e) => {
                        setRegisterData({
                          ...registerData,
                          phoneNumber: e.target.value,
                        });
                        field.onChange(e);
                      }}
                      type="tel"
                      error={error ? true : false}
                      helperText={error?.message}
                      margin="dense"
                      placeholder="09012345678"
                    />
                  )}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              {formLabel('職業', false)}
              <Controller
                name="occupation"
                control={control}
                defaultValue={registerData.occupation}
                render={({ field, fieldState: { error } }) => (
                  <FormControl error={!!error} fullWidth>
                    <Select
                      {...field}
                      displayEmpty
                      margin="dense"
                      onChange={(e) => {
                        field.onChange(e);
                        setRegisterData((prev) => ({
                          ...prev,
                          occupation: e.target.value,
                        }));
                      }}
                    >
                      <MenuItem value="" disabled>
                        <span style={{ color: theme.palette.text.disabled }}>
                          選択してください
                        </span>
                      </MenuItem>
                      {occupationOptions.map((value, index) => (
                        <MenuItem key={index} value={value}>
                          {value}
                        </MenuItem>
                      ))}
                    </Select>
                    {!!error && (
                      <FormHelperText>{error?.message}</FormHelperText>
                    )}
                  </FormControl>
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined">
                <FormLabel>
                  <Typography
                    color="textPrimary"
                    variant="subtitle2"
                    fontWeight={700}
                    sx={{ mb: 1 }}
                  >
                    勤務先
                  </Typography>
                </FormLabel>
                <Controller
                  name="employer"
                  control={control}
                  defaultValue={registerData.employer}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      value={registerData.employer}
                      onChange={(e) => {
                        setRegisterData({
                          ...registerData,
                          employer: e.target.value,
                        });
                        field.onChange(e);
                      }}
                      error={error ? true : false}
                      helperText={error?.message}
                      margin="dense"
                      placeholder={'〇〇商事'}
                    />
                  )}
                />
              </FormControl>
            </Grid>

            <Grid item xs={5}>
              <FormControl fullWidth variant="outlined">
                <FormLabel>
                  <Typography
                    color="textPrimary"
                    variant="subtitle2"
                    fontWeight={700}
                    sx={{ mb: 1 }}
                  >
                    世帯年収
                  </Typography>
                </FormLabel>
                <Box sx={{ alignItems: 'center', display: 'flex' }}>
                  <Controller
                    name="householdIncome"
                    control={control}
                    defaultValue={registerData.householdIncome}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        type="number"
                        value={
                          registerData.householdIncome !== null
                            ? registerData.householdIncome
                            : ''
                        }
                        onChange={(e) => {
                          const value = e.target.value
                            ? parseFloat(e.target.value)
                            : null;
                          setRegisterData({
                            ...registerData,
                            householdIncome: value,
                          });
                          field.onChange(value);
                        }}
                        error={error ? true : false}
                        helperText={error?.message}
                        margin="dense"
                        placeholder={'1000'}
                        sx={{ mr: 1 }}
                      />
                    )}
                  />
                  <Typography
                    color="textPrimary"
                    variant="body1"
                    sx={{ whiteSpace: 'nowrap' }}
                  >
                    万円
                  </Typography>
                </Box>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined">
                <FormLabel>
                  <Typography
                    color="textPrimary"
                    variant="subtitle2"
                    fontWeight={700}
                    sx={{ mb: 1 }}
                  >
                    家族・世帯構成
                  </Typography>
                </FormLabel>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={registerData.householdComposition.spouse > 0}
                      onChange={(e) => {
                        const value = e.target.checked ? 1 : 0;
                        setRegisterData({
                          ...registerData,
                          householdComposition: {
                            ...registerData.householdComposition,
                            spouse: value,
                          },
                        });
                        setValue('householdComposition.spouse', value);
                      }}
                    />
                  }
                  label="配偶者"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={registerData.householdComposition.children > 0}
                      onChange={(e) => {
                        const value = e.target.checked ? 1 : 0;
                        setRegisterData({
                          ...registerData,
                          householdComposition: {
                            ...registerData.householdComposition,
                            children: value,
                          },
                        });
                        setValue('householdComposition.children', value);
                      }}
                    />
                  }
                  label="お子様"
                />
                {registerData.householdComposition.children > 0 && (
                  <Box sx={{ alignItems: 'center', display: 'flex', mt: 2 }}>
                    <Controller
                      name="householdComposition.children"
                      control={control}
                      defaultValue={registerData.householdComposition.children}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          type="number"
                          value={registerData.householdComposition.children}
                          onChange={(e) => {
                            const value = parseInt(e.target.value, 10);
                            setRegisterData({
                              ...registerData,
                              householdComposition: {
                                ...registerData.householdComposition,
                                children: value,
                              },
                            });
                            field.onChange(value);
                          }}
                          error={error ? true : false}
                          helperText={error?.message}
                          margin="dense"
                          placeholder={'2'}
                          sx={{ mr: 1 }}
                        />
                      )}
                    />
                    <Typography color="textPrimary" variant="body1">
                      人
                    </Typography>
                  </Box>
                )}
                <Controller
                  name="householdComposition.others"
                  control={control}
                  defaultValue={registerData.householdComposition.others}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      value={registerData.householdComposition.others}
                      onChange={(e) => {
                        const value = e.target.value;
                        setRegisterData({
                          ...registerData,
                          householdComposition: {
                            ...registerData.householdComposition,
                            others: value,
                          },
                        });
                        field.onChange(value);
                      }}
                      error={error ? true : false}
                      helperText={error?.message}
                      margin="dense"
                      placeholder={'その他'}
                      fullWidth
                      sx={{ mt: 2 }}
                    />
                  )}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sx={{ px: 3 }}>
              <Grid item xs={12} sx={{ pt: 6, px: 3 }}>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  fullWidth
                  disabled={!isValid}
                >
                  入力項目の確認へ進む
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
}
