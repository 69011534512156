import { createContext, useContext } from 'react';
type AuthInfo = {
  accessToken: string;
  fingerPrint: string;
  userId: string;
} | null;
type AppContextType = {
  authInfo: AuthInfo;
};

export const AppContext = createContext<AppContextType | undefined>(undefined);

export const useAppContext = (): AppContextType => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useAppContext must be used within an AppContextProvider');
  }
  return context;
};
