import { Stack, Typography } from '@mui/material';

export function EmailSent() {
  return (
    <>
      <Stack spacing={3} textAlign="center" mt={10} px={4}>
        <Typography fontSize={24} fontWeight={700}>
          パスワード再設定手続きのメールをお送りしました
        </Typography>
        <Typography color="text.gray">
          ご登録メールアドレス宛にパスワード再発行手続きのメールをお送りしました。
          <br />
          メール本文に記述されているURLをクリックして頂き、パスワード再設定手続きを行ってください。
        </Typography>
      </Stack>
    </>
  );
}
