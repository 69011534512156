import { TypeId, User } from '@app/types/user';

export function isDemanderUser(typeId: string) {
  return typeId === TypeId.DEMAND;
}

export function isUserInfoRegistered(user: User | null): boolean {
  return !!user?.customFields?.familyName;
}

export function generateInviteCode() {
  const base = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  return Array.from(crypto.getRandomValues(new Uint32Array(10)))
    .map((v) => base[v % base.length])
    .join('');
}
