import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import React from 'react';

import { theme } from '@app/theme';
import { Product } from '@app/types/catalog';

interface ExhibitionInfoProps {
  product: Product;
}

const TableCellWithStyle = ({ children }: { children: React.ReactNode }) => (
  <TableCell
    sx={{
      backgroundColor: theme.palette.neutral.greyLight,
      fontWeight: 'bold',
    }}
  >
    {children}
  </TableCell>
);

export function ExhibitionInfo({ product }: ExhibitionInfoProps) {
  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h6" fontWeight={600} sx={{ mb: 3 }}>
        住宅展示場情報
      </Typography>
      <TableContainer
        component={Paper}
        sx={{ border: '1px solid #e0e0e0', borderRadius: '8px' }}
      >
        <Table>
          <TableBody>
            <TableRow>
              <TableCellWithStyle>所在地</TableCellWithStyle>
              <TableCell>{product.customFields.contactAddress}</TableCell>
            </TableRow>
            <TableRow>
              <TableCellWithStyle>問い合わせ</TableCellWithStyle>
              <TableCell>
                {typeof product.organization === 'object' &&
                product.organization !== null &&
                product.organization.phoneNumber ? (
                  <>
                    {product.organization.phoneNumber}
                    <Typography
                      component="span"
                      variant="body2"
                      sx={{ color: theme.palette.grey[500], ml: 1 }}
                    >
                      ※営業時間内の対応となります
                    </Typography>
                  </>
                ) : (
                  '-'
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCellWithStyle>営業時間</TableCellWithStyle>
              <TableCell>
                {product.customFields.openingTime}-
                {product.customFields.closingTime}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCellWithStyle>定休日</TableCellWithStyle>
              <TableCell>{product.customFields.regularHoliday}</TableCell>
            </TableRow>
            <TableRow>
              <TableCellWithStyle>アクセス</TableCellWithStyle>
              <TableCell>{product.customFields.access}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
