import CheckIcon from '@mui/icons-material/Check';
import { Box, Grid } from '@mui/material';
import { styled } from '@mui/system';
import React from 'react';

import { theme } from '@app/theme';

const steps = [1, 2, 3];

interface ProgressStepperProps {
  activeStep: number;
}

const CustomBox = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== 'whiteColor' && prop !== 'active' && prop !== 'primaryColor',
})<{
  active: boolean;
  primaryColor: string;
  whiteColor: string;
}>(({ active, primaryColor, whiteColor }) => ({
  alignItems: 'center',
  backgroundColor: active ? primaryColor : theme.palette.neutral.greyDark,
  borderRadius: '50%',
  color: whiteColor,
  display: 'flex',
  fontSize: '1.3rem',
  fontWeight: 600,
  height: 35,
  justifyContent: 'center',
  paddingRight: 1,
  width: 35,
}));

const DottedLine = styled('div')({
  backgroundColor: theme.palette.neutral.silver,
  borderRadius: '50%',
  height: 8,
  margin: '0 8px',
  width: 8,
});

export function ProgressStepper({ activeStep }: ProgressStepperProps) {
  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        mb: 6,
        mt: 4,
        width: '100%',
      }}
    >
      <Grid
        sx={{
          alignItems: 'center',
          display: 'flex',
        }}
      >
        {steps.map((step, index) => (
          <Grid sx={{ alignItems: 'center', display: 'flex' }} key={index}>
            <CustomBox
              whiteColor={theme.palette.common.white}
              active={
                index === activeStep ||
                (index === steps.length - 1 && activeStep === 4)
              }
              primaryColor={theme.palette.primary.main}
            >
              {index === steps.length - 1 && activeStep === 4 ? (
                <CheckIcon
                  sx={{
                    color: theme.palette.neutral.white,
                  }}
                />
              ) : (
                step
              )}
            </CustomBox>
            {index !== steps.length - 1 && <DottedLine />}
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
