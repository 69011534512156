import { Container, CircularProgress } from '@mui/material';
import { ReactElement, useEffect } from 'react';

import { useOnScreen } from '@app/hooks/useScroll';

type ScrollThresholdProps = {
  disabled: boolean;
  thresholdReached: (endOfListReached: boolean) => void;
};

export function ScrollThreshold({
  thresholdReached,
  disabled,
}: ScrollThresholdProps): ReactElement {
  const [isEndOfList, isEndOfListRef] = useOnScreen();

  useEffect(() => {
    if (isEndOfList) {
      thresholdReached(isEndOfList);
    }
  }, [isEndOfList, thresholdReached]);

  const text = isEndOfList ? <CircularProgress color="primary" /> : null;
  return !disabled ? (
    <>
      <Container sx={{ py: 3, textAlign: 'center' }} ref={isEndOfListRef}>
        {text}
      </Container>
    </>
  ) : (
    <></>
  );
}
