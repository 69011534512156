import { AlertColor } from '@mui/material';
import { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';

import {
  snackbarOpenState,
  snackbarSeverityState,
  snackbarTextState,
} from '@app/domain/top-nav';

/**
 * Snackbar をまとめて制御するhook
 */
const DEFAULT_SNACKBAR_SEVERITY = 'error';
export const useSetSnackbar = () => {
  const setIsOpenSnackbar = useSetRecoilState(snackbarOpenState);
  const setSnackbarText = useSetRecoilState(snackbarTextState);
  const setSnackbarSeverity = useSetRecoilState(snackbarSeverityState);
  const setSnackbar = useCallback(
    (
      isOpen: boolean,
      text: string,
      severity: AlertColor = DEFAULT_SNACKBAR_SEVERITY
    ): void => {
      setSnackbarText(text);
      setSnackbarSeverity(severity);
      setIsOpenSnackbar(isOpen);
    },
    [setIsOpenSnackbar, setSnackbarText, setSnackbarSeverity]
  );
  return setSnackbar;
};
