import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Paper, Stack, Box, Button } from '@mui/material';
import React, { useState } from 'react';

interface CarouselItemProps {
  image: string;
}

export function CarouselItem({ image }: CarouselItemProps) {
  return (
    <Paper>
      <img
        src={image}
        alt="carousel item"
        style={{ height: '240px', objectFit: 'cover', width: '360px' }}
      />
    </Paper>
  );
}

interface ImageCarouselProps {
  displayMode: 'largeImage' | 'carousel';
  images: string[];
}

export function ImageCarousel({ images, displayMode }: ImageCarouselProps) {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrev = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const handleNext = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <Stack
      sx={{
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {displayMode === 'largeImage' && (
        <Box sx={{ mb: 3 }}>
          <img
            src={images[currentIndex]}
            alt="selected item"
            style={{ height: '550px', objectFit: 'cover', width: '980px' }}
          />
        </Box>
      )}

      {(displayMode === 'carousel' || displayMode === 'largeImage') && (
        <Stack
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
          }}
          direction="row"
        >
          <Box
            sx={{ overflow: 'hidden', position: 'relative', width: '1080px' }}
          >
            <Box
              sx={{
                display: 'flex',
                transform: `translateX(-${currentIndex * 360}px)`,
                transition: 'transform 0.5s ease-in-out',
              }}
            >
              {images.map((image, index) => (
                <CarouselItem key={index} image={image} />
              ))}
            </Box>
            <Button
              onClick={handlePrev}
              sx={{
                '&:hover': {
                  backgroundColor: 'white',
                },
                backgroundColor: 'white',
                borderRadius: '50%',
                left: 0,
                minHeight: '40px',
                minWidth: '40px',
                padding: 0,
                position: 'absolute',
                top: '50%',
                transform: 'translateY(-50%)',
                zIndex: 1,
              }}
            >
              <ArrowBackIcon />
            </Button>
            <Button
              onClick={handleNext}
              sx={{
                '&:hover': {
                  backgroundColor: 'white',
                },
                backgroundColor: 'white',
                borderRadius: '50%',
                minHeight: '40px',
                minWidth: '40px',
                padding: 0,
                position: 'absolute',
                right: 0,
                top: '50%',
                transform: 'translateY(-50%)',
                zIndex: 1,
              }}
            >
              <ArrowForwardIcon />
            </Button>
          </Box>
        </Stack>
      )}
    </Stack>
  );
}
