import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box, Button, Divider, Stack, Typography } from '@mui/material';
import { ReactElement } from 'react';

interface AccountProps {
  user?: {
    email: string;
  };
}

export function Account({ user }: AccountProps): ReactElement {
  const navigateToChangePassword = () => {
    alert('Go to Change password page');
  };
  const navigateToProfileManagement = () => {
    alert('Go to Change password page');
  };
  return (
    <Stack sx={{ color: '#333', ml: 5 }}>
      <Typography variant="h3" sx={{ fontWeight: 'bold', mb: 4 }}>
        セキュリティー
      </Typography>
      <Stack divider={<Divider flexItem />} spacing={2}>
        <Stack direction="row" alignItems="center">
          <Typography sx={{ color: '#7A7A7A', minWidth: '200px' }}>
            メールアドレス
          </Typography>
          <Typography flexGrow={1}>sampleEmail@pro-cloud.com</Typography>
        </Stack>
        <Stack direction="row" alignItems="center">
          <Typography sx={{ color: '#7A7A7A', minWidth: '200px' }}>
            パスワード
          </Typography>
          <Stack
            direction="row"
            flexGrow={1}
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography flexGrow={1}>・・・・・・・・・</Typography>
            <Button
              sx={{ alignItems: 'center', display: 'inline-flex' }}
              onClick={navigateToChangePassword}
            >
              <Typography
                textAlign={'right'}
                variant="body2"
                color="#039c9f"
                flexGrow={1}
              >
                編集
              </Typography>
              <ChevronRightIcon fontSize="small" color="info" />
            </Button>
          </Stack>
        </Stack>
      </Stack>
      <Divider sx={{ mt: 2 }} />
      <Typography variant="h3" sx={{ fontWeight: 'bold', mb: 4, mt: 3 }}>
        アカウント管理
      </Typography>
      <Stack divider={<Divider flexItem />} spacing={2}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box minWidth="200px">
            <Typography>アカウントを削除する</Typography>
            <Typography
              variant="body3"
              sx={{ color: '#7A7A7A', display: 'inline-block', mt: 1 }}
            >
              削除すると保存された情報がすべて削除されます
            </Typography>
          </Box>
          <Button
            sx={{ alignItems: 'center', display: 'inline-flex' }}
            onClick={navigateToProfileManagement}
          >
            <Typography
              textAlign={'right'}
              variant="body2"
              color="error"
              flexGrow={1}
            >
              アカウント管理
            </Typography>
            <ChevronRightIcon fontSize="small" color="error" />
          </Button>
        </Stack>
      </Stack>
      <Divider sx={{ mt: 2 }} />
    </Stack>
  );
}
