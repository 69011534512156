/** blocks-1d31 */
export interface Pagination<T> {
  '@nextLink': string;
  count: number;
  total: number;
  value: T;
}

/** blocks-1d31 */
export enum LoadableState {
  HAS_ERROR = 'hasError',
  HAS_VALUE = 'hasValue',
  LOADING = 'loading',
  NOT_FOUND = 'notFound',
}
