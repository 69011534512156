export const TypeId = {
  DEMAND: '000',
} as const;
export type TypeIdKey = (typeof TypeId)[keyof typeof TypeId];

export interface User {
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  avatar: string;
  customFields: UserCustomFields;
  email: string;
  emailVerified: boolean;
  id: string;
  name: string;
  phoneNumber: string;
  reviewStats: {
    averageRate: number;
    averageSubRates: {
      attendance: number;
      communication: number;
      followRule: number;
      quality: number;
      reception: number;
      speed: number;
    };
    totalCount: number;
  };
  typeId: TypeIdKey;
}

export interface UserCustomFields {
  addressLine4?: string;
  birthday?: string;
  clinicalDepartments?: string[];
  confidences?: string[];
  currentDepartment?: string;
  currentHospital?: string;
  familyName?: string;
  familyNameKana?: string;
  firstClinicalDepartments?: string[];
  firstName?: string;
  firstNameKana?: string;
  gender?: string;
  genderSelf?: string;
  inviteCode?: string;
  invitedCode?: string;
  jobChange?: string;
  license?: string;
  medicalLicenseYear?: number;
  medicalRegisterNumber?: string;
  notes?: string;
  orderAfterFiles?: UploadFileInfo[];
  orderMatchedFiles?: UploadFileInfo[];
  postalCode?: string;
  universityName?: string;
}

export interface UserUpdate {
  addressLine1?: string;
  addressLine2?: string;
  addressLine3?: string;
  customFields: UserCustomFields;
  phoneNumber?: string;
}

export interface UploadFileInfo {
  attachmentId: string;
  name: string;
}

export interface UserEmailUpdate {
  email: string;
}

export interface UserPasswordUpdate {
  newPasswords?: {
    confirmPassword?: string;
    password?: string;
  };
  password?: string;
}

export interface UserSendResetPassword {
  email: string;
}

export const JobChange = {
  ACTIVE: 'active', // 積極的
  CONDITIONS_MATCH: 'conditionsMatch', // いい条件があれば
  NOT_THINKING: 'notThinking', // 今は考えてない
} as const;
export type JobChangeKey = (typeof JobChange)[keyof typeof JobChange];

/**
 * preference
 */
export const PreferenceKeys = {
  NOTIFICATION: 'notification', // 通知設定
  SEARCH_CONDITION: 'searchCondition', // 検索条件
} as const;
export type PreferenceKey =
  (typeof PreferenceKeys)[keyof typeof PreferenceKeys];

export interface PreferenceCreation {
  key: PreferenceKey;
  value: string;
}

export interface Preference {
  createdAt: Date;
  delFlg: number;
  id: string;
  key: string;
  updatedAt: Date;
  userId: string;
  value: string;
}

export interface PreferenceNotification {
  newMessageByEmail: boolean;
  newProductByFollowedOrg: boolean;
  sendRemindDayBeforeWork: boolean;
}

/**
 * attachment
 */
export interface Attachment {
  id?: string;
  ownerId?: string;
  ownerType?: string;
  type?: string;
  url?: string;
}

export interface AttachmentWithFile extends Attachment {
  blob?: Blob;
  name?: string;
}

export interface AttachmentCreation {
  objectId?: string;
  ownerId?: string;
  type?: string;
}

export interface AttachmentCreateByTypes {
  orderAfterFiles: AttachmentWithFile[];
  orderMatchedFiles: AttachmentWithFile[];
}
