import {
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import React, { ReactElement, useState } from 'react';

import { Card } from '@app/components/Product/company/Card';

interface ProductData {
  address: string;
  imageSrc: string;
  title: string;
}

interface ProductCardListProps {
  initialVisibleItems?: number;
  products: ProductData[];
}

export function ProductCardList({
  products,
  initialVisibleItems = 6,
}: ProductCardListProps): ReactElement {
  const theme = useTheme();
  const [visibleItems, setVisibleItems] = useState(initialVisibleItems);

  const loadMore = () => {
    setVisibleItems((prev) => prev + 6);
  };

  return (
    <Stack
      spacing={4}
      sx={{ mt: 5 }}
      justifyContent="center"
      alignItems="center"
    >
      <Stack
        px={2}
        direction="row"
        alignItems="center"
        width="100%"
        justifyContent="flex-start"
      >
        <Typography variant="h3" fontWeight="bold" mr={3}>
          住宅展示場一覧
        </Typography>
        <Typography variant="body3" color="textSecondary" mr={1}>
          エリア
        </Typography>
        <Box>
          <FormControl sx={{ height: '40px', width: '140px' }}>
            <Select
              value="全国"
              onChange={(event: SelectChangeEvent) => {
                console.log(event);
              }}
            >
              <MenuItem value="全国">全国</MenuItem>
              <MenuItem value="50㎡">50㎡</MenuItem>
              <MenuItem value="100㎡">100㎡</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Stack>
      <Grid container spacing={3} sx={{ mb: 5 }} justifyContent="center">
        {products.slice(0, visibleItems).map((data, index) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={4}
            key={index}
            display="flex"
            justifyContent="center"
          >
            <Card
              imageSrc={data.imageSrc}
              title={data.title}
              address={data.address}
            />
          </Grid>
        ))}
      </Grid>
      {visibleItems < products.length && (
        <Button
          variant="outlined"
          onClick={loadMore}
          sx={{
            borderColor: theme.palette.grey[300],
            borderRadius: 1,
            color: 'black !important',
            fontWeight: 'normal',
            px: 10,
            py: 2,
          }}
        >
          もっと見る
        </Button>
      )}
    </Stack>
  );
}
