import {
  Avatar,
  Box,
  Button,
  Divider,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import { ReactElement, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { resetResponseInterceptor } from '@app/adapter/axios';
import { useClearAuthStateAndStorage } from '@app/domain/app';
import { User } from '@app/types/user';

interface AccountInfoProps {
  user: User;
}

export function AccountInfo({ user }: AccountInfoProps): ReactElement {
  const navigate = useNavigate();
  const clearAuthStateAndStorage = useClearAuthStateAndStorage();

  const handleClickLogout = useCallback(() => {
    clearAuthStateAndStorage();
    resetResponseInterceptor();
    navigate('/login');
  }, [clearAuthStateAndStorage, navigate]);

  return (
    <Box>
      <Stack
        spacing={2}
        divider={<Divider flexItem />}
        sx={{ backgroundColor: 'white', p: 2 }}
      >
        <Stack direction="row" alignItems="center">
          <Stack spacing={1} width={1}>
            <Typography variant="body2">ドクター情報</Typography>
            <Stack direction="row" spacing={2} alignItems="center">
              <Avatar sx={{ height: 36, width: 36 }}>
                {user?.customFields?.familyName
                  ? user.customFields.familyName.substring(0, 1)
                  : ''}
              </Avatar>
              {user?.customFields && (
                <Typography>{`${user.customFields.familyName || '(未設定)'} ${
                  user.customFields.firstName || ''
                }`}</Typography>
              )}
            </Stack>
          </Stack>
          <Box>
            <Button
              color="inherit"
              variant="outlined"
              onClick={() => navigate('/profile/edit')}
            >
              <Typography variant="body2">変更</Typography>
            </Button>
          </Box>
        </Stack>
        <Stack direction="row" alignItems="center">
          <Stack spacing={1} width={1}>
            <Typography variant="body2">メールアドレス</Typography>
            <Typography>{user?.email || ''}</Typography>
          </Stack>
          <Box>
            <Button
              color="inherit"
              variant="outlined"
              onClick={() => navigate('/change-email')}
            >
              <Typography variant="body2">変更</Typography>
            </Button>
          </Box>
        </Stack>
        <Stack direction="row" alignItems="center">
          <Stack spacing={1} width={1}>
            <Typography variant="body2">パスワード</Typography>
            <Typography>・・・・・・・・・</Typography>
          </Stack>
          <Box>
            <Button
              color="inherit"
              variant="outlined"
              onClick={() => navigate('/change-password')}
            >
              <Typography variant="body2">変更</Typography>
            </Button>
          </Box>
        </Stack>
      </Stack>
      <Link
        component="button"
        color="inherit"
        sx={{ p: 1 }}
        onClick={handleClickLogout}
      >
        <Typography variant="body2">ログアウト</Typography>
      </Link>
    </Box>
  );
}
