import { Stack } from '@mui/material';
import { ReactElement } from 'react';
import { useLocation } from 'react-router-dom';

import { TAB_NAME } from './Index';

import { ProfileEdit } from '@app/components/Setting/ProfileEdit';
import { ProfileInfo } from '@app/components/Setting/ProfileInfo';

export function Profile(): ReactElement {
  const { pathname } = useLocation();
  return (
    <Stack sx={{ color: '#333', ml: 5 }}>
      {pathname === TAB_NAME.PROFILE && <ProfileInfo />}
      {pathname === TAB_NAME.PROFILE_EDIT && <ProfileEdit />}
    </Stack>
  );
}
