import { ReactElement, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

import { userAuthInfoSelector } from '@app/domain/app';

export function LineCallback(): ReactElement {
  const navigate = useNavigate();
  const location = useLocation();
  const setUserAuthInfo = useSetRecoilState(userAuthInfoSelector);

  useEffect(() => {
    if (location.pathname !== '/line/callback') {
      return;
    }

    const hash = location.hash.slice(1);
    const params = new URLSearchParams(hash);
    const userId = params.get('userId');
    const accessToken = params.get('accessToken');
    if (!userId || !accessToken) {
      // TODO: Do we need to display error messages?
      globalThis.location.replace('/login#line=failed');
      return;
    }

    // FIXME: resolve the hardcoding problem
    setUserAuthInfo({ accessToken, fingerPrint: 'fingerprint', userId });
    navigate('/');
  }, [setUserAuthInfo, navigate, location]);

  return <></>;
}
