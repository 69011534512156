import {
  AppBar,
  Box,
  Button,
  Card,
  Container,
  Hidden,
  Stack,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import React, { ReactElement, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import LINEImage from '@app/static/images/LINE.png';

/* 
  PUT every fake part for LINE in here as we'll eventually remove this file.
*/

function FakeForm() {
  const navigate = useNavigate();

  const handleSubmit = useCallback(() => {
    navigate('/line/callback');
  }, [navigate]);

  return (
    <>
      <Box textAlign="center" sx={{ my: 6 }}>
        <img src={LINEImage} alt="LINE" style={{ width: '5.5em' }} />
      </Box>
      <form onSubmit={handleSubmit}>
        <Stack direction="column" spacing={4} sx={{ mb: 4, px: 2 }}>
          <TextField
            margin="dense"
            fullWidth
            label="メールアドレス"
            variant="standard"
            sx={{
              '& .MuiInputLabel-root': { fontWeight: 700, opacity: 0.4 },
              '& .MuiInputLabel-root.Mui-focused': {
                color: '#00be00',
                fontWeight: 300,
                opacity: 1,
              },
              '& :after': { borderBottom: '2px solid #00be00 !important' },
              '& :before': { borderBottom: '2px solid #bec8d2 !important' },
            }}
          />
          <TextField
            margin="dense"
            fullWidth
            label="パスワード"
            variant="standard"
            sx={{
              '& .MuiInputLabel-root': { fontWeight: 700, opacity: 0.4 },
              '& .MuiInputLabel-root.Mui-focused': {
                color: '#00be00',
                fontWeight: 300,
                opacity: 1,
              },
              '& :after': { borderBottom: '2px solid #00be00 !important' },
              '& :before': { borderBottom: '2px solid #bec8d2 !important' },
            }}
          />
        </Stack>
        <Stack direction="column" spacing={2} sx={{ px: 2 }}>
          <Button
            color="neutral"
            type="submit"
            fullWidth
            variant="contained"
            sx={{ color: '#fff' }}
          >
            ログイン
          </Button>
          {/* NOTE: We don't need links, so let them just be visual things  */}
          <Typography
            textAlign="center"
            variant="caption"
            sx={{ color: '#469fd6' }}
          >
            メールアドレス・パスワードの確認はこちら
          </Typography>
          <Typography
            textAlign="center"
            variant="caption"
            sx={{ color: '#469fd6' }}
          >
            LINEアプリでログイン
          </Typography>
        </Stack>
      </form>
    </>
  );
}

export function MockLine(): ReactElement {
  return (
    <Container>
      <Hidden mdDown>
        <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
          <Card
            sx={{
              boxShadow: '0 6px 17px 0 rgb(0 0 0 / 10%)',
              maxWidth: 500,
              mt: 10,
              pb: 5,
              px: 5,
              width: 500,
            }}
          >
            <FakeForm />
          </Card>
        </Box>
        <AppBar
          position="fixed"
          sx={{ background: 'unset', bottom: 0, top: 'auto' }}
        >
          <Toolbar>
            <Typography
              variant="caption"
              sx={{ color: '#bec8d2', textAlign: 'center' }}
            >
              © LINE Corporation
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <Typography
              component="span"
              variant="caption"
              sx={{ color: '#6c7985' }}
            >
              プライバシーポリシー
            </Typography>
            <Typography
              component="span"
              variant="caption"
              sx={{ color: '#6c7985', ml: 5 }}
            >
              利用規約
            </Typography>
          </Toolbar>
        </AppBar>
      </Hidden>
      <Hidden mdUp>
        <FakeForm />
        <AppBar
          position="fixed"
          sx={{ background: 'unset', bottom: 0, top: 'auto' }}
        >
          <Stack
            justifyContent="center"
            direction="row"
            spacing={4}
            sx={{ mb: 3 }}
          >
            <Typography
              component="span"
              variant="caption"
              sx={{ color: '#6c7985', fontSize: '0.7em' }}
            >
              プライバシーポリシー
            </Typography>
            <Typography
              component="span"
              variant="caption"
              sx={{ color: '#6c7985', fontSize: '0.7em' }}
            >
              利用規約
            </Typography>
          </Stack>
          <Typography
            variant="caption"
            sx={{ color: '#bec8d2', mb: 4, textAlign: 'center' }}
          >
            <small>© LINE Corporation</small>
          </Typography>
        </AppBar>
      </Hidden>
      <style>
        {`
        .MuiAppBar-positionFixed {
          display: none;
        }
        `}
      </style>
    </Container>
  );
}
