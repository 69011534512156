import { LoadingButton } from '@mui/lab';
import {
  Box,
  Stack,
  Typography,
  Button,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { ReactElement, useState } from 'react';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { InquiryStepOneState, InquiryStepTwoState } from '@app/domain/order';
import { theme } from '@app/theme';
import {
  InquiryStepOneFormData,
  InquiryStepTwoFormData,
} from '@app/types/order';

interface DetailRowProps {
  label: string;
  value: string;
}

function DetailRow({ label, value }: DetailRowProps) {
  return (
    <Stack spacing={1} sx={{ mb: 3 }}>
      <Typography fontWeight="bold" variant="body1">
        {label}
      </Typography>
      {value
        ? value.split('\\n').map((v, index) => (
            <Typography
              variant="body1"
              sx={{ marginTop: '0!important' }}
              key={index}
              fontWeight="bold"
            >
              {v}
            </Typography>
          ))
        : '-'}
    </Stack>
  );
}

interface InReviewProps {
  isLoading: boolean;
  onBackToStep1: () => void;
  onBackToStep2: () => void;
  onSubmit: (data: InquiryStepOneFormData & InquiryStepTwoFormData) => void;
}

export function Confirm({
  onSubmit,
  onBackToStep1,
  onBackToStep2,
  isLoading,
}: InReviewProps): ReactElement {
  const step1Data = useRecoilValue(InquiryStepOneState);
  const step2Data = useRecoilValue(InquiryStepTwoState);

  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

  const combinedData = {
    ...step1Data,
    ...step2Data,
  } as InquiryStepOneFormData & InquiryStepTwoFormData;

  const handleBackToStep1 = () => {
    onBackToStep1();
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsCheckboxChecked(event.target.checked);
  };

  return (
    <Stack
      spacing={5}
      alignItems="center"
      sx={{
        margin: '0 auto',
        width: '100%',
      }}
    >
      <Box
        sx={{
          backgroundColor: theme.palette.neutral.redLight,
          border: `1px solid ${theme.palette.error.main}`,
          borderRadius: 1,
          color: theme.palette.error.main,
          margin: '0 auto',
          maxWidth: '800px',
          padding: 3,
          width: '100%',
        }}
      >
        <Typography variant="body1" fontWeight="bold">
          予約はまだ完了していません。
        </Typography>
        <Typography variant="body1" fontWeight="bold">
          内容をご確認の上「予約する」ボタンを押してください。
        </Typography>
      </Box>
      <Box
        sx={{
          border: `1px solid ${theme.palette.neutral.silver}`,
          borderRadius: 1,
          margin: '0 auto',
          maxWidth: '800px',
          p: 6,
          width: '100%',
        }}
      >
        <DetailRow label="来場したい住宅展示場の都道府県" value="東京都" />
        <DetailRow label="名前" value="山田太郎" />
        <DetailRow label="名前" value="山田太郎" />
        <DetailRow label="フリガナ" value="ヤマダタロウ" />
        <DetailRow label="生年月日" value="2000年1月1日 (公開)" />
        <DetailRow label="年齢" value="40歳" />
        <DetailRow label="電話番号" value="090-1111-1111" />
        <DetailRow label="メールアドレス" value="email@sample.com" />
        <DetailRow
          label="住所"
          value="〒000-0000 \n 大阪府大阪市阿倍野区阪南町1-1-1 \n 〇〇〇〇マンション1号室"
        />
        <Button onClick={handleBackToStep1}>← 修正する</Button>
      </Box>
      <Stack alignItems="center" sx={{ width: '100%' }}>
        <Box
          sx={{
            backgroundColor: theme.palette.grey[100],
            border: `1px solid ${theme.palette.grey[300]}`,
            borderRadius: 1,
            fontWeight: 'bold',
            margin: '0 auto',
            maxWidth: '800px',
            padding: 3,
            width: '100%',
          }}
        >
          <Typography variant="body2" fontWeight="bold">
            【携帯メールアドレスのドメイン指定受信に関するお願い】
          </Typography>
          <Typography variant="body2" fontWeight="bold">
            メールアドレスを登録された方には、自動で確認メールを送信しております。メールアドレスの誤入力、携帯電話のドメイン指定受信の設定などにより確認メールを受信出来ない場合がございます。迷惑メールなどに入っている場合もございますので、再度受信設定をご確認ください。確認メールが届かない場合は、当社からの返信を受信出来ない可能性がございますので、お手数ですが、メール以外の連絡方法を追記の上、再度お問い合わせいただきますようお願い致します。
          </Typography>
          <Typography variant="body2" fontWeight="bold" sx={{ mt: 2 }}>
            【大型連休前のお申込みについて】
          </Typography>
          <Typography variant="body2" fontWeight="bold">
            年末年始、お盆休み、その他大型連休前にお申込みを頂いた場合、見学予約日程の確定までに少々お時間を頂くことがございます。
          </Typography>
        </Box>
        <FormControlLabel
          control={
            <Checkbox
              checked={isCheckboxChecked}
              onChange={handleCheckboxChange}
            />
          }
          sx={{ my: 5 }}
          label={
            <>
              <Link
                target="_blank"
                rel="noopener noreferrer"
                to="#"
                style={{ textDecoration: 'none' }}
              >
                <Typography
                  component="span"
                  color="primary"
                  variant="body2"
                  fontWeight="bold"
                >
                  プライバシーポリシー
                </Typography>
              </Link>
              <Typography component="span" variant="body2" fontWeight="bold">
                プライバシーポリシー
              </Typography>
            </>
          }
        />
        <Typography
          variant="body3"
          color={theme.palette.text.secondary}
          fontWeight="bold"
        >
          データを送信される際の情報はSSL暗号化通信により保護されますので安心してご利用ください
        </Typography>
      </Stack>

      <Stack
        direction="row"
        spacing={2}
        sx={{ maxWidth: '800px', pb: 20, pt: 10, width: '100%' }}
      >
        <Button
          type="button"
          variant="outlined"
          color="secondary"
          onClick={onBackToStep1}
          sx={{ flex: 1, p: 2 }}
        >
          戻る
        </Button>
        <LoadingButton
          type="button"
          variant="contained"
          color="primary"
          onClick={() => onSubmit(combinedData)}
          sx={{ flex: 1 }}
          disabled={!isCheckboxChecked}
          loading={isLoading}
        >
          次へ
        </LoadingButton>
      </Stack>
    </Stack>
  );
}
