import { ReactElement } from 'react';
import { useRecoilState } from 'recoil';

import { ProductDetailInfo } from '@app/components/Product/company/ProductDetailInfo';
import { BottomMenu } from '@app/components/Shared/BottomMenu';
import { Breadcrumb } from '@app/components/Shared/Breadcrumb';
import { HeadBlock } from '@app/components/Shared/HeadBlock';
import { searchResultProductsState } from '@app/domain/catalog';
import { BOTTOM_MENU_ITEMS } from '@app/static/constants';
import { Product } from '@app/types/catalog';

const breadcrumbItems = [
  { label: '住宅展示場検索結果', link: '/exhibitions' },
  { label: '住宅展示場詳細' },
];

export function Index(): ReactElement {
  const [resultProducts] = useRecoilState(searchResultProductsState);
  const product: Product = resultProducts.data[0];
  return (
    <>
      <HeadBlock />
      <Breadcrumb items={breadcrumbItems} />
      <ProductDetailInfo product={product} />
      <BottomMenu menuItems={BOTTOM_MENU_ITEMS} />
    </>
  );
}
