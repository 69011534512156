import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { ReactElement } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { UserUpdateForm } from '@app/schemas/user';
import { PREFECTURE_OPTIONS } from '@app/static/constants';
import { theme } from '@app/theme';
import { UserUpdate } from '@app/types/user';
const genderOptions = ['男性', '女性'] as const;
const yearOptions = () => {
  const currentYear = new Date().getFullYear();
  return Array.from({ length: 101 }, (i, index) => currentYear - index);
};
const formLabel = (label: string, require?: boolean): ReactElement => {
  return (
    <FormLabel sx={{ mb: 1 }}>
      <Typography variant="body2">
        {label}
        {require && (
          <Typography
            component="span"
            color="white"
            variant="h5"
            sx={{
              backgroundColor: '#ee6363',
              borderRadius: 1,
              ml: 0.5,
              px: 0.5,
            }}
          >
            必須
          </Typography>
        )}
      </Typography>
    </FormLabel>
  );
};
export function ProfileEdit(): ReactElement {
  const { control, handleSubmit } = useForm<UserUpdate>({
    defaultValues: UserUpdateForm.defaultValues,
    mode: 'all',
    resolver: UserUpdateForm.resolver,
  });
  const onSubmit = (data: UserUpdate) => {
    console.log(data);
  };
  return (
    <>
      <Typography variant="h3" fontWeight="bold" mb={4}>
        個人情報編集
      </Typography>
      <Stack
        spacing={3}
        sx={{
          border: 1,
          borderColor: theme.palette.grey[400],
          borderRadius: 1,
          p: 3,
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Stack direction="row" spacing={2} width={{ xs: '50%' }}>
                <Stack>
                  {formLabel('姓', true)}
                  <Controller
                    name="customFields.familyName"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        error={!!error}
                        helperText={error?.message}
                        margin="dense"
                        placeholder={'姓を入力'}
                        fullWidth
                      />
                    )}
                  />
                </Stack>
                <Stack>
                  {formLabel('名', true)}
                  <Controller
                    name="customFields.firstName"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        error={!!error}
                        helperText={error?.message}
                        margin="dense"
                        placeholder={'名を入力'}
                        fullWidth
                      />
                    )}
                  />
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack direction="row" spacing={2} width={{ xs: '50%' }}>
                <Stack>
                  {formLabel('姓（かな）', true)}
                  <Controller
                    name="customFields.familyNameKana"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        error={!!error}
                        helperText={error?.message}
                        margin="dense"
                        placeholder={'姓（かな）を入力'}
                        fullWidth
                      />
                    )}
                  />
                </Stack>
                <Stack>
                  {formLabel('名（かな）', true)}
                  <Controller
                    name="customFields.firstNameKana"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        error={!!error}
                        helperText={error?.message}
                        margin="dense"
                        placeholder={'名（かな）を入力'}
                        fullWidth
                      />
                    )}
                  />
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack width={{ xs: '50%' }}>
                {formLabel('性別', true)}
                <Controller
                  name="customFields.gender"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <FormControl error={!!error} fullWidth>
                      <Select
                        {...field}
                        margin="dense"
                        error={!!error}
                        displayEmpty
                        renderValue={(selected) => {
                          if (selected.length === 0) {
                            return (
                              <Typography style={{ color: '#ccc ' }}>
                                選択する
                              </Typography>
                            );
                          }
                        }}
                      >
                        {genderOptions.map((value, index) => (
                          <MenuItem key={index} value={value}>
                            {value}
                          </MenuItem>
                        ))}
                      </Select>
                      {!!error && (
                        <FormHelperText>{error?.message}</FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack width={{ xs: '36%' }}>
                {formLabel('生年月日', true)}
                <Stack direction="row" spacing={1} alignItems="center">
                  <Controller
                    name="customFields.birthday"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <Select
                        {...field}
                        value={
                          field.value
                            ? String(new Date(field.value).getFullYear())
                            : ''
                        }
                        margin="dense"
                        error={!!error}
                        fullWidth
                        onChange={(e) => {
                          if (field.value) {
                            const birthday = new Date(field.value);
                            birthday.setFullYear(Number(e.target.value));
                            field.onChange(birthday.toISOString());
                          }
                        }}
                      >
                        {yearOptions().map((value, index) => (
                          <MenuItem key={index} value={value}>
                            {value}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                  <Typography>年</Typography>
                  <Controller
                    name="customFields.birthday"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <Select
                        {...field}
                        value={
                          field.value
                            ? String(new Date(field.value).getMonth())
                            : ''
                        }
                        margin="dense"
                        error={!!error}
                        fullWidth
                        onChange={(e) => {
                          if (field.value) {
                            const birthday = new Date(field.value);
                            birthday.setMonth(Number(e.target.value));
                            field.onChange(birthday.toISOString());
                          }
                        }}
                      >
                        {Array.from({ length: 12 }).map((i, index) => (
                          <MenuItem key={index} value={index}>
                            {index + 1}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                  <Typography>月</Typography>
                  <Controller
                    name="customFields.birthday"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <Select
                        {...field}
                        value={
                          field.value
                            ? String(new Date(field.value).getDate())
                            : ''
                        }
                        margin="dense"
                        error={!!error}
                        fullWidth
                        onChange={(e) => {
                          if (field.value) {
                            const birthday = new Date(field.value);
                            birthday.setDate(Number(e.target.value));
                            field.onChange(birthday.toISOString());
                          }
                        }}
                      >
                        {Array.from({ length: 31 }).map((i, index) => (
                          <MenuItem key={index} value={index + 1}>
                            {index + 1}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                  <Typography>日</Typography>
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack>
                {formLabel('住所', true)}
                <Stack spacing={1}>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Typography variant="body2">〒</Typography>
                    <Controller
                      name="customFields.postalCode"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          error={!!error}
                          helperText={error?.message}
                          margin="dense"
                          placeholder={'1000001'}
                          sx={{ width: '160px' }}
                        />
                      )}
                    />
                    <Button
                      color="inherit"
                      variant="text"
                      sx={{
                        border: 1,
                        borderColor: theme.palette.grey[400],
                        borderRadius: 1,
                        fontSize: '12px',
                        height: '32px',
                        p: 1,
                      }}
                    >
                      住所を検索
                    </Button>
                  </Stack>
                  <Controller
                    name="addressLine1"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <Select
                        {...field}
                        margin="dense"
                        displayEmpty
                        sx={{ width: '182px' }}
                        renderValue={(selected) => {
                          if (selected.length === 0) {
                            return (
                              <Typography style={{ color: '#ccc ' }}>
                                都道府県
                              </Typography>
                            );
                          }
                        }}
                      >
                        {PREFECTURE_OPTIONS.map((value, index) => (
                          <MenuItem key={index} value={value}>
                            {value}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                  <Controller
                    name="addressLine1"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <Select
                        {...field}
                        margin="dense"
                        displayEmpty
                        sx={{ width: '182px' }}
                        renderValue={(selected) => {
                          if (selected.length === 0) {
                            return (
                              <Typography style={{ color: '#ccc ' }}>
                                市区町村
                              </Typography>
                            );
                          }
                          return selected;
                        }}
                      >
                        {PREFECTURE_OPTIONS.map((value, index) => (
                          <MenuItem key={index} value={value}>
                            {value}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                  <Controller
                    name="addressLine3"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        error={!!error}
                        helperText={error?.message}
                        margin="dense"
                        placeholder={'町名・番地'}
                        sx={{ width: '50%' }}
                      />
                    )}
                  />
                  <Controller
                    name="customFields.addressLine4"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        error={!!error}
                        helperText={error?.message}
                        margin="dense"
                        placeholder={'建物名・部屋番号'}
                        sx={{ width: '50%' }}
                      />
                    )}
                  />
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack>
                {formLabel('連絡先電話番号', true)}
                <Controller
                  name="phoneNumber"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      type="tel"
                      error={!!error}
                      helperText={error?.message}
                      margin="dense"
                      placeholder="09012345678"
                      sx={{ width: '50%' }}
                    />
                  )}
                />
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack sx={{ width: '50%' }}>
                {formLabel('職業', false)}
                <Controller
                  name="customFields.medicalLicenseYear"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <FormControl error={!!error} fullWidth>
                      <Select
                        {...field}
                        margin="dense"
                        displayEmpty
                        renderValue={(selected) => {
                          if (!selected) {
                            return (
                              <Typography style={{ color: '#ccc ' }}>
                                選択する
                              </Typography>
                            );
                          }
                          return selected;
                        }}
                      >
                        <MenuItem value={0}></MenuItem>
                        {yearOptions().map((value, index) => (
                          <MenuItem key={index} value={value}>
                            {value}
                          </MenuItem>
                        ))}
                      </Select>
                      {!!error && (
                        <FormHelperText>{error?.message}</FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack sx={{ width: '50%' }}>
                {formLabel('勤務先', false)}
                <Controller
                  name="customFields.medicalRegisterNumber"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      error={!!error}
                      helperText={error?.message}
                      margin="dense"
                      placeholder="〇〇商事"
                      fullWidth
                    />
                  )}
                />
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack>
                {formLabel('世帯年収', false)}
                <Stack spacing={1}>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Controller
                      name="customFields.postalCode"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          error={!!error}
                          helperText={error?.message}
                          margin="dense"
                          placeholder={'1000'}
                          sx={{ width: '80px' }}
                        />
                      )}
                    />
                    <Typography variant="body2">万円</Typography>
                  </Stack>
                  <Controller
                    name="addressLine1"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <Select
                        {...field}
                        margin="dense"
                        displayEmpty
                        sx={{ width: '182px' }}
                        renderValue={(selected) => {
                          if (selected.length === 0) {
                            return (
                              <Typography style={{ color: '#ccc ' }}>
                                都道府県
                              </Typography>
                            );
                          }
                        }}
                      >
                        {PREFECTURE_OPTIONS.map((value, index) => (
                          <MenuItem key={index} value={value}>
                            {value}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack>
                {formLabel('家族・世帯構成', false)}
                <Stack>
                  <FormControlLabel
                    label={'配偶者'}
                    control={
                      <Controller
                        name="customFields.confidences"
                        control={control}
                        render={({ field }) => (
                          <Checkbox {...field} checked={false} sx={{ py: 0 }} />
                        )}
                      />
                    }
                  />
                  <FormControlLabel
                    label={'お子様'}
                    control={
                      <Controller
                        name="customFields.confidences"
                        control={control}
                        render={({ field }) => (
                          <Checkbox {...field} checked={true} sx={{ py: 0 }} />
                        )}
                      />
                    }
                  />
                </Stack>
                <Stack direction="row" spacing={1} alignItems="center" mt={1}>
                  <Controller
                    name="customFields.postalCode"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        error={!!error}
                        helperText={error?.message}
                        margin="dense"
                        placeholder={'2'}
                        sx={{ width: '80px' }}
                      />
                    )}
                  />
                  <Typography variant="body2">人</Typography>
                </Stack>
              </Stack>
              <Stack direction="row" spacing={1} alignItems="center" mt={1}>
                <Controller
                  name="customFields.postalCode"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      error={!!error}
                      helperText={error?.message}
                      margin="dense"
                      placeholder={'その他'}
                    />
                  )}
                />
              </Stack>
            </Grid>
          </Grid>
        </form>
      </Stack>
    </>
  );
}
