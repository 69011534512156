import { LoadingButton } from '@mui/lab';
import {
  FormLabel,
  TextField,
  Typography,
  Stack,
  FormControl,
  Paper,
  Alert,
} from '@mui/material';
import { ReactElement, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { resetPassword } from '@app/adapter/user-service';
import { HeadBlock } from '@app/components/Shared/HeadBlock';
import { useSetSnackbar } from '@app/hooks/useSetSnackbar';
import { PasswordResetForm, PasswordResetFormData } from '@app/schemas/user';
import { theme } from '@app/theme';
import { isError } from '@app/utils/error';

export function ChangePassword(): ReactElement {
  const navigate = useNavigate();
  const setSnackbar = useSetSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [mainErrorMessage, setMainErrorMessage] = useState<string>();

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<PasswordResetFormData>(PasswordResetForm);

  const handleSubmitForm = async (formData: PasswordResetFormData) => {
    setIsLoading(true);
    setMainErrorMessage('');
    try {
      const token =
        new URLSearchParams(globalThis.location.search).get('token') || '';
      await resetPassword(formData.password, token);
      navigate('/password/reset/complete');
    } catch (error) {
      if (isError(error)) {
        setSnackbar(true, 'パスワードの登録に失敗しました', 'error');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <HeadBlock />
      <Stack spacing={3} alignItems="center" mt={5} px={4}>
        <Typography fontSize={24} fontWeight={700}>
          パスワード再設定
        </Typography>
        <Typography color="text.gray">
          新しいパスワードを入力して下さい
        </Typography>
        <form onSubmit={handleSubmit(handleSubmitForm)}>
          <Stack spacing={3} alignItems="center" width={480}>
            {mainErrorMessage && (
              <Alert
                severity="error"
                icon={false}
                sx={{ border: `1px solid ${theme.palette.error.main}` }}
              >
                {mainErrorMessage}
              </Alert>
            )}
            <Paper variant="outlined" sx={{ p: 3, width: 1 }}>
              <Stack spacing={4}>
                <FormControl fullWidth>
                  <FormLabel focused={false}>新しいパスワード</FormLabel>
                  <Controller
                    name="password"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        type="password"
                        size="small"
                        error={!!error}
                        helperText={error?.message}
                        placeholder="パスワードを入力"
                      />
                    )}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <FormLabel focused={false}>
                    新しいパスワード（確認）
                  </FormLabel>
                  <Controller
                    name="passwordConfirm"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        type="password"
                        size="small"
                        error={!!error}
                        helperText={error?.message}
                        placeholder="同じパスワードを入力"
                      />
                    )}
                  />
                </FormControl>
                <LoadingButton
                  type="submit"
                  color="primary"
                  variant="contained"
                  loading={isLoading}
                  disabled={!isValid}
                  fullWidth
                >
                  送信
                </LoadingButton>
              </Stack>
            </Paper>
          </Stack>
        </form>
      </Stack>
    </>
  );
}
