import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Box,
  Button,
  Typography,
  useTheme,
  Card,
  CardContent,
  CardActions,
  Divider,
  FormControlLabel,
  Checkbox,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  MenuItem,
  Select,
  SelectChangeEvent,
  FormControl,
  Stack,
} from '@mui/material';
import { ReactElement, useState, useCallback, useEffect } from 'react';
import { useRecoilValue } from 'recoil';

import { getOrganizations } from '@app/adapter/organization-service';
import { locationsTreeSelector } from '@app/domain/catalog';
import { ROOM_TYPE_OPTIONS } from '@app/static/constants';
import { Organization } from '@app/types/organization';

interface SearchConditionProps {
  condition?: Conditions;
  onSearch: (conditions: Conditions) => void;
  showPropertySearch: boolean;
}

export interface Conditions {
  access?: string;
  floorPlanRooms?: number[];
  locationIds: string[];
  maxBuildingArea?: string;
  maxLandArea?: string;
  maxPrice?: string;
  minBuildingArea?: string;
  minLandArea?: string;
  minPrice?: string;
  organizationId: string[];
}

const defaultCondition: Conditions = {
  locationIds: [],
  organizationId: [],
};

export function SearchCondition({
  condition = defaultCondition,
  onSearch,
  showPropertySearch,
}: SearchConditionProps): ReactElement {
  const theme = useTheme();
  const locations = useRecoilValue(locationsTreeSelector);
  const [selectedLocations, setSelectedLocations] = useState<string[]>(
    condition.locationIds
  );
  const [selectedCompanies, setSelectedCompanies] = useState<string[]>(
    condition.organizationId
  );

  // 分譲関連のState
  const [minPrice, setPriceMin] = useState<string>(
    condition.minPrice || '下限なし'
  );
  const [maxPrice, setPriceMax] = useState<string>(
    condition.maxPrice || '上限なし'
  );
  const [minLandArea, setLandAreaMin] = useState<string>(
    condition.minLandArea || '下限なし'
  );
  const [maxLandArea, setLandAreaMax] = useState<string>(
    condition.maxLandArea || '上限なし'
  );
  const [minBuildingArea, setBuildingAreaMin] = useState<string>(
    condition.minBuildingArea || '下限なし'
  );
  const [maxBuildingArea, setBuildingAreaMax] = useState<string>(
    condition.maxBuildingArea || '上限なし'
  );
  const [access, setAccess] = useState<string>(condition.access || '指定なし');

  const [floorPlanRooms, setFloorPlanRooms] = useState<number[]>(
    condition.floorPlanRooms ?? []
  );
  const [organizations, setOrganizations] = useState<Organization[]>([]);

  const fetchOrganizations = useCallback(async () => {
    try {
      const response = await getOrganizations();
      setOrganizations(response.data.value);
    } catch (error) {
      console.error('Error fetching organizations:', error);
    }
  }, []);

  useEffect(() => {
    void fetchOrganizations();
  }, [fetchOrganizations]);

  const handleLocationChange = useCallback(
    (id: string, childrenIds: string[] = []) => {
      setSelectedLocations((prev) => {
        if (prev.includes(id)) {
          return prev.filter(
            (locationId) =>
              locationId !== id && !childrenIds.includes(locationId)
          );
        }
        return [...prev, id, ...childrenIds];
      });
    },
    []
  );

  const handleCompanyChange = (id: string) => {
    setSelectedCompanies((prev) => {
      if (prev.includes(id)) {
        return prev.filter((companyId) => companyId !== id);
      }
      return [...prev, id];
    });
  };

  const clearAllConditions = () => {
    setSelectedLocations([]);
    setSelectedCompanies([]);
    setFloorPlanRooms([]);
    setPriceMin('下限なし');
    setPriceMax('上限なし');
    setLandAreaMin('下限なし');
    setLandAreaMax('上限なし');
    setBuildingAreaMin('下限なし');
    setBuildingAreaMax('上限なし');
    setAccess('指定なし');
  };

  const handleRoomTypeChange = (value: number) => {
    setFloorPlanRooms((prev) => {
      if (prev.includes(value)) {
        return prev.filter((t) => t !== value);
      }
      return [...prev, value];
    });
  };

  return (
    <Card
      sx={{
        border: '1px solid',
        borderColor: theme.palette.divider,
        borderRadius: '16px',
        m: 2,
        maxWidth: 300,
        ml: 4,
      }}
    >
      <CardContent sx={{ padding: 0 }}>
        <Typography
          variant="subtitle1"
          component="div"
          textAlign="center"
          color={theme.palette.primary.main}
          py={1}
          sx={{ backgroundColor: '#e3f2fd', fontWeight: 'bold' }}
        >
          検索条件
        </Typography>
        <Divider />
        <Box sx={{ m: 2 }}>
          <Typography
            variant="subtitle1"
            gutterBottom
            sx={{ fontWeight: 'bold', pt: 1 }}
          >
            建築エリアを選ぶ
          </Typography>
          {!!locations.length &&
            locations.map((region) => (
              <Accordion
                key={region.id}
                TransitionProps={{ unmountOnExit: true }}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Stack direction="row" alignItems="center">
                    <Checkbox
                      value={region.id}
                      checked={
                        selectedLocations.includes(region.id) &&
                        region.children?.every((child) =>
                          selectedLocations.includes(child.id)
                        )
                      }
                      indeterminate={
                        !selectedLocations.includes(region.id) &&
                        region.children?.some((child) =>
                          selectedLocations.includes(child.id)
                        )
                      }
                      onClick={(e) => e.stopPropagation()}
                      onChange={() =>
                        handleLocationChange(
                          region.id,
                          region.children
                            ? region.children.map((child) => child.id)
                            : []
                        )
                      }
                    />
                    <Typography>{region.name}</Typography>
                  </Stack>
                </AccordionSummary>
                <AccordionDetails sx={{ px: 5, py: 1 }}>
                  <Stack>
                    {region.children &&
                      region.children.map((prefecture) => (
                        <FormControlLabel
                          key={prefecture.id}
                          label={prefecture.name}
                          control={
                            <Checkbox
                              value={prefecture.id}
                              checked={selectedLocations.includes(
                                prefecture.id
                              )}
                              onChange={() =>
                                handleLocationChange(prefecture.id)
                              }
                            />
                          }
                        />
                      ))}
                  </Stack>
                </AccordionDetails>
              </Accordion>
            ))}
          {!showPropertySearch && (
            <>
              <Typography
                variant="subtitle1"
                gutterBottom
                sx={{ fontWeight: 'bold', pt: 1 }}
              >
                企業を選ぶ
              </Typography>
              <Stack sx={{ flexDirection: 'column', ml: 3 }}>
                {organizations.slice(0, 10).map((organization) => (
                  <FormControlLabel
                    key={organization.id}
                    control={
                      <Checkbox
                        checked={selectedCompanies.includes(organization.id)}
                        onChange={() => handleCompanyChange(organization.id)}
                      />
                    }
                    label={organization.name}
                  />
                ))}
              </Stack>
            </>
          )}
          {showPropertySearch && (
            <>
              <Typography
                variant="subtitle1"
                gutterBottom
                sx={{ fontWeight: 'bold', pt: 1 }}
              >
                価格
              </Typography>
              <Stack
                direction="row"
                sx={{
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  mb: 2,
                }}
              >
                <FormControl fullWidth>
                  <Select
                    value={minPrice}
                    onChange={(event: SelectChangeEvent) =>
                      setPriceMin(event.target.value as string)
                    }
                  >
                    <MenuItem value="下限なし">下限なし</MenuItem>
                    <MenuItem value="500">500万円以上</MenuItem>
                    <MenuItem value="1000">1000万円以上</MenuItem>
                    <MenuItem value="1500">1500万円以上</MenuItem>
                    <MenuItem value="2000">2000万円以上</MenuItem>
                    <MenuItem value="2500">2500万円以上</MenuItem>
                    <MenuItem value="3000">3000万円以上</MenuItem>
                    <MenuItem value="3500">3500万円以上</MenuItem>
                    <MenuItem value="4000">4000万円以上</MenuItem>
                    <MenuItem value="4500">4500万円以上</MenuItem>
                    <MenuItem value="5000">5000万円以上</MenuItem>
                    <MenuItem value="5500">5500万円以上</MenuItem>
                    <MenuItem value="6000">6000万円以上</MenuItem>
                    <MenuItem value="6500">6500万円以上</MenuItem>
                    <MenuItem value="7000">7000万円以上</MenuItem>
                    <MenuItem value="7500">7500万円以上</MenuItem>
                    <MenuItem value="8000">8000万円以上</MenuItem>
                    <MenuItem value="8500">8500万円以上</MenuItem>
                    <MenuItem value="9000">9000万円以上</MenuItem>
                    <MenuItem value="9500">9500万円以上</MenuItem>
                    <MenuItem value="10000">1億円以上</MenuItem>
                  </Select>
                </FormControl>
                <Typography sx={{ mx: 1 }}>~</Typography>
                <FormControl fullWidth>
                  <Select
                    value={maxPrice}
                    onChange={(event: SelectChangeEvent) =>
                      setPriceMax(event.target.value as string)
                    }
                  >
                    <MenuItem value="上限なし">上限なし</MenuItem>
                    <MenuItem value="500">500万円未満</MenuItem>
                    <MenuItem value="1000">1000万円未満</MenuItem>
                    <MenuItem value="1500">1500万円未満</MenuItem>
                    <MenuItem value="2000">2000万円未満</MenuItem>
                    <MenuItem value="2500">2500万円未満</MenuItem>
                    <MenuItem value="3000">3000万円未満</MenuItem>
                    <MenuItem value="3500">3500万円未満</MenuItem>
                    <MenuItem value="4000">4000万円未満</MenuItem>
                    <MenuItem value="4500">4500万円未満</MenuItem>
                    <MenuItem value="5000">5000万円未満</MenuItem>
                    <MenuItem value="5500">5500万円未満</MenuItem>
                    <MenuItem value="6000">6000万円未満</MenuItem>
                    <MenuItem value="6500">6500万円未満</MenuItem>
                    <MenuItem value="7000">7000万円未満</MenuItem>
                    <MenuItem value="7500">7500万円未満</MenuItem>
                    <MenuItem value="8000">8000万円未満</MenuItem>
                    <MenuItem value="8500">8500万円未満</MenuItem>
                    <MenuItem value="9000">9000万円未満</MenuItem>
                    <MenuItem value="9500">9500万円未満</MenuItem>
                    <MenuItem value="10000">1億円未満</MenuItem>
                  </Select>
                </FormControl>
              </Stack>
              <Typography
                variant="subtitle1"
                gutterBottom
                sx={{ fontWeight: 'bold', pt: 1 }}
              >
                土地面積
              </Typography>
              <Stack
                direction="row"
                sx={{
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  mb: 2,
                }}
              >
                <FormControl fullWidth>
                  <Select
                    value={minLandArea}
                    onChange={(event: SelectChangeEvent) =>
                      setLandAreaMin(event.target.value as string)
                    }
                  >
                    <MenuItem value="下限なし">下限なし</MenuItem>
                    <MenuItem value="40">40㎡以上</MenuItem>
                    <MenuItem value="50">50㎡以上</MenuItem>
                    <MenuItem value="60">60㎡以上</MenuItem>
                    <MenuItem value="70">70㎡以上</MenuItem>
                    <MenuItem value="80">80㎡以上</MenuItem>
                    <MenuItem value="90">90㎡以上</MenuItem>
                    <MenuItem value="100">100㎡以上</MenuItem>
                  </Select>
                </FormControl>
                <Typography sx={{ mx: 1 }}>~</Typography>
                <FormControl fullWidth>
                  <Select
                    value={maxLandArea}
                    onChange={(event: SelectChangeEvent) =>
                      setLandAreaMax(event.target.value as string)
                    }
                  >
                    <MenuItem value="上限なし">上限なし</MenuItem>
                    <MenuItem value="40">40㎡未満</MenuItem>
                    <MenuItem value="50">50㎡未満</MenuItem>
                    <MenuItem value="60">60㎡未満</MenuItem>
                    <MenuItem value="70">70㎡未満</MenuItem>
                    <MenuItem value="80">80㎡未満</MenuItem>
                    <MenuItem value="90">90㎡未満</MenuItem>
                    <MenuItem value="100">100㎡未満</MenuItem>
                  </Select>
                </FormControl>
              </Stack>
              <Typography
                variant="subtitle1"
                gutterBottom
                sx={{ fontWeight: 'bold', pt: 1 }}
              >
                建物面積
              </Typography>
              <Stack
                direction="row"
                sx={{
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  mb: 2,
                }}
              >
                <FormControl fullWidth>
                  <Select
                    value={minBuildingArea}
                    onChange={(event: SelectChangeEvent) =>
                      setBuildingAreaMin(event.target.value as string)
                    }
                  >
                    <MenuItem value="下限なし">下限なし</MenuItem>
                    <MenuItem value="40">40㎡以上</MenuItem>
                    <MenuItem value="50">50㎡以上</MenuItem>
                    <MenuItem value="60">60㎡以上</MenuItem>
                    <MenuItem value="70">70㎡以上</MenuItem>
                    <MenuItem value="80">80㎡以上</MenuItem>
                    <MenuItem value="90">90㎡以上</MenuItem>
                    <MenuItem value="100">100㎡以上</MenuItem>
                  </Select>
                </FormControl>
                <Typography sx={{ mx: 1 }}>~</Typography>
                <FormControl fullWidth>
                  <Select
                    value={maxBuildingArea}
                    onChange={(event: SelectChangeEvent) =>
                      setBuildingAreaMax(event.target.value as string)
                    }
                  >
                    <MenuItem value="上限なし">上限なし</MenuItem>
                    <MenuItem value="40">40㎡未満</MenuItem>
                    <MenuItem value="50">50㎡未満</MenuItem>
                    <MenuItem value="60">60㎡未満</MenuItem>
                    <MenuItem value="70">70㎡未満</MenuItem>
                    <MenuItem value="80">80㎡未満</MenuItem>
                    <MenuItem value="90">90㎡未満</MenuItem>
                    <MenuItem value="100">100㎡未満</MenuItem>
                  </Select>
                </FormControl>
              </Stack>
              <Typography
                variant="subtitle1"
                gutterBottom
                sx={{ fontWeight: 'bold', pt: 1 }}
              >
                間取り
              </Typography>
              <Stack direction="row" flexWrap="wrap" sx={{ mb: 2 }}>
                {ROOM_TYPE_OPTIONS.map((type) => (
                  <FormControlLabel
                    key={type.label}
                    control={
                      <Checkbox
                        checked={floorPlanRooms.includes(type.value)}
                        onChange={() => handleRoomTypeChange(type.value)}
                      />
                    }
                    label={type.label}
                    sx={{ margin: 0, width: '50%' }}
                  />
                ))}
              </Stack>
              <Typography
                variant="subtitle1"
                gutterBottom
                sx={{ fontWeight: 'bold', pt: 1 }}
              >
                駅徒歩分
              </Typography>
              <FormControl fullWidth>
                <Select
                  value={access}
                  onChange={(event: SelectChangeEvent) =>
                    setAccess(event.target.value as string)
                  }
                >
                  <MenuItem value="指定なし">指定なし</MenuItem>
                  <MenuItem value="5分">5分以内</MenuItem>
                  <MenuItem value="10分">10分以内</MenuItem>
                  <MenuItem value="15分">15分以内</MenuItem>
                  <MenuItem value="20分">20分以内</MenuItem>
                </Select>
              </FormControl>
            </>
          )}
        </Box>
      </CardContent>
      <CardActions
        sx={{ backgroundColor: '#e3f2fd', justifyContent: 'center', py: 3 }}
      >
        <Box textAlign="center" width="100%">
          <Button
            size="large"
            fullWidth
            variant="contained"
            color="primary"
            onClick={() =>
              onSearch({
                ...condition,
                access: access === '指定なし' ? undefined : access,
                floorPlanRooms:
                  floorPlanRooms.length > 0 ? floorPlanRooms : undefined,
                locationIds: selectedLocations,
                maxBuildingArea:
                  maxBuildingArea === '上限なし' ? undefined : maxBuildingArea,
                maxLandArea:
                  maxLandArea === '上限なし' ? undefined : maxLandArea,
                maxPrice: maxPrice === '上限なし' ? undefined : maxPrice,
                minBuildingArea:
                  minBuildingArea === '下限なし' ? undefined : minBuildingArea,
                minLandArea:
                  minLandArea === '下限なし' ? undefined : minLandArea,
                minPrice: minPrice === '下限なし' ? undefined : minPrice,
                organizationId: selectedCompanies,
              })
            }
          >
            検索結果を見る
          </Button>
          <Button
            size="small"
            sx={{ mt: 1, textDecoration: 'underline' }}
            onClick={clearAllConditions}
          >
            すべての条件をクリア
          </Button>
        </Box>
      </CardActions>
    </Card>
  );
}
