import FlagIcon from '@mui/icons-material/Flag';
import {
  Box,
  Button,
  Typography,
  Card,
  CardContent,
  Divider,
  useTheme,
  Stack,
} from '@mui/material';
import React, { ReactElement } from 'react';
export function InquiryCards(): ReactElement {
  const theme = useTheme();
  return (
    <Box sx={{ maxWidth: 300, ml: 3, width: '100%' }}>
      <Card
        sx={{
          border: `1px solid ${theme.palette.grey[400]}`,
          borderRadius: '8px',
          mb: 2,
          px: 2,
        }}
      >
        <CardContent sx={{ mb: 2, px: 0 }}>
          <Typography variant="body2" fontWeight="bold" mb={3}>
            この住宅展示場を見学
          </Typography>
          <Button
            variant="outlined"
            sx={{
              borderColor: theme.palette.grey[400],
              borderRadius: 1,
              color: 'black !important',
              fontWeight: 'normal',
              p: 1,
            }}
            fullWidth
          >
            住宅展示場を探す
          </Button>
        </CardContent>

        <Divider />

        <CardContent sx={{ mt: 1, px: 0 }}>
          <Typography variant="body2" fontWeight="bold" mb={3}>
            この企業の分譲を探す
          </Typography>
          <Button
            variant="outlined"
            sx={{
              borderColor: theme.palette.grey[400],
              borderRadius: 1,
              color: 'black !important',
              fontWeight: 'normal',
              p: 1,
            }}
            fullWidth
          >
            分譲を探す
          </Button>
        </CardContent>
      </Card>
      <Stack direction="row" justifyContent="center" alignItems="center">
        <FlagIcon color="disabled" fontSize="small" />
        <Typography variant="body1" color={theme.palette.grey[500]} ml={1}>
          Report listing
        </Typography>
      </Stack>
    </Box>
  );
}
