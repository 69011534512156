import { Box, Grid, Typography, Divider } from '@mui/material';
import React from 'react';

import { theme } from '@app/theme';

interface FloorPlanHighlight {
  description: string;
  image: string;
  title: string;
}

interface FloorPlanHighlightsProps {
  highlights: FloorPlanHighlight[];
}

export function FloorPlanHighlights({ highlights }: FloorPlanHighlightsProps) {
  return (
    <Box sx={{ mt: 3 }}>
      <Typography variant="h3" fontWeight="bold">
        この会社を選ぶ理由
      </Typography>
      {highlights.map((highlight, index) => (
        <Grid container spacing={2} key={index} sx={{ my: 5 }}>
          <Grid item xs={12} md={4}>
            <Box
              component="img"
              src={highlight.image}
              alt={highlight.title}
              sx={{
                borderRadius: 1,
                height: '288px',
                objectFit: 'cover',
                width: '100%',
              }}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <Box ml={1}>
              <Box
                sx={{
                  background:
                    'linear-gradient(to right, #e0f7fa, #e0f2f1, #e0f7fa)',
                  borderRadius: '16px',
                  display: 'inline-block',
                  mb: 1,
                  px: 2,
                  py: 0.5,
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    color: theme.palette.neutral.blueLight,
                    fontWeight: 'bold',
                  }}
                >
                  Point.{index + 1}
                </Typography>
              </Box>
              <Typography variant="h6" fontWeight="bold" sx={{ mb: 1 }}>
                {highlight.title}
              </Typography>
              <Typography variant="body1">{highlight.description}</Typography>
            </Box>
          </Grid>
        </Grid>
      ))}
      <Divider sx={{ my: 5 }} />
    </Box>
  );
}
