import { LoadingButton } from '@mui/lab';
import {
  Box,
  Container,
  FormLabel,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import _ from 'lodash';
import React, { ReactElement, useCallback, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { sendResetPasswordEmail } from '@app/adapter/user-service';
import { HeadBlock } from '@app/components/Shared/HeadBlock';
import { useSetSnackbar } from '@app/hooks/useSetSnackbar';
import { SendResetPasswordForm } from '@app/schemas/user';
import { ERROR_MESSAGE } from '@app/static/constants';
import { theme } from '@app/theme';
import { UserSendResetPassword } from '@app/types/user';
import { isError } from '@app/utils/error';

export function SendResetPasswordEmail(): ReactElement {
  const navigate = useNavigate();
  const setSnackbar = useSetSnackbar();
  const [isLoading, setIsLoading] = useState(false);

  const {
    control,
    formState: { isValid },
    handleSubmit,
  } = useForm<UserSendResetPassword>({
    defaultValues: SendResetPasswordForm.defaultValues,
    mode: 'all',
    resolver: SendResetPasswordForm.resolver,
  });

  const onSubmit = useCallback(
    async (data: UserSendResetPassword) => {
      setIsLoading(true);
      try {
        await sendResetPasswordEmail(data.email);
        navigate('/password/reset/email-sent', { state: { waiting: true } });
      } catch (e) {
        if (isError(e)) {
          console.error(e.message);
        }
        let message = '送信に失敗しました';
        if (_.get(e, 'response.data.code') === 'invalid_email') {
          message = ERROR_MESSAGE.EMAIL_NOT_FOUND;
        }
        setSnackbar(true, message);
      } finally {
        setIsLoading(false);
      }
    },
    [navigate, setSnackbar]
  );

  return (
    <>
      <HeadBlock />
      <Container>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={4} sx={{ mt: 15 }}>
            <Typography fontWeight={500} variant="h4" textAlign="center">
              パスワード再設定手続き
            </Typography>
            <Typography textAlign="center">
              会員登録時にご登録して頂いたメールアドレスを入力してください。パスワード再発行手続きのメールをお送りします。
            </Typography>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Box
                sx={{
                  border: `1px solid ${theme.palette.neutral.silver}`,
                  borderRadius: 1,
                  mt: 3,
                  padding: 3,
                  width: '60%',
                }}
              >
                <FormLabel>
                  <Typography variant="body2">メールアドレス</Typography>
                </FormLabel>
                <Controller
                  name="email"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      type="email"
                      error={!!error}
                      helperText={error?.message}
                      margin="dense"
                      placeholder="email@sample.com"
                      fullWidth
                    />
                  )}
                />
                <LoadingButton
                  type="submit"
                  color="primary"
                  variant="contained"
                  fullWidth
                  loading={isLoading}
                  disabled={!isValid}
                  sx={{ mt: 3 }}
                >
                  送信
                </LoadingButton>
              </Box>
            </Box>
          </Stack>
        </form>
      </Container>
    </>
  );
}
