import { Visibility, VisibilityOff } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Link as MUILink,
  Stack,
  TextField,
  Typography,
  IconButton,
  InputAdornment,
} from '@mui/material';
import { Box } from '@mui/system';
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import { register } from '@app/adapter/user-service';
import { Copyright } from '@app/components/Shared/Copyright';
import { HeadBlock } from '@app/components/Shared/HeadBlock';
import { Logo } from '@app/components/Shared/Logo';
import { userIvCodeState } from '@app/domain/user';
import { useSetSnackbar } from '@app/hooks/useSetSnackbar';
import {
  RegistrationFormConfig,
  RegistrationFormValues,
} from '@app/schemas/registration';
import { imageAssets } from '@app/theme';
import { isError } from '@app/utils/error';
import { generateInviteCode } from '@app/utils/user';

export function Register(): ReactElement {
  const navigate = useNavigate();
  const setSnackbar = useSetSnackbar();
  const [ivCodeState, setIvCodeState] = useRecoilState(userIvCodeState);
  const [isLoading, setIsLoading] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const { control, handleSubmit, formState } = useForm<RegistrationFormValues>({
    defaultValues: { ...RegistrationFormConfig.defaultValues },
    mode: 'all',
    resolver: RegistrationFormConfig.resolver,
  });

  const location = useLocation();
  const queryParams = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location.search]);

  useEffect(() => {
    const ivCode = queryParams.get('iv');
    if (ivCode) {
      setIvCodeState(ivCode);
    }
    // eslint-disable-next-line
  }, []);

  const handlePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const onSubmit = useCallback(
    async (data: RegistrationFormValues) => {
      const { email, password } = data;
      try {
        setIsLoading(true);
        await register(email, password, {
          inviteCode: generateInviteCode(),
          invitedCode: queryParams.get('iv') || ivCodeState,
        });
        setIvCodeState('');
        navigate('/register/complete');
      } catch (e) {
        let message = '登録できませんでした';
        if (isError(e)) {
          console.error(e.message);
          if (e.message.includes('has been registered in system')) {
            message = '既にこのメールアドレスは登録済みです';
          }
        }
        setSnackbar(true, message);
      } finally {
        setIsLoading(false);
      }
    },
    [ivCodeState, navigate, queryParams, setSnackbar, setIvCodeState]
  );

  return (
    <>
      <HeadBlock />
      <Container maxWidth={false} disableGutters>
        <Box display="flex" height="100vh" width="100%">
          <Box
            flex={1}
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            p={3}
            sx={{ p: 8 }}
          >
            <Box sx={{ px: 10 }}>
              <Logo />
              <Stack spacing={3} sx={{ mt: 6, py: 3 }}>
                <Typography
                  variant="h5"
                  fontWeight={600}
                  textAlign="left"
                  sx={{ py: 3 }}
                >
                  アカウント作成
                </Typography>
              </Stack>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={2}>
                  <FormControl variant="outlined" fullWidth>
                    <FormLabel>
                      <Typography variant="body2" fontWeight={500}>
                        メールアドレス
                      </Typography>
                    </FormLabel>
                    <Controller
                      name="email"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          error={!!error}
                          helperText={error?.message}
                          margin="dense"
                          placeholder="メールアドレスを入力"
                        />
                      )}
                    />
                  </FormControl>
                  <FormControl variant="outlined" fullWidth>
                    <FormLabel>
                      <Typography variant="body2" fontWeight={500}>
                        パスワード
                      </Typography>
                    </FormLabel>
                    <Controller
                      name="password"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          type={isPasswordVisible ? 'text' : 'password'}
                          error={!!error}
                          helperText={error?.message}
                          margin="dense"
                          placeholder="パスワードを入力"
                          autoComplete="new-password"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={handlePasswordVisibility}
                                  onMouseDown={(e) => e.preventDefault()}
                                >
                                  {isPasswordVisible ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    />
                  </FormControl>
                  <Stack
                    alignItems="center"
                    justifyContent="center"
                    sx={{ pt: 5 }}
                  >
                    <Controller
                      name="agreement"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <FormControl error={!!error}>
                          <FormControlLabel
                            label={
                              <Typography
                                component="div"
                                variant="body2"
                                sx={{ display: 'flex' }}
                              >
                                {/* TODO: 利用規約確定したらリンク先正式なものに変更 */}
                                <Link
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  to="https://med-pro.jp/media.dr/prodoctors_termsofservice/"
                                  style={{ textDecoration: 'none' }}
                                >
                                  <Typography
                                    component="span"
                                    color="primary"
                                    variant="body2"
                                  >
                                    利用規約
                                  </Typography>
                                </Link>
                                <Typography>・</Typography>
                                <Link
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  to="https://med-pro.jp/media.dr/en_privacypolicy/"
                                  style={{ textDecoration: 'none' }}
                                >
                                  <Typography
                                    component="span"
                                    color="primary"
                                    variant="body2"
                                  >
                                    プライバシーポリシー
                                  </Typography>
                                </Link>
                                を確認しました
                              </Typography>
                            }
                            control={<Checkbox {...field} />}
                          />
                          {error && (
                            <FormHelperText sx={{ ml: 0 }}>
                              {error.message}
                            </FormHelperText>
                          )}
                        </FormControl>
                      )}
                    />
                  </Stack>
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    fullWidth
                    disabled={!formState.isValid}
                    loading={isLoading}
                  >
                    アカウント作成
                  </LoadingButton>
                </Stack>
              </form>
              <Box sx={{ my: 5 }}>
                <Typography component="div" align="center" variant="body2">
                  既にアカウントをお持ちですか？
                  <MUILink component={Link} to="/login" underline="none">
                    ログイン
                  </MUILink>
                </Typography>
              </Box>
            </Box>
            <Box sx={{ py: 2, textAlign: 'center' }}>
              <Copyright />
            </Box>
          </Box>
          <Box
            flex={1}
            sx={{
              backgroundImage: `url(${imageAssets.registerMainImage})`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              width: '100%',
            }}
          ></Box>
        </Box>
      </Container>
    </>
  );
}
