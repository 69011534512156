import { FormType } from '@app/types/order';

interface Qualification {
  name: string;
  status: string;
  value: string;
}

export enum OrganizationStatus {
  BEFORE_REVIEW = 'beforeReview',
  CONFIRMED = 'confirmed',
  IN_REVIEW = 'inReview',
  REJECTED = 'rejected',
}

export enum OrganizationType {
  INDIVIDUAL = '個人',
  ORGANIZATION = '法人',
}

export interface Organization {
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  branchName?: string;
  certifiedQualifications?: Qualification[];
  customFields: OrganizationCustomFields;
  description?: string;
  iconImageUri?: string;
  id: string;
  name: string;
  numberOfProjects?: number;
  ownerId?: string;
  phoneNumber: string;
  pictureUrl?: string;
  postalCode: string;
  qualifications?: Qualification[];
  size?: number;
  status?: OrganizationStatus;
  typeId?: OrganizationType;
}

export interface OrganizationCustomFields {
  access: string;
  addressByCity?: string;
  addressLine4: string;
  chatContact: string;
  clinicUrl: string;
  department: string;
  electronicChartMaker: string;
  electronicCharts: string[];
  equipmentOther: string;
  equipments: string[];
  exhibitionRequestDocQuestions?: InquiryStepTwoQuestion[];
  exhibitionVisitorReserveQuestions?: InquiryStepTwoQuestion[];
  mainImages: string;
  ownerName: string;
  ownerUserId: string;
  phoneNumber: string;
  propertyRequestDocQuestions?: InquiryStepTwoQuestion[];
  propertyVisitorReserveQuestions?: InquiryStepTwoQuestion[];
}

export interface InquiryStepTwoQuestion {
  formType: FormType;
  otherFlg: boolean;
  required: boolean;
  selections: string[];
  subText: string;
  title: string;
}

export enum InquiryType {
  ExhibitionRequestDoc = 'exhibitionRequestDoc',
  ExhibitionVisitor = 'exhibitionVisitor',
  PropertyRequestDoc = 'propertyRequestDoc',
  PropertyVisitor = 'propertyVisitor',
}

/**
 * follow
 */
export const FollowerType = {
  ORGANIZATION: 'organization',
  PRODUCT: 'product',
  USER: 'user',
} as const;
export type FollowerTypeKey = (typeof FollowerType)[keyof typeof FollowerType];

export const FollowType = {
  ORGANIZATION: 'organization',
  PRODUCT: 'product',
  USER: 'user',
} as const;
export type FollowTypeKey = (typeof FollowType)[keyof typeof FollowType];

export interface Follow {
  createdAt: Date;
  delFlg: number;
  followId: string;
  followType: FollowTypeKey;
  followerId: string;
  followerType: FollowerTypeKey;
  id: string;
  updatedAt: Date;
}

export interface FollowCreate {
  followerId: string;
  followerType: FollowerTypeKey;
}
