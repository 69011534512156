import { Typography } from '@mui/material';
import { ReactElement } from 'react';

interface TopNavTitleProps {
  title: string;
}

export function TopNavTitle({ title }: TopNavTitleProps): ReactElement {
  return (
    <Typography
      component="div"
      color="inherit"
      fontSize="1.125rem"
      sx={{
        textAlign: 'center',
        width: '100%',
      }}
      noWrap
    >
      {title}
    </Typography>
  );
}
