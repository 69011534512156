import { Box, Stack, Typography, Container, Grid } from '@mui/material';
import { ReactElement } from 'react';
import { Link } from 'react-router-dom';

import { imageAssets, theme } from '@app/theme';

interface BottomMenuProps {
  menuItems: {
    target?: React.HTMLAttributeAnchorTarget;
    text: string;
    to: string;
  }[];
  shouldShowMenuItems?: boolean;
}

export function BottomMenu(props: BottomMenuProps): ReactElement {
  const { menuItems, shouldShowMenuItems = true } = props;
  const currentYear = new Date().getFullYear();

  return (
    <Box
      sx={{
        bgcolor: theme.palette.neutral.blueLight,
        color: theme.palette.neutral.white,
      }}
    >
      <Container sx={{ py: shouldShowMenuItems ? 8 : 4 }}>
        {shouldShowMenuItems && (
          <>
            <Grid container alignItems="center" spacing={4} sx={{ mb: 10 }}>
              <Grid item xs={12} md={4}>
                <img
                  src={imageAssets.logoWhite}
                  alt="Logo"
                  style={{ width: '150px' }}
                />
              </Grid>
              <Grid item xs={12} md={8}>
                <Stack direction="row" spacing={4} justifyContent="flex-end">
                  <Stack direction="column" spacing={1}>
                    {menuItems
                      .slice(0, 3)
                      .map(({ text, to, target }, index) => (
                        <Link
                          key={index}
                          to={to}
                          style={{ color: 'inherit', textDecoration: 'none' }}
                          target={target}
                        >
                          <Typography>{text}</Typography>
                        </Link>
                      ))}
                  </Stack>
                  <Stack direction="column" spacing={1}>
                    {menuItems.slice(3).map(({ text, to, target }, index) => (
                      <Link
                        key={index}
                        to={to}
                        style={{ color: 'inherit', textDecoration: 'none' }}
                        target={target}
                      >
                        <Typography>{text}</Typography>
                      </Link>
                    ))}
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          </>
        )}
        <Box
          sx={{
            alignItems: 'center',
            borderTop: shouldShowMenuItems ? '1px solid white' : 'none',
            display: 'flex',
            justifyContent: 'space-between',
            pt: shouldShowMenuItems ? 3 : 'none',
          }}
        >
          <Typography variant="body2">
            ©{currentYear} All Rights Reserved
          </Typography>
          <Stack direction="row" spacing={2}>
            <Link
              to="/terms"
              style={{ color: 'inherit', textDecoration: 'none' }}
            >
              <Typography variant="body2">Term & Condition</Typography>
            </Link>
            <Link
              to="/privacy"
              style={{ color: 'inherit', textDecoration: 'none' }}
            >
              <Typography variant="body2">Privacy Policy</Typography>
            </Link>
          </Stack>
        </Box>
      </Container>
    </Box>
  );
}
