import { Box, Container, Stack, Typography } from '@mui/material';
import _ from 'lodash';
import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { Copyright } from '@app/components/Shared/Copyright';
import { HeadBlock } from '@app/components/Shared/HeadBlock';
import { Logo } from '@app/components/Shared/Logo';
import { getVerifyToken, verifyEmailToken } from '@app/domain/network-actions';
import { useSetSnackbar } from '@app/hooks/useSetSnackbar';
import { imageAssets } from '@app/theme';

export function WaitingForEmailVerify() {
  const navigate = useNavigate();
  const setSnackbar = useSetSnackbar();
  const verifyRun = useRef(false);

  useEffect(() => {
    const token = getVerifyToken();
    if (_.isEmpty(token) || verifyRun.current) return;

    verifyEmailToken(token)
      .then((data) => {
        if (data.status === 204) {
          setSnackbar(
            true,
            'メールアドレスを認証しました。ログインしてください',
            'success'
          );
          navigate('/login');
        }
      })
      .catch((e) => {
        setSnackbar(true, 'メールアドレスの認証に失敗しました。');
      });
    verifyRun.current = true;
  }, [navigate, setSnackbar]);

  return (
    <>
      <HeadBlock />
      <Container maxWidth={false} disableGutters>
        <Box display="flex" height="100vh" width="100%">
          <Box
            flex={1}
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            p={3}
            sx={{ p: 8 }}
          >
            <Box sx={{ px: 10 }}>
              <Logo />
              <Stack spacing={3} sx={{ mt: 6, py: 3 }}>
                <Typography
                  variant="h5"
                  fontWeight={600}
                  textAlign="left"
                  sx={{ py: 3 }}
                >
                  登録手続き用メールを送信しました
                </Typography>

                <Box>
                  <Typography sx={{ py: 3 }}>
                    ご登録はまだ完了しておりません。
                  </Typography>
                  <Typography sx={{ py: 3 }}>
                    ご登録いただいたメールアドレス宛に本登録手続き用メールを送信しました。
                  </Typography>
                  <Typography sx={{ py: 3 }}>
                    メール本文に記述されているURLをクリックして頂き、本登録手続き画面より手続きを行なってください。
                  </Typography>
                </Box>
              </Stack>
            </Box>
            <Box sx={{ py: 2, textAlign: 'center' }}>
              <Copyright />
            </Box>
          </Box>
          <Box
            flex={1}
            sx={{
              backgroundImage: `url(${imageAssets.registerMainImage})`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              width: '100%',
            }}
          ></Box>
        </Box>
      </Container>
    </>
  );
}
