import { Box, Container, Grid, Typography } from '@mui/material';
import { ReactElement, useCallback, useEffect, useState } from 'react';

import {
  getCategories,
  getOrganizationProducts,
} from '@app/adapter/catalog-service';
import { ExhibitionFloorPlan } from '@app/components/Product/ExhibitionFloorPlan';
import { ExhibitionInfo } from '@app/components/Product/ExhibitionInfo';
import {
  InquiryCardsHorizontal,
  InquiryCardsVertical,
} from '@app/components/Product/InquiryCards';
import { ProductCardList } from '@app/components/Product/ProductCardList';
import { ProductDetails } from '@app/components/Product/ProductDetails';
import { Loading } from '@app/components/Shared/Loading';
import { CategoryType, Product, ProductVariantSku } from '@app/types/catalog';

interface ProductDetailInfoProps {
  product: Product;
}

export function ProductDetailInfo({
  product,
}: ProductDetailInfoProps): ReactElement {
  const [categoryId, setCategoryId] = useState<string | null>(null);
  const [resultProducts, setResultProducts] = useState<Product[]>([]);

  const floorPlanVariants = product.variants.filter(
    (variant) => variant.sku === ProductVariantSku.FLOOR_PLAN
  );

  const fetchCategoryId = useCallback(async () => {
    try {
      const response = await getCategories();
      const propertyCategory = response.data.value.find(
        (category) => category.name === CategoryType.PROPERTY
      );
      setCategoryId(propertyCategory ? propertyCategory.id : null);
    } catch (error) {
      console.error('Failed to fetch categories', error);
    }
  }, []);

  const fetchProducts = useCallback(async () => {
    if (!categoryId) return;
    try {
      const response = await getOrganizationProducts(
        product.organizationId,
        categoryId
      );
      setResultProducts(response.data.value);
    } catch (error) {
      console.error('Failed to fetch organization products', error);
    }
  }, [product.organizationId, categoryId]);

  useEffect(() => {
    void fetchCategoryId();
  }, [fetchCategoryId]);

  useEffect(() => {
    void fetchProducts();
  }, [fetchProducts]);

  return (
    <>
      {!product ? (
        <Loading />
      ) : (
        <Box>
          <Container sx={{ pb: 10, pt: 3 }} maxWidth={false} disableGutters>
            <Grid container spacing={3}>
              <Grid item xs={12} md={9} sx={{ width: '100%' }}>
                <ProductDetails product={product} />
                <ExhibitionFloorPlan variants={floorPlanVariants} />
                <ExhibitionInfo product={product} />
                {resultProducts.length > 0 &&
                  resultProducts[0].organizationId && (
                    <InquiryCardsHorizontal
                      type={CategoryType.EXHIBITION}
                      isExhibition={true}
                      organizationId={resultProducts[0].organizationId}
                    />
                  )}
                <Grid sx={{ mb: 12, mt: 5 }}>
                  <Typography
                    variant="h6"
                    fontWeight={600}
                    textAlign="left"
                    sx={{ mx: 3 }}
                  >
                    この窓口が入稿している土地情報
                  </Typography>
                  <ProductCardList
                    products={resultProducts}
                    initialVisibleItems={6}
                    isProperty={true}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                md={3}
                sx={{ display: 'flex', justifyContent: 'center', mt: 7 }}
              >
                {resultProducts.length > 0 &&
                  resultProducts[0].organizationId && (
                    <InquiryCardsVertical
                      organizationId={resultProducts[0].organizationId}
                      isExhibition={true}
                    />
                  )}
              </Grid>
            </Grid>
          </Container>
        </Box>
      )}
    </>
  );
}
