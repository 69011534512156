import { Box, Container, CircularProgress } from '@mui/material';
import { ReactElement } from 'react';
interface LoadingProps {
  onGoBack?: () => void;
  title?: string;
}

export function Loading({
  title = '読み込み中',
  onGoBack,
}: LoadingProps): ReactElement {
  return (
    <>
      <Container
        sx={{
          alignItems: 'center',
          background: 'neutral.white',
          display: 'flex',
          justifyContent: 'space-between',
          py: 1,
        }}
      >
        <Box sx={{ m: '10vh auto' }}>
          <CircularProgress color="primary" />
        </Box>
      </Container>
    </>
  );
}
