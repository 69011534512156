import {
  Card as MuiCard,
  CardMedia,
  CardContent,
  Typography,
} from '@mui/material';
import React, { ReactElement } from 'react';

interface CardProps {
  address: string;
  imageSrc: string;
  title: string;
}

export function Card({ imageSrc, title, address }: CardProps): ReactElement {
  return (
    <MuiCard sx={{ borderRadius: 2, boxShadow: 3, width: '100%' }}>
      <CardMedia
        component="img"
        height="200"
        image={imageSrc}
        alt="House"
        sx={{ borderRadius: 2 }}
      />
      <CardContent>
        <Typography variant="subtitle2" component="div" fontWeight="bold">
          住宅展示場タイトル
        </Typography>
        <Typography variant="body2" color="text.secondary">
          サブテキスト1 ~ サブテキスト2
        </Typography>
      </CardContent>
    </MuiCard>
  );
}
