import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Divider,
} from '@mui/material';
import React from 'react';

import { theme } from '@app/theme';
import { Product } from '@app/types/catalog';

interface OrganizationInfoProps {
  product: Product;
}

const TableCellWithStyle = ({ children }: { children: React.ReactNode }) => (
  <TableCell
    sx={{
      backgroundColor: theme.palette.neutral.sky,
      fontWeight: 'bold',
      width: '30%',
    }}
  >
    {children}
  </TableCell>
);

const TableCellWithFallback = ({
  value,
}: {
  value: string | number | undefined | null | string[];
}) => (
  <TableCell>
    {Array.isArray(value) ? value.join(', ') : value ? value : '-'}
  </TableCell>
);

export function OrganizationInfo({ product }: OrganizationInfoProps) {
  if (typeof product.organization === 'string') {
    return null;
  }

  return (
    <Box sx={{ p: 2 }}>
      <Divider sx={{ my: 4 }} />
      <Typography variant="h6" fontWeight={600} sx={{ mb: 3 }}>
        会社情報
      </Typography>
      <TableContainer
        component={Paper}
        sx={{
          border: `1px solid ${theme.palette.neutral.silver}`,
          borderRadius: '8px',
          width: '100%',
        }}
      >
        <Table>
          <TableBody>
            <TableRow>
              <TableCellWithStyle>売主</TableCellWithStyle>
              <TableCellWithFallback value={product.customFields.companyName} />
            </TableRow>
            <TableRow>
              <TableCellWithStyle>問い合わせ</TableCellWithStyle>
              <TableCell>
                <Typography>{product.organization?.name || '-'}</Typography>
                <Typography sx={{ mt: 1 }}>
                  {product.organization?.phoneNumber || ''}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
