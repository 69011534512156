import {
  Box,
  Grid,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Link,
} from '@mui/material';
import React from 'react';

import { imageAssets, theme } from '@app/theme';
import { ProductVariant } from '@app/types/catalog';

interface PropertyFloorPlanProps {
  organizationId: string;
  variants: ProductVariant[];
}

export function PropertyFloorPlan({
  variants,
  organizationId,
}: PropertyFloorPlanProps) {
  return (
    <Box sx={{ mb: 10, mt: 3 }}>
      <Typography variant="h6" fontWeight={600} sx={{ my: 2 }}>
        間取り・プラン
      </Typography>
      <Typography variant="body2" sx={{ mb: 2 }}>
        公開中: {variants.length}件
      </Typography>
      <Grid container spacing={2}>
        {variants.map((variant) => (
          <Grid item xs={6} key={variant.id}>
            <Card
              sx={{
                border: `1px solid ${theme.palette.neutral.silver}`,
                height: '100%',
              }}
            >
              <CardContent>
                <Typography variant="subtitle1" fontWeight={600} sx={{ my: 2 }}>
                  {variant.title}
                </Typography>
                <Typography variant="body2" sx={{ my: 2 }}>
                  間取り: {variant.customFields?.floorPlan || '-'}
                </Typography>
                <Typography variant="body2" sx={{ my: 2 }}>
                  専有面積: {variant.customFields?.exclusiveArea || '-'} ㎡
                </Typography>
                <Typography variant="body2" sx={{ my: 2 }}>
                  販売価格:
                  <Link
                    href={`/companies/${organizationId}/inquiry/create`}
                    color="primary"
                    sx={{ ml: 1 }}
                  >
                    価格をお問い合わせ（無料）
                  </Link>
                </Typography>
              </CardContent>
              <CardMedia
                component="img"
                sx={{
                  height: '200px',
                  mb: 3,
                  objectFit: 'contain',
                  width: '100%',
                }}
                image={variant.images?.[0]?.url || imageAssets.floorPlanDefault}
                alt={variant.title}
              />
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
