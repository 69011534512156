import { Box, Container, Divider, Grid } from '@mui/material';
import { ReactElement } from 'react';

import { InquiryCards } from './InquiryCards';

import { ExhibitionInfo } from '@app/components/Product/company/ExhibitionInfo';
import { FloorPlanHighlights } from '@app/components/Product/company/FloorPlanHighlights';
import { ProductCardList } from '@app/components/Product/company/ProductCardList';
import { ProductDetails } from '@app/components/Product/company/ProductDetails';
import { Loading } from '@app/components/Shared/Loading';
import { productListDummyData } from '@app/static/constants';
import { Product } from '@app/types/catalog';

interface ProductDetailInfoProps {
  product: Product;
}

export function ProductDetailInfo({
  product,
}: ProductDetailInfoProps): ReactElement {
  const highlights = [
    {
      description: product.customFields.floorPlanDescription1,
      image: product.customFields.floorPlanImage1,
      title: product.customFields.floorPlanTitle1,
    },
    {
      description: product.customFields.floorPlanDescription2,
      image: product.customFields.floorPlanImage2,
      title: product.customFields.floorPlanTitle2,
    },
    {
      description: product.customFields.floorPlanDescription3,
      image: product.customFields.floorPlanImage3,
      title: product.customFields.floorPlanTitle3,
    },
    {
      description: product.customFields.floorPlanDescription4,
      image: product.customFields.floorPlanImage4,
      title: product.customFields.floorPlanTitle4,
    },
    {
      description: product.customFields.floorPlanDescription5,
      image: product.customFields.floorPlanImage5,
      title: product.customFields.floorPlanTitle5,
    },
  ];

  return (
    <>
      {!product ? (
        <Loading />
      ) : (
        <Box>
          <Container sx={{ pb: 10, pt: 3 }} maxWidth={false} disableGutters>
            <Grid container spacing={3}>
              <Grid item xs={12} md={9} sx={{ width: '100%' }}>
                <ProductDetails product={product} />
                <Divider sx={{ my: 5 }} />
                <FloorPlanHighlights highlights={highlights} />
                <ExhibitionInfo product={product} />
                <Grid sx={{ mb: 12, mt: 5 }}>
                  <ProductCardList
                    products={productListDummyData}
                    initialVisibleItems={6}
                  />
                  <ProductCardList
                    products={productListDummyData}
                    initialVisibleItems={6}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                md={3}
                sx={{ display: 'flex', justifyContent: 'center', mt: 7 }}
              >
                <InquiryCards />
              </Grid>
            </Grid>
          </Container>
        </Box>
      )}
    </>
  );
}
