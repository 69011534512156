import { Box, Typography, Paper } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { getOrganizationProducts } from '@app/adapter/catalog-service';
import { imageAssets } from '@app/theme';
import { Product } from '@app/types/catalog';

interface ShowroomListProps {
  organizationId: string;
}

export function ShowroomList({ organizationId }: ShowroomListProps) {
  const [showrooms, setShowrooms] = useState<Product[]>([]);
  const location = useLocation();

  const fetchProducts = useCallback(async () => {
    const queryParams = new URLSearchParams(location.search);
    const categoryId = queryParams.get('category') || undefined;

    try {
      const response = await getOrganizationProducts(
        organizationId,
        categoryId
      );
      setShowrooms(response.data.value);
    } catch (error) {
      console.error('Failed to fetch organization products', error);
    }
  }, [organizationId, location.search]);

  useEffect(() => {
    void fetchProducts();
  }, [fetchProducts]);

  const handleScroll = (event: React.WheelEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  return (
    <Box sx={{ overflow: 'hidden', width: '100%' }}>
      <Typography variant="body1" sx={{ mb: 2 }}>
        住宅展示場一覧
      </Typography>
      <Box
        sx={{ display: 'flex', overflowX: 'auto', pb: 3, width: '100%' }}
        onWheel={handleScroll}
      >
        {showrooms.map((showroom) => (
          <Box
            key={showroom.id}
            sx={{ flex: '0 0 auto', mr: 2, width: '240px' }}
          >
            <Paper elevation={0} sx={{ height: '100%', padding: 2 }}>
              <img
                src={showroom.images[0]?.url || imageAssets.productDefault}
                alt={showroom.name}
                style={{
                  borderRadius: '4px',
                  height: '160px',
                  objectFit: 'cover',
                  width: '100%',
                }}
              />
              <Typography variant="body1" sx={{ mb: 1, mt: 1 }}>
                {showroom.name}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {showroom.addressLine1}
                {showroom.addressLine2}
                {showroom.addressLine3}
                {showroom.customFields.addressLine4}
              </Typography>
            </Paper>
          </Box>
        ))}
      </Box>
    </Box>
  );
}
