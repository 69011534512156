import React, { ReactElement, useEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

import { checkToken } from '@app/adapter/auth-service';
import { getUser } from '@app/adapter/user-service';
import { loggedInUserState, userAuthInfoSelector } from '@app/domain/app';
import { useSetSnackbar } from '@app/hooks/useSetSnackbar';
import { isDemanderUser } from '@app/utils/user';

export function LoginCallback(): ReactElement {
  const navigate = useNavigate();
  const setSnackbar = useSetSnackbar();
  const setUserAuthInfoState = useSetRecoilState(userAuthInfoSelector);
  const setLoggedInUser = useSetRecoilState(loggedInUserState);

  const location = useLocation();
  const { provider } = useParams<{ provider: string }>();

  useEffect(() => {
    const login = async () => {
      const { search } = location;
      const url = new URLSearchParams(search);
      const code = url.get('code');

      if (code) {
        const {
          data: { userId, accessToken, fp },
        } = await checkToken<{
          accessToken: string;
          fp: string;
          userId: string;
        }>(code, `${provider}Oauth`);

        const user = await getUser(userId, accessToken, fp);
        if (!isDemanderUser(user.data.typeId)) {
          throw new Error('ProDoctorsの医療機関側のURLで再度お試しください');
        }

        setLoggedInUser(user.data);
        setUserAuthInfoState({
          accessToken,
          fingerPrint: fp,
          userId,
        });

        navigate('/home');
      }
    };

    login().catch((error) => {
      setSnackbar(true, 'ログインに失敗しました');
      navigate('/login');
    });
  }, [
    location,
    navigate,
    provider,
    setLoggedInUser,
    setSnackbar,
    setUserAuthInfoState,
  ]);

  return <></>;
}
