import { LoadingButton } from '@mui/lab';
import { Box, Stack, Typography, Button, Grid } from '@mui/material';
import { ReactElement, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { ProgressStepper } from '@app/components/User/ProgressStepper';
import { registerState } from '@app/domain/register';
import { UserFormData } from '@app/schemas/registration';
import { theme } from '@app/theme';

export interface RegistrationFormData {
  registerData: UserFormData;
}

interface RegistrationFormProps {
  onBack: () => void;
  onSubmit: (formData: RegistrationFormData) => void | Promise<void>;
}

export function InReview({
  onBack,
  onSubmit,
}: RegistrationFormProps): ReactElement {
  const registerData = useRecoilValue(registerState);
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  return (
    <Stack spacing={5}>
      <Box
        sx={{
          border: `1px solid ${theme.palette.neutral.silver}`,
          borderRadius: 1,
          mt: 10,
          padding: 3,
        }}
      >
        <Box>
          <ProgressStepper activeStep={1} />
          <Typography variant="h6" fontWeight={700} sx={{ mb: 5 }}>
            会員情報登録
          </Typography>

          <Typography variant="body1" fontWeight={700}>
            姓名
          </Typography>
          <Box sx={{ display: 'flex', mb: 3, mt: 1 }}>
            <Typography>
              {registerData.customFields.familyName}{' '}
              {registerData.customFields.firstName}
            </Typography>
          </Box>
          <Typography variant="body1" fontWeight={700}>
            姓名(かな)
          </Typography>
          <Box sx={{ display: 'flex', mb: 3, mt: 1 }}>
            <Typography>
              {registerData.customFields.familyNameKana}{' '}
              {registerData.customFields.firstNameKana}
            </Typography>
          </Box>
          <Typography variant="body1" fontWeight={700}>
            性別
          </Typography>
          <Typography sx={{ mb: 3, mt: 1 }}>{registerData.gender}</Typography>

          <Typography variant="body1" fontWeight={700}>
            生年月日
          </Typography>
          <Typography sx={{ mb: 3, mt: 1 }}>
            {registerData.birthday ? registerData.birthday : '-'}
          </Typography>

          <Typography variant="body1" fontWeight={700}>
            住所
          </Typography>
          <Typography sx={{ mt: 1 }}>〒{registerData.postalCode}</Typography>
          <Typography sx={{ mb: 3, mt: 1 }}>
            {registerData.addressLine1}
            {registerData.addressLine2}
            {registerData.addressLine3}
            {registerData.customFields.addressLine4}
          </Typography>
          <Typography variant="body1" fontWeight={700}>
            電話番号
          </Typography>
          <Typography sx={{ mb: 3, mt: 1 }}>
            {registerData.phoneNumber}
          </Typography>

          <Typography variant="body1" fontWeight={700}>
            職業
          </Typography>
          <Typography sx={{ mb: 3, mt: 1 }}>
            {registerData.occupation ? registerData.occupation : '-'}
          </Typography>

          <Typography variant="body1" fontWeight={700}>
            勤務先
          </Typography>
          <Typography sx={{ mb: 3, mt: 1 }}>
            {registerData.employer ? registerData.employer : '-'}
          </Typography>

          <Typography variant="body1" fontWeight={700}>
            世帯年収
          </Typography>
          <Typography sx={{ mb: 3, mt: 1 }}>
            {registerData.householdIncome ? registerData.householdIncome : '-'}
          </Typography>

          <Typography variant="body1" fontWeight={700}>
            家族・世帯構成
          </Typography>
          <Typography sx={{ mb: 3, mt: 1 }}>
            {registerData.householdComposition ? (
              <>
                子供: {registerData.householdComposition.children} 人,{' '}
                {registerData.householdComposition.spouse > 0 && '配偶者, '}
                {registerData.householdComposition.others}
              </>
            ) : (
              '-'
            )}
          </Typography>
        </Box>
        <Grid item xs={12} sx={{ px: 3 }}>
          <Grid container item xs={12} sx={{ pt: 6 }} spacing={2}>
            <LoadingButton
              type="submit"
              color="primary"
              variant="contained"
              fullWidth
              onClick={async () => {
                setIsButtonLoading(true);
                await onSubmit({
                  registerData,
                });
                setIsButtonLoading(false);
              }}
              loading={isButtonLoading}
              sx={{ mb: 2 }}
            >
              登録する
            </LoadingButton>
            <Button
              color="secondary"
              variant="outlined"
              fullWidth
              onClick={onBack}
            >
              入力画面へ戻る
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Stack>
  );
}
