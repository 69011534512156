import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import { generatePasswordPattern } from '@app/utils/pattern';

export interface RegistrationFormValues {
  agreement: boolean;
  email: string;
  password: string;
}

const RegistrationFormDefaultValues = {
  agreement: false,
  email: '',
  password: '',
} as const;

const RegistrationFormSchema = z.object({
  agreement: z.literal(true, {
    errorMap: () => ({
      message: 'プライバシーポリシーと利用規約に同意する必要があります',
    }),
  }),
  email: z.string().email({ message: 'メールアドレスを入力してください' }),
  password: z
    .string()
    .min(8, 'パスワードは記号を除く、英数字含む8文字以上を入力してください')
    .max(24, '24文字以下のパスワードを入力してください')
    .regex(generatePasswordPattern(), {
      message:
        '少なくとも1文字以上の小文字英字、数字が必要です。許可されている文字は、半角英数字です',
    }),
});

export const RegistrationFormConfig = {
  defaultValues: RegistrationFormDefaultValues,
  resolver: zodResolver(RegistrationFormSchema),
  schema: RegistrationFormSchema,
} as const;

// MEMO: プロクラさんのAPIを使用するとのことで一旦仮の型を定義する
export interface UserFormData {
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  birthday: string;
  customFields: {
    addressByCity: string;
    addressLine4: string;
    familyName: string;
    familyNameKana: string;
    firstName: string;
    firstNameKana: string;
  };
  employer: string; //勤務先
  gender: '男性' | '女性' | 'その他' | '';
  householdComposition: {
    //家族・世帯構成
    children: number; //子供の人数
    others: string; //その他の項目
    spouse: number; //配偶者
  };
  householdIncome?: number | null; //世帯年収
  occupation: string; //職業
  phoneNumber: string;
  postalCode: string;
}

export const USER_FORM_DEFAULT_VALUES: UserFormData = {
  addressLine1: '',
  addressLine2: '',
  addressLine3: '',
  birthday: '',
  customFields: {
    addressByCity: '',
    addressLine4: '',
    familyName: '',
    familyNameKana: '',
    firstName: '',
    firstNameKana: '',
  },
  employer: '',
  gender: '',
  householdComposition: {
    children: 0,
    others: '',
    spouse: 0,
  },
  householdIncome: null,
  occupation: '',
  phoneNumber: '',
  postalCode: '',
};

export const UserFormValidator = z.object({
  addressLine1: z.string().min(1, { message: '都道府県を入力してください' }),
  addressLine2: z.string().min(1, { message: '市町村区を入力してください' }),
  addressLine3: z.string().min(1, { message: '町名・番地を入力してください' }),
  birthday: z
    .string()
    .regex(/^\d{4}-\d{2}-\d{2}$|^$/, {
      message: '生年月日は正しい形式で入力してください',
    })
    .optional(),
  customFields: z.object({
    addressByCity: z.string().optional(),
    addressLine4: z.string().optional(),
    familyName: z.string().min(1, { message: '姓を入力してください' }),
    familyNameKana: z
      .string()
      .min(1, { message: '姓(かな)を入力してください' })
      .refine((value) => /^[ぁ-んー\s]+$/u.test(value), {
        message: '姓(かな)はひらがなのみで入力してください',
      }),
    firstName: z.string().min(1, { message: '名を入力してください' }),
    firstNameKana: z
      .string()
      .min(1, { message: '名(かな)を入力してください' })
      .refine((value) => /^[ぁ-んー\s]+$/u.test(value), {
        message: '名(かな)はひらがなのみで入力してください',
      }),
  }),
  employer: z.string().optional(),
  gender: z.enum(['男性', '女性', 'その他', '']),
  householdComposition: z
    .object({
      children: z
        .number()
        .nonnegative({ message: '子供の人数は0以上で入力してください' }),
      others: z.string().optional(),
      spouse: z
        .number()
        .nonnegative({ message: '配偶者の人数は0以上で入力してください' }),
    })
    .optional(),
  householdIncome: z
    .number()
    .nonnegative({ message: '世帯年収は0以上で入力してください' })
    .nullable()
    .optional(),
  occupation: z.string().optional(),
  phoneNumber: z
    .string()
    .min(1, { message: '電話番号を入力してください' })
    .regex(/^0[0-9]{9,12}$/, {
      message: '電話番号はハイフンなしの数字を入力してください',
    }),
  postalCode: z
    .string()
    .regex(/^\d{7}$/, { message: '郵便番号を入力してください' }),
});

export const UserRegistrationForm = {
  defaultValues: USER_FORM_DEFAULT_VALUES,
  resolver: zodResolver(UserFormValidator),
  schema: UserFormValidator,
} as const;
