import {
  Button,
  Box,
  Typography,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  SelectChangeEvent,
} from '@mui/material';
import { ReactElement, useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { getOrganizations } from '@app/adapter/organization-service';
import { categorySelector, locationsTreeSelector } from '@app/domain/catalog';
import { useDeviceType } from '@app/hooks/useBrowserHooks';
import { SEARCH_OPTIONS } from '@app/static/constants';
import { imageAssets } from '@app/theme';
import { CategoryType, CategoryTypeKey } from '@app/types/catalog';
import { Organization } from '@app/types/organization';

export function HomeCarousel(): ReactElement {
  const navigate = useNavigate();
  const { isDesktop } = useDeviceType();

  const categories = useRecoilValue(categorySelector);
  const locations = useRecoilValue(locationsTreeSelector);

  const [searchType, setSearchType] = useState<CategoryTypeKey>(
    CategoryType.EXHIBITION
  );
  const [location, setLocation] = useState<string>('');
  const [organization, setOrganization] = useState('');
  const [organizations, setOrganizations] = useState<Organization[]>([]);

  const fetchOrganizations = useCallback(async () => {
    try {
      const response = await getOrganizations();
      setOrganizations(response.data.value);
    } catch (error) {
      console.error('Error fetching organizations:', error);
    }
  }, []);

  useEffect(() => {
    void fetchOrganizations();
  }, [fetchOrganizations]);

  const handleSearchTypeChange = (event: SelectChangeEvent<string>) => {
    setSearchType(event.target.value as CategoryTypeKey);
  };

  const handleLocationChange = (event: SelectChangeEvent<string>) => {
    setLocation(event.target.value);
  };

  const handleOrganizationChange = (event: SelectChangeEvent<string>) => {
    setOrganization(event.target.value as string);
  };

  const handleClickSearch = () => {
    const params = new URLSearchParams();
    if (location) params.append('locationId', location);

    const selectedOrganization = organizations.find(
      (org) => org.id === organization
    );
    if (selectedOrganization) {
      params.append('organizationId', selectedOrganization.id);
    }

    const selectedCategory = categories.find(
      (category) => category.name === searchType
    );
    if (selectedCategory) {
      params.append('category', selectedCategory.id);
    }

    const url =
      searchType === CategoryType.EXHIBITION
        ? `/exhibitions?${params.toString()}`
        : `/properties?${params.toString()}`;
    navigate(url);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      sx={{
        borderRadius: '16px',
        boxShadow: 3,
        height: '60vh',
        m: 3,
        overflow: 'hidden',
        position: 'relative',
        textAlign: 'center',
      }}
    >
      <Box
        sx={{
          backgroundImage: `url(${imageAssets.homeTop})`,
          backgroundPosition: 'center',
          backgroundSize: '115%',
          height: '100%',
          left: 0,
          position: 'absolute',
          top: 0,
          width: '100%',
          zIndex: 1,
        }}
      />
      <Box
        sx={{
          backgroundColor: 'rgba(0, 0, 0, 0.3)',
          height: '100%',
          left: 0,
          position: 'absolute',
          top: 0,
          width: '100%',
          zIndex: 2,
        }}
      />
      <Typography
        variant="h3"
        component="div"
        sx={{
          borderRadius: '8px',
          color: 'white',
          fontWeight: 'bold',
          mb: 12,
          p: 1,
          zIndex: 3,
        }}
      >
        住宅展示場見学予約サービス
      </Typography>

      <Box
        display="flex"
        flexDirection={isDesktop ? 'row' : 'column'}
        alignItems="center"
        justifyContent="space-between"
        bgcolor="rgba(255, 255, 255, 1)"
        p={2}
        borderRadius="8px"
        width={isDesktop ? 'auto' : '100%'}
        sx={{ boxShadow: 3, zIndex: 3 }}
      >
        <FormControl sx={{ minWidth: 200, mx: 2 }}>
          <Select
            value={searchType}
            onChange={handleSearchTypeChange}
            displayEmpty
            renderValue={(selected) => {
              if (!selected) {
                return <em>住宅展示場を探す</em>;
              }
              return (
                SEARCH_OPTIONS.find((option) => option.value === selected)
                  ?.label || ''
              );
            }}
            sx={{
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
            }}
          >
            {SEARCH_OPTIONS.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl sx={{ minWidth: 200, mx: 2 }}>
          <InputLabel shrink={!!location}>エリア</InputLabel>
          <Select
            value={location}
            onChange={handleLocationChange}
            displayEmpty
            sx={{
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
            }}
          >
            <MenuItem value="">
              <em>全て</em>
            </MenuItem>
            {locations.flatMap((region) =>
              region.children
                ? region.children.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))
                : []
            )}
          </Select>
        </FormControl>

        <FormControl sx={{ minWidth: 200, mx: 2 }}>
          <InputLabel shrink={!!organization}>企業</InputLabel>
          <Select
            value={organization}
            onChange={handleOrganizationChange}
            displayEmpty
            sx={{
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
            }}
          >
            <MenuItem value="">
              <em>全て</em>
            </MenuItem>
            {organizations.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Button
          variant="contained"
          color="primary"
          onClick={handleClickSearch}
          sx={{ minWidth: 150, mx: 2 }}
        >
          検索する
        </Button>
      </Box>
    </Box>
  );
}
