import { LoadingButton } from '@mui/lab';
import { Box, Container, Link, Stack, Typography } from '@mui/material';
import { format } from 'date-fns';
import _ from 'lodash';
import { ReactElement, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';

import { update, uploadBlob } from '@app/adapter/user-service';
import { HeadBlock } from '@app/components/Shared/HeadBlock';
import {
  afterLoginRouteSelector,
  loggedInUserState,
  userAuthInfoSelector,
} from '@app/domain/app';
import { userUploadFilesState, userUpdateState } from '@app/domain/user';
import { useSetSnackbar } from '@app/hooks/useSetSnackbar';
import { UploadFileInfo, User } from '@app/types/user';
import { isError } from '@app/utils/error';
import { jobChangeOptions } from '@app/views/User/Edit';

export function EditConfirm(): ReactElement {
  const navigate = useNavigate();
  const setSnackbar = useSetSnackbar();
  const userInfo = useRecoilValue(userAuthInfoSelector);
  const [loggedInUser, setLoggedInUser] = useRecoilState(loggedInUserState);
  const [updateSharedState, setUpdateSharedState] =
    useRecoilState(userUpdateState);
  const [uploadFilesState, setUploadFilesState] =
    useRecoilState(userUploadFilesState);
  const [afterLoginRoute, setAfterLoginRoute] = useRecoilState(
    afterLoginRouteSelector
  );
  const [isLoading, setIsLoading] = useState(false);

  const getUploadAttachments = useCallback(
    async (field: string) => {
      const files: UploadFileInfo[] = [];
      if (userInfo && uploadFilesState) {
        for (const file of _.get(uploadFilesState, field)) {
          let attachmentId = file.id;
          if (!attachmentId) {
            const attachment = await uploadBlob(
              userInfo.userId,
              userInfo.accessToken,
              userInfo.fingerPrint,
              file
            );
            attachmentId = attachment.id;
          }
          files.push({
            attachmentId: attachmentId || '',
            name: file.name || '',
          });
        }
      }
      return files;
    },
    [uploadFilesState, userInfo]
  );

  const handleClickUpdate = useCallback(async () => {
    if (!userInfo || !loggedInUser || !updateSharedState) {
      navigate('/login');
      return;
    }
    setIsLoading(true);
    try {
      const payload = {
        ...updateSharedState,
        customFields: {
          ...loggedInUser.customFields,
          ...updateSharedState.customFields,
          orderAfterFiles: await getUploadAttachments('orderAfterFiles'),
          orderMatchedFiles: await getUploadAttachments('orderMatchedFiles'),
        },
      } as User;
      // ユーザー情報更新
      const response = await update(
        userInfo.userId,
        userInfo.accessToken,
        userInfo.fingerPrint,
        payload
      );
      // セッション情報更新
      if (response.data) {
        setLoggedInUser(response.data);
      }
      setUpdateSharedState(null);
      setUploadFilesState({
        orderAfterFiles: [],
        orderMatchedFiles: [],
      });

      setSnackbar(true, '登録しました', 'success');
      if (afterLoginRoute) {
        navigate(afterLoginRoute);
        setAfterLoginRoute(null);
      } else {
        navigate('/home');
      }
    } catch (e) {
      let message = '登録に失敗しました';
      if (isError(e)) {
        console.error(e.message);
        if (
          e.message ===
          'contentLength is bigger than allowed max content length'
        ) {
          message =
            'ファイルサイズが大きすぎます。1つあたり10MB以内でご設定お願いします';
        }
      }
      setSnackbar(true, message);
    } finally {
      setIsLoading(false);
    }
  }, [
    afterLoginRoute,
    getUploadAttachments,
    loggedInUser,
    navigate,
    setAfterLoginRoute,
    setSnackbar,
    setLoggedInUser,
    setUpdateSharedState,
    setUploadFilesState,
    updateSharedState,
    userInfo,
  ]);

  return (
    <>
      <HeadBlock />
      <Container fixed>
        <Typography
          variant="h5"
          fontWeight={700}
          textAlign="center"
          sx={{ my: 4 }}
        >
          ドクター情報登録
        </Typography>
        <Typography mb={3}>
          入力内容を確認の上、送信ボタンを押してください。
        </Typography>
        {updateSharedState && (
          <Stack spacing={3}>
            <Box>
              <Typography variant="body2">姓名</Typography>
              <Typography>
                {`${updateSharedState.customFields.familyName}${updateSharedState.customFields.firstName}`}
              </Typography>
            </Box>
            <Box>
              <Typography variant="body2">姓（かな）名（かな）</Typography>
              <Typography>
                {`${updateSharedState.customFields.familyNameKana}${updateSharedState.customFields.firstNameKana}`}
              </Typography>
            </Box>
            <Box>
              <Typography variant="body2">性別</Typography>
              <Typography>{updateSharedState.customFields.gender}</Typography>
            </Box>
            <Box>
              <Typography variant="body2">自認の性別</Typography>
              <Typography>
                {updateSharedState.customFields.genderSelf}
              </Typography>
            </Box>
            <Box>
              <Typography variant="body2">生年月日</Typography>
              <Typography>
                {updateSharedState.customFields.birthday
                  ? format(
                      new Date(updateSharedState.customFields.birthday),
                      'yyyy年MM月dd日'
                    )
                  : ''}
              </Typography>
            </Box>
            <Box>
              <Typography variant="body2">住所</Typography>
              {updateSharedState.customFields.postalCode && (
                <Typography>
                  {updateSharedState.customFields.postalCode}
                </Typography>
              )}
              <Typography>
                {[
                  updateSharedState.addressLine1,
                  updateSharedState.addressLine2,
                  updateSharedState.addressLine3,
                  updateSharedState.customFields.addressLine4,
                ]}
              </Typography>
            </Box>
            <Box>
              <Typography variant="body2">連絡先電話番号</Typography>
              <Typography>{updateSharedState.phoneNumber}</Typography>
            </Box>
            <Box>
              <Typography variant="body2">主な診療科</Typography>
              {updateSharedState.customFields.clinicalDepartments?.map(
                (value, index) => (
                  <Typography key={index}>{value}</Typography>
                )
              )}
            </Box>
            <Box>
              <Typography variant="body2">上記以外の診療可能科目</Typography>
              {updateSharedState.customFields.firstClinicalDepartments?.map(
                (value, index) => (
                  <Typography key={index}>{value}</Typography>
                )
              )}
            </Box>
            <Box>
              <Typography variant="body2">医師免許取得年</Typography>
              {updateSharedState.customFields.medicalLicenseYear && (
                <Typography>
                  {updateSharedState.customFields.medicalLicenseYear}年
                </Typography>
              )}
            </Box>
            <Box>
              <Typography variant="body2">医籍番号</Typography>
              <Typography>
                {updateSharedState.customFields.medicalRegisterNumber}
              </Typography>
            </Box>
            <Box>
              <Typography variant="body2">取得資格</Typography>
              <Typography>{updateSharedState.customFields.license}</Typography>
            </Box>
            <Box>
              <Typography variant="body2">出身大学</Typography>
              <Typography>
                {updateSharedState.customFields.universityName}
              </Typography>
            </Box>
            <Box>
              <Typography variant="body2">現在の所属(病院)</Typography>
              <Typography>
                {updateSharedState.customFields.currentHospital}
              </Typography>
            </Box>
            <Box>
              <Typography variant="body2">現在の所属(診療科)</Typography>
              <Typography>
                {updateSharedState.customFields.currentDepartment}
              </Typography>
            </Box>
            <Box>
              <Typography variant="body2">自信があるもの</Typography>
              {updateSharedState.customFields.confidences?.map(
                (value, index) => (
                  <Typography key={index}>{value}</Typography>
                )
              )}
            </Box>
            <Box>
              <Typography variant="body2">特記事項</Typography>
              <Typography>{updateSharedState.customFields.notes}</Typography>
            </Box>
            <Box>
              <Typography variant="body2">応募後開示_添付資料データ</Typography>
              <Typography>推奨の添付資料： 履歴書、職務経歴書、他</Typography>
              <Stack spacing={1}>
                {uploadFilesState?.orderAfterFiles.map((file, index) => (
                  <Stack key={index} direction="row">
                    <Box width={1}>
                      <Typography component="span">{file.name}</Typography>
                    </Box>
                    <Link href={file.url} target="_blank" whiteSpace="nowrap">
                      表示する
                    </Link>
                  </Stack>
                ))}
              </Stack>
            </Box>
            <Box>
              <Typography variant="body2">
                採用決定後開示_添付資料データ
              </Typography>
              <Typography>
                推奨の添付資料： 医師免許、保険医登録票、他
              </Typography>
              <Stack spacing={1}>
                {uploadFilesState?.orderMatchedFiles.map((file, index) => (
                  <Stack key={index} direction="row">
                    <Box width={1}>
                      <Typography component="span">{file.name}</Typography>
                    </Box>
                    <Link href={file.url} target="_blank" whiteSpace="nowrap">
                      表示する
                    </Link>
                  </Stack>
                ))}
              </Stack>
            </Box>
            <Box>
              <Typography variant="body2">転職意欲</Typography>
              <Typography>
                {
                  jobChangeOptions.find(
                    (j) => j.value === updateSharedState.customFields.jobChange
                  )?.label
                }
              </Typography>
            </Box>
            <Stack spacing={2}>
              <LoadingButton
                type="submit"
                color="primary"
                variant="contained"
                loading={isLoading}
                fullWidth
                onClick={handleClickUpdate}
              >
                登録
              </LoadingButton>
              <LoadingButton
                color="inherit"
                variant="outlined"
                loading={isLoading}
                fullWidth
                onClick={() => navigate('/profile/edit')}
              >
                入力画面に戻る
              </LoadingButton>
            </Stack>
          </Stack>
        )}
      </Container>
    </>
  );
}
