import { Divider, Stack, Typography, useTheme } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

import { ImageCarousel } from '@app/components/Product/company/CarouselItem';
import { StrengthsSection } from '@app/components/Product/company/StrengthsSection';
import { Product } from '@app/types/catalog';

interface ProductDetailsProps {
  product: Product;
}

export function ProductDetails({ product }: ProductDetailsProps) {
  const theme = useTheme();

  return (
    <Stack spacing={3} sx={{ width: '100%' }}>
      <Typography variant="h2" fontWeight="bold">
        {product.name}
      </Typography>
      {typeof product.organization === 'object' &&
        product.organization !== null && (
          <Typography
            variant="body1"
            color={theme.palette.grey[500]}
            sx={{ mt: '5px !important' }}
          >
            {product.customFields.contactAddress}
          </Typography>
        )}

      <ImageCarousel
        images={product.images.map((img) => img.url)}
        displayMode="largeImage"
      />

      <Divider />
      <Stack direction="row">
        <Typography mr={3}>施工エリア</Typography>
        <Link to={'#'} style={{ color: '#326EE6', textDecoration: 'none' }}>
          全国（沖縄県および離島を除く）
        </Link>
      </Stack>
      <Divider sx={{ marginBottom: '24px !important' }} />
      <StrengthsSection
        strengths={product.customFields.strengths}
        strengthsTitle={product.customFields.strengthsTitle}
        strengthsDescription={product.customFields.strengthsDescription}
        showReadMore={false}
      />
    </Stack>
  );
}
