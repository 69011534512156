import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import {
  InquiryStepOneFormData,
  InquiryStepTwoFormData,
} from '@app/types/order';

export const defaultInquiryStepOneFormData: InquiryStepOneFormData = {
  customFields: {
    addressLine4: '',
    addressLine5: '',
    exhibition: '',
    postalCode: '',
    prefecture: {
      id: '',
      name: '',
    },
  },
  customer: {
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    age: '',
    email: '',
    name: '',
    nameKana: '',
    phone: '',
    prefectureName: '',
  },
  items: {
    product: {
      id: '',
      name: '',
    },
    quantity: 1,
    variantId: '',
  },
};

export const defaultInquiryStepTwoFormData: InquiryStepTwoFormData = {};

export const InquiryStepOneFormSchema = z.object({
  customFields: z.object({
    addressLine4: z.string().optional(),
    addressLine5: z.string().optional(),
    postalCode: z.string().regex(/^\d{7}$/, {
      message: '郵便番号はハイフンなしの7桁の数字で入力してください',
    }),
  }),
  customer: z.object({
    addressLine1: z.string().min(1, { message: '都道府県を選択してください' }),
    addressLine2: z.string().min(1, { message: '市区町村を入力してください' }),
    addressLine3: z.string().min(1, { message: '町名を入力してください' }),
    age: z
      .string()
      .min(1, { message: '年齢を入力してください' })
      .regex(/^\d+$/, {
        message: '年齢は数字で入力してください',
      }),
    email: z
      .string()
      .min(1, { message: 'メールアドレスを入力してください' })
      .email({
        message: '有効なメールアドレスを入力してください',
      }),
    name: z.string().min(1, { message: '名前を入力してください' }),
    nameKana: z
      .string()
      .min(1, { message: '名(かな)を入力してください' })
      .refine((value) => /^[ァ-ンヴー\s]+$/.test(value), {
        message: '名(かな)はカタカナのみで入力してください',
      }),
    phone: z
      .string()
      .min(1, { message: '電話番号を入力してください' })
      .regex(/^0[0-9]{9,12}$/, {
        message: '電話番号はハイフンなしの数字を入力してください',
      }),
  }),
});

export const InquiryStepOneForm = {
  defaultValues: defaultInquiryStepOneFormData,
  resolver: zodResolver(InquiryStepOneFormSchema),
  schema: InquiryStepOneFormSchema,
} as const;
