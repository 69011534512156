import { ReactElement } from 'react';

import { Completed } from '@app/components/Product/company/Inquiry/Completed';
import { HeadBlock } from '@app/components/Shared/HeadBlock';

export function CompletedPage(): ReactElement {
  return (
    <>
      <HeadBlock />
      <Completed />
    </>
  );
}
